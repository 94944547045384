import { ApiClient } from './Client'

export const payInMethod = async (token: string, chart_id: number, price: number) => {
	return await ApiClient({
		method: 'POST',
		url: '/wallet/buy',
		headers: {
			Authorization: `${token}`
		},
		data: {
			chart_id: chart_id,
			price: price
		}
	})
}

export const payOutMethod = async (token: string, chart_id: number, price: number, payment_id: number) => {
	return await ApiClient({
		method: 'POST',
		url: '/wallet/sell',
		headers: {
			Authorization: `${token}`
		},
		data: {
			chart_id: chart_id,
			price: price,
			id: payment_id
		}
	})
}

export const moveInMethod = async (
	token: string,
	amount: number,
	chart_id: number,
	chain_id: number,
) => {
	return await ApiClient({
		method: 'POST',
		url: '/payment/create-payment',
		headers: {
			Authorization: token
		},
		data: {
			amount: amount,
			currency_id: 3,
			chain_id: chain_id,
		}
	})
}

export const PaymentStatusMethod = async (token: string, payment_id: number) => {
	return await ApiClient({
		url: "/payment/get-payment-status",
		headers: {
			Authorization: token
		},
		params: {
			payment_id: payment_id
		}
	})
}


//invoice
// export const moveInMethod = async (token: string, chart_id: number, chain_id: number, price: number) => {
// 	return await ApiClient({
// 		method: 'POST',
// 		url: '/wallet/input',
// 		headers: {
// 			Authorization: token
// 		},
// 		data: {
// 			chart_id: chart_id,
// 			chain_id: chain_id,
// 			price: price,
// 			currency_id: 3
// 		}
// 	})
// }

export const moveOutMethod = async (token: string, chart_id: number, price: number, b2b_payments_ids: number[]) => {
	return await ApiClient({
		method: 'POST',
		url: '/wallet/sellb2b',
		headers: {
			Authorization: token
		},
		data: {
			chart_id: chart_id,
			price: price,
			b2b_payments_ids: b2b_payments_ids,
			type_id: 1
		}
	})
}

export const moveOut = async (
	token: string,
	outAddress: string,
	chart_id: number,
	amount: number,
	currency: number
) => {
	return await ApiClient({
		method: 'POST',
		url: '/payment/create-payout',
		headers: {
			Authorization: token,
			// 'Content-Type': 'application/json'
		},
		data: {
			address: outAddress,
			chart_id: chart_id,
			amount: amount,
			currency: currency
		}
	})
}

export const DeliveryMethod = async (
	token: string,
	fio_courier: string,
	phone_courier: string,
	street_for_courier: string,
	build_for_courier: string,
	pod_for_courier: string,
	summa: string,
	description?: string
) => {
	return await ApiClient({
		method: 'POST',
		url: '/payment/create',
		headers: {
			Authorization: token
		},
		data: {
			b2b: 1,
			fio_courier: fio_courier,
			phone_courier: phone_courier,
			street_for_courier: street_for_courier,
			build_for_courier: build_for_courier,
			pod_for_courier: pod_for_courier,
			summa: summa,
			...(description && { description: description })
		}
	})
}

export const GetCardB2bList = async (token: string, type: 1 | 0, company_id: number) => {
	return await ApiClient({
		method: 'GET',
		url: '/payment/get-payments',
		// url: '/payment/courier-list',
		headers: {
			Authorization: token
		},
		params: {
			type: type,
			company_id: company_id
		}
	})
}

export const AddB2bCard = async (
	token: string,
	fio_karta: string,
	num_karta: string,
	bank_id: number,
	company_id: number,
	payment_id: number,
	bank: string
) => {
	return await ApiClient({
		method: 'POST',
		url: '/payment/create',
		headers: {
			Authorization: token
		},
		data: {
			type: 0,
			b2b: 1,
			payment_receiver: fio_karta,
			value: num_karta,
			bank_id: bank_id,
			company_id: company_id,
			payment_id: bank_id,
			bank: bank
		}
	})
}

export const DelB2bCard = async (token: string, id: number) => {
	return await ApiClient({
		method: 'POST',
		url: '/payment/delete-b2bpayment',
		headers: {
			Authorization: token
		},
		data: {
			id: id
		}
	})
}

export const B2bCardMethod = async (
	token: string,
	payment_id: number,
	value: string,
	payment_receiver: string,
	bank: string,
	summa: number
) => {
	return await ApiClient({
		method: 'POST',
		url: '/payment/create',
		headers: {
			Authorization: token
		},
		data: {
			b2b: 1,
			type: 0,
			payment_id: payment_id,
			value: value,
			payment_receiver: payment_receiver,
			bank: bank,
			summa: summa
		}
	})
}

export const GetChainList = async (token: string, chart_id: number) => {
	return await ApiClient({
		method: 'GET',
		url: '/chart/chain-list',
		headers: {
			Authorization: token
		},
		params: {
			chart_id: chart_id
		}
	})
}

export const UpdateSumma = async (token: string, id: number, summa: number) => {
	return await ApiClient({
		method: 'POST',
		url: '/wallet/update-summa',
		headers: {
			Authorization: token
		},
		data: {
			id: id,
			summa: summa
		}
	})
}

export const GetFeeMethod = async (token: string, currency: string, amount: number) => {
	return await ApiClient({
		method: "GET",
		url: "/payment/get-min-payout-fee",
		headers: {
			Authorization: token
		},
		params: {
			currency: currency,
			amount: amount
		}
	})
}