import React, { useEffect, useState } from 'react';
import styles from './B2bFilters.module.scss';
import { CustomSelect, SelectOption } from "../../../UI/CustomSelect/CustomSelect";
import { OrderType, UserProfile } from "../../../types/types";
import { B2bFilters } from "../../../types/B2b.types";
import { useAppDispatch } from "../../../utils/hooks/useAppDispatch";
import { setChartId, setOfferType, setMainOkved, setCurrencyId, setSum, setBank, setDiscount } from "../../../bll/slices/b2b.slice";
import Input from "../../../UI/Input";
import { Switcher } from "../../../UI/Switcher/Switcher";
import { getBank, getCharts, getCurrencies, getOkved } from "../../../bll/selectors/app.selector";
import { useAppSelector } from "../../../utils/hooks/useAppSelector";
import Select from 'react-select'
import filter from '../../../assets/icons/filter.svg'
import addNew from '../../../assets/icons/addNew.svg'
import { ICompany } from '../../../types/Company.type';
import { CompanyMonitor } from './CompanyMonitor/CompanyMonitor';
// import { findCompanyByINNThunk } from '../../../bll/api/company.thunk';
import { WithPopUp } from '../../../utils/hocs/WithPopUp/WithPopUp';
import { B2BCompanyCreator } from '../../b2bComponents/B2bCompanySelector/B2bCompanyCreator/B2bCompanyCreator';
import MainBlock from '../../addNew/MainBlock/MainBlock';
import { FiatProps, TokenProps } from '../../filters/Filters';
import { GetB2bCharts, GetCurrency } from '../../../services/P2p';
import AuthorizationBlock from '../../AuthorizationComponents/AuthorizationBlock';

interface B2BFiltersProps {
  filters: B2bFilters;
  sum: number | null;
  discountValue: number | null;
  disabled: boolean;
  // setAddNewOpen: (param: boolean) => void;
  selectedCompany: ICompany;
  setOffType: (param: number) => void;
  isCreateOffer: boolean;
  setIsCreateOffer: (param: boolean) => void;
  setIsCreating: (param: boolean) => void;
  isCreating: boolean
  profile: UserProfile | null
}

export const B2BFilters: React.FC<B2BFiltersProps> = ({
  filters,
  sum,
  discountValue,
  disabled,
  // setAddNewOpen,
  selectedCompany,
  setOffType,
  isCreateOffer,
  setIsCreateOffer,
  isCreating,
  setIsCreating,
  profile
}) => {

  const charts = useAppSelector(getCharts);
  const currencies = useAppSelector(getCurrencies);
  const okveds = useAppSelector(getOkved);
  const banks = useAppSelector(getBank);

  const chartOptions = Object.values(charts)
    .map(({ id, icon, symbol }) => new SelectOption(id, symbol, icon));

  const currencyOptions = Object.values(currencies).map(
    ({ id, icon, symbol }) => new SelectOption(id, symbol, icon)
  );

  const discountOptions = [...Array(31)].map((_, i) => i).map(value => new SelectOption(value, `${value} %`)).slice(1);

  const okvedOptions = Object.values(okveds).map(({ title, id, okved_id }) => { return { label: okved_id ? `${okved_id} | ${title}` : title, id: id } })

  const bankOptions = Object.values(banks).map(({ title, id }) => { return { label: title, id: id } })

  const { chartId, currencyId, mainOkved, discount, type } = filters;


  const dispatch = useAppDispatch();

  const changeFilterToken = (id: string | number | null) => {
    if (!id) {
      dispatch(setChartId(null));
      return;
    }
    if (!Number.isFinite(+id)) return;
    dispatch(setChartId(+id));
  };

  const changeFilterCurrencies = (id: string | number | null) => {
    if (!id) {
      dispatch(setCurrencyId(null));
      return;
    }
    if (!Number.isFinite(+id)) return;
    dispatch(setCurrencyId(+id));
  };

  const changeFilterDiscount = (id: string | number | null) => {
    if (!id) {
      dispatch(setDiscount(null));
      return;
    }
    if (!Number.isFinite(+id)) return;
    dispatch(setDiscount(+id));
  };

  const changeFilterOKVED = (params: { label: string, id: string } | null) => {
    if (!params?.id) {
      dispatch(setMainOkved(null));
      return;
    }
    dispatch(setMainOkved(String(params.id)));
  };
  const changeFilterBank = (params: { label: string, id: number } | null) => {
    if (!params?.id) {
      dispatch(setBank(null));
      return;
    }
    dispatch(setBank(String(params.id)));
  };

  const changeFilterFiatSum = (sum: string) => {
    if (!Number.isFinite(+sum)) return;
    dispatch(setSum(+sum));
  };
  // const changeFilterDiscount = (discount: string) => {
  //   if (!Number.isFinite(+discount)) return;
  //   if (+discount > 30) {
  //     dispatch(setDiscount(30));
  //   } else {
  //     dispatch(setDiscount(+discount));
  //   }
  // };

  const changeFilterOfferType = (offerType: string) => {
    +offerType === OrderType.BUY && dispatch(setOfferType(OrderType.BUY));
    +offerType === OrderType.SELL && dispatch(setOfferType(OrderType.SELL));
    setOffType(+offerType)
  };

  const rootClassName = disabled
    ? `${styles.b2bFilters} ${styles.disabled}`
    : styles.b2bFilters;

  const [showMobileFilters, setshowMobileFilters] = useState(true)

  const [fiatList, setFiatList] = useState<FiatProps[]>([
    { label: <div className={styles.fiatBox}><img src={''} alt="" className={styles.fiat} />RUB</div>, value: 1 }
  ])
  const [tokenList, setTokenList] = useState<TokenProps[]>([
    { label: <div className={styles.fiatBox}><img src={''} alt="" className={styles.fiat} />USDT</div>, value: 1 },
    { label: <div className={styles.fiatBox}><img src={''} alt="" className={styles.fiat} />BTC</div>, value: 2 },
    { label: <div className={styles.fiatBox}><img src={''} alt="" className={styles.fiat} />ETH</div>, value: 3 },
  ])

  const getKrypto = async (token: string) => {
    const data = await GetB2bCharts(token);
    if (data.status === 200) {
      const temp: TokenProps[] = [];
      data.data.forEach((el: any) => {
        temp.push({ label: <div className={styles.fiatBox}><img src={el.icon} alt={el.name} className={styles.fiat} />{el.symbol}</div>, value: el.id })
      });
      setTokenList(temp)
    }
  }

  const getCurr = async (token: string) => {
    const data = await GetCurrency(token);
    if (data.status === 200) {
      const temp: FiatProps[] = [];
      data.data.forEach((el: any) => {
        temp.push({ label: <div className={styles.fiatBox}><img src={el.icon} alt={el.symbol} className={styles.fiat} />{el.name}</div>, value: el.id })
      })
      setFiatList(temp)
    }
  }


  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    getKrypto(token)
    getCurr(token)
  }, [okveds])

  const openNewOffer = () => {
    setIsCreateOffer(!isCreateOffer);
  };

  // const [isCreating, setIsCreating] = useState<boolean>(false);
  const [creatingCompany, setCreatingCompany] = useState<null | ICompany>(null);

  const createCompany = () => {
    setIsCreating(true);
  };

  const closeCreating = () => {
    setIsCreating(false);
    const jivo = document.querySelectorAll("jdiv")[0];
    (jivo as HTMLDivElement).style.display = 'block';
  };

  useEffect(() => {
    creatingCompany ? createCompany() : <></>
  }, [creatingCompany])

  // const findCompany = (inn: string) => {
  //   dispatch(findCompanyByINNThunk(inn)).unwrap()
  //     .then(findCompany => {
  //       findCompany && setCreatingCompany({ ...findCompany, inn });
  //       const jivo = document.querySelectorAll("jdiv")[0];
  //       (jivo as HTMLDivElement).style.display = 'none';
  //     });
  // };

  // const [inn, setInn] = useState<string>('');

  // const find = () => {
  //   if (inn.length === 10) {
  //     setInn('');
  //     findCompany(inn);
  //   }
  // }

  // useEffect(() => {
  //   if (inn.length === 10) {
  //     setInn('');
  //     findCompany(inn);
  //   }

  // }, [inn, setInn]);

  // const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (!Number.isFinite(+e.currentTarget.value)) return;
  //   if (+e.currentTarget.value.length > 10) {
  //     e.currentTarget.value = e.currentTarget.value.slice(0, 10);
  //     setInn(e.currentTarget.value);
  //   } else {
  //     setInn(e.currentTarget.value);
  //   }
  // };

  const createNullCompany = () => {
    let nullCompany: ICompany = { bank: 0, bik: '', rs: '', ks: '', id: 0, name: '', address: '', inn: '', ogrn: '' }
    setCreatingCompany({ ...nullCompany })
  }

  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(false);

  return (
    <div className={rootClassName}>
      <div className={styles.mobile}>
        <Switcher
          viewOption1={'Покупка'}
          viewOption2={'Продажа'}
          option1={String(OrderType.BUY)}
          option2={String(OrderType.SELL)}
          value={String(type)}
          changeValue={changeFilterOfferType}
        />
        <div className={styles.mobileBox}>
          <img src={filter} alt="filter" onClick={() => setshowMobileFilters(!showMobileFilters)} />
          {selectedCompany ? <img src={addNew} alt="filter" onClick={openNewOffer} /> : <></>}
          {/* {selectedCompany ? <img src={addNew} alt="filter" onClick={() => setAddNewOpen(true)} /> : <></>} */}
        </div>
        <div style={showMobileFilters ? { display: "flex", flex: 1 } : { display: "none" }}>
          <CustomSelect options={chartOptions} placeholder='Криптовалюта' value={chartId}
            onChange={changeFilterToken} />
        </div>
        <div className={styles.sumWrapper} style={showMobileFilters ? { display: "flex", flex: 1 } : { display: "none" }}>
          <Input value={sum ? String(sum) : ''} placeholder={'Введите сумму'} setValue={changeFilterFiatSum} />
          <CustomSelect options={currencyOptions} placeholder='Валюта' value={currencyId}
            onChange={changeFilterCurrencies} />
        </div>
        {/* <CustomSelect options={okvedOptions} placeholder='ОКВЭД' value={mainOkved} onChange={changeFilterOKVED} /> */}
        <div style={showMobileFilters ? { display: "flex", flex: 1 } : { display: "none" }}>
          <Select
            options={okvedOptions}
            classNames={{
              container: (state) => styles.select3,
              singleValue: (state) => styles.select4,
              control: (state) => styles.select2,
              menuList: (state) => styles.select6,
              menu: (state) => styles.select61,
              option: (state) => styles.select7,
              input: (state) => styles.select1,
              placeholder: (state) => styles.select1
            }}
            placeholder='ОКВЭД'
            onChange={changeFilterOKVED}
          />
        </div>
        <div style={showMobileFilters ? { display: "flex", flex: 1 } : { display: "none" }}>
          <Select
            options={bankOptions}
            classNames={{
              container: (state) => styles.select3,
              singleValue: (state) => styles.select4,
              control: (state) => styles.select2,
              menuList: (state) => styles.select6,
              menu: (state) => styles.select61,
              option: (state) => styles.select7,
              input: (state) => styles.select1,
              placeholder: (state) => styles.select1
            }}
            placeholder='Выберите банк'
            onChange={changeFilterBank}
          />
        </div>
        <div className={styles.discountWrapper} style={showMobileFilters ? { display: "flex", flex: 1 } : { display: "none" }}>
          {/* <Input value={discount ? String(discount) : ''} placeholder={'Комиссия'} setValue={changeFilterDiscount} /> */}
          <CustomSelect options={discountOptions} placeholder='Комиссия' value={discount}
            onChange={changeFilterDiscount} />
        </div>
        <div className={styles.wrap}>
          {
            profile ?
              selectedCompany ?
                <CompanyMonitor company={selectedCompany} />
                :
                <div className={styles.addCompanyBlock}>
                  {/* <input className={styles.innInput} title='text' onChange={onChangeHandler} placeholder={'Введите ИНН'} /> */}
                  <button
                    // className={inn.length === 10 ? styles.btnOk : styles.btnDis}
                    // onClick={() => inn.length === 10 ? find() : <></>}
                    className={styles.btnOk}
                    onClick={() => createNullCompany()}
                  >Добавить компанию</button>
                </div>
              :
              <></>
          }
          {selectedCompany ?
            <button className={styles.createOfferButton} onClick={() => profile ? openNewOffer() : setOpenModal(true)}>
              + Добавить объявление
            </button>
            : <></>}
        </div>
      </div>
      <div className={styles.desk}>
        <Switcher
          viewOption1={'Покупка'}
          viewOption2={'Продажа'}
          option1={String(OrderType.BUY)}
          option2={String(OrderType.SELL)}
          value={String(type)}
          changeValue={changeFilterOfferType}
        />
        <CustomSelect options={chartOptions} placeholder='Криптовалюта' value={chartId}
          onChange={changeFilterToken} />
        <div className={styles.sumWrapper}>
          <Input value={sum ? String(sum) : ''} placeholder={'Введите сумму'} setValue={changeFilterFiatSum} />
          <CustomSelect options={currencyOptions} placeholder='Валюта' value={currencyId}
            onChange={changeFilterCurrencies} />
        </div>
        <Select
          options={okvedOptions}
          classNames={{
            container: (state) => styles.select3,
            singleValue: (state) => styles.select4,
            control: (state) => styles.select2,
            menuList: (state) => styles.select6,
            menu: (state) => styles.select61,
            option: (state) => styles.select7,
            input: (state) => styles.select1,
            placeholder: (state) => styles.select1
          }}
          placeholder='ОКВЭД'
          onChange={changeFilterOKVED}
        />
        <Select
          options={bankOptions}
          classNames={{
            container: (state) => styles.select3,
            singleValue: (state) => styles.select4,
            control: (state) => styles.select2,
            menuList: (state) => styles.select6,
            menu: (state) => styles.select61,
            option: (state) => styles.select7,
            input: (state) => styles.select1,
            placeholder: (state) => styles.select1
          }}
          placeholder='Выберите банк'
          onChange={changeFilterBank}
        />
        <div className={styles.discountWrapper}>
          {/* <Input value={discount ? String(discount) : ''} placeholder={'Комиссия'} setValue={changeFilterDiscount} /> */}
          <CustomSelect options={discountOptions} placeholder='Комиссия' value={discount}
            onChange={changeFilterDiscount} />
        </div>
        <div className={styles.wrap}>
          {
            profile ?
              selectedCompany ?
                <CompanyMonitor company={selectedCompany} />
                :
                <div className={styles.addCompanyBlock}>
                  {/* <input className={styles.innInput} title='text' onChange={onChangeHandler} placeholder={'Введите ИНН'} /> */}
                  <button
                    // className={inn.length === 10 ? styles.btnOk : styles.btnDis}
                    // onClick={() => inn.length === 10 ? find() : <></>}
                    className={styles.btnOk}
                    onClick={() => createNullCompany()}
                  >Добавить компанию</button>
                </div>
              :
              <></>
          }
          {selectedCompany ?
            <button className={styles.createOfferButton} onClick={() => profile ? openNewOffer() : setOpenModal(true)}>
              + Добавить объявление
            </button>
            : <></>}
        </div>
      </div>
      {isCreating &&
        creatingCompany &&
        (
          <WithPopUp close={closeCreating}>
            <B2BCompanyCreator close={closeCreating} creatingCompany={creatingCompany} setCreatingCompany={setCreatingCompany} />
          </WithPopUp>
        )}
      <MainBlock
        addNewOpen={isCreateOffer}
        setAddNewOpen={openNewOffer}
        tokenList={tokenList}
        fiatList={fiatList}
        okveds={okveds}
      />
      <AuthorizationBlock
        type={'login'}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setStatus={setStatus}
      />
    </div>
  );
};
