import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Readability } from '@mozilla/readability';
import styles from './NewsItem.module.scss';
import axios from 'axios';
import Container from '../../../UI/container/Container';

const NewsItem = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true);
    // axios.get(state.url)
    axios.get(state.link)
      .then(res => {
        const doc = document.implementation.createHTMLDocument('');
        doc.write(res.data);
        let article = new Readability(doc).parse();
        console.log(article);

        const parser = new DOMParser();
        const newContent = parser.parseFromString(article ? article.content : '', 'text/html');
        const div = document.getElementById('text') || document.createElement('div');
        if (div.children.length === 0) {
          div.appendChild(newContent.body);
        }
        // div.removeChild(div.children[1]);
        // div.removeChild(div.children[2]);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
      })
  }, [state])

  // useEffect(() => {
  //   const div = document.getElementById('text') || document.createElement('div');
  //   for (let i = 1; i < div.children.length; i++) {
  //     div.removeChild(div.children[i])
  //     console.log(div);

  //   }
  // }, [loading])
  // console.log(state);

  return (
    <div className={styles.newsBlock}>
      <Container className={styles.newsItemBlock}>
        <div className={styles.elTitle}>{state.title}</div>
        <div className={styles.elTime}>
          {/* {new Date(state.publishedAt).toLocaleDateString()}{' '}
          {new Date(state.publishedAt).getHours().toString().length === 1 ? `0${new Date(state.publishedAt).getHours()}` : new Date(state.publishedAt).getHours()}
          :
          {new Date(state.publishedAt).getMinutes().toString().length === 1 ? `0${new Date(state.publishedAt).getMinutes()}` : new Date(state.publishedAt).getMinutes()}
          :
          {new Date(state.publishedAt).getSeconds().toString().length === 1 ? `0${new Date(state.publishedAt).getSeconds()}` : new Date(state.publishedAt).getSeconds()} */}
          {state.date}
        </div>
        {/* <img className={styles.elImg} src={state.urlToImage} alt="" /> */}
        <div className={styles.text} id='text'></div>
      </Container>
    </div>
  )
}

export default NewsItem