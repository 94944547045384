import React, { useState } from 'react'
import styles from './HistoryTransactions.module.scss'
import HistoryTransactionsTable from './historyTransactionsTable/HistoryTransactionsTable';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss'

const HistoryTransaction = () => {
  const [activeNavItem, setActiveNavItem] = useState(0);

  const navItems = [
    {
      id: 0,
      title: `Ввод`,
    },
    {
      id: 1,
      title: `Вывод общий`,
    },
    {
      id: 2,
      title: `Вывод B2B`,
    },
    {
      id: 3,
      title: `Переводы`,
    },
    {
      id: 4,
      title: `Обмены`
    }
  ];

  const checkSlider = (e: any) => {
    // setActivePayments(e.activeIndex ?? 0)
  }

  const [swiperInstance, setSwiperInstance] = useState<any>()

  return (
    <div className={styles.root}>
      <div className={styles.p2pBlock}>
        <h2>История транзакций</h2>
        <div className={styles.nav}>
          <div className={styles.swiperContainer} style={{ width: `${window.innerWidth - 40}px` }}>
            <Swiper spaceBetween={10} slidesPerView={'auto'} onSlideChange={(e) => checkSlider(e)}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
              {navItems.map((el, index) => (
                <SwiperSlide style={{ width: 'max-content', padding: '5px 0' }}>
                  <span
                    onClick={() => setActiveNavItem(index)}
                    className={`${styles.navItem} ${activeNavItem === index ? styles.navItemActive : ""
                      }`}
                    key={el.id}
                  >
                    {el.title}
                  </span>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.navLeft}>
            {navItems.map((el, index) => (
              <span
                onClick={() => setActiveNavItem(index)}
                className={`${styles.navItem} ${activeNavItem === index ? styles.navItemActive : ""
                  }`}
                key={el.id}
              >
                {el.title}
              </span>
            ))}
          </div>
        </div>
        <HistoryTransactionsTable type={activeNavItem} />
      </div>
    </div>
  )
}

export default HistoryTransaction