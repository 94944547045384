import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './B2bCompanyCreator.module.scss';
import { useFormik } from 'formik';
import { StyledButton } from "../../../../UI/StyledButton/StyledButton";
import { StyledInput } from "../../../../UI/StyledInput/StyledInput";
import { useAppDispatch } from "../../../../utils/hooks/useAppDispatch";
import { findCompanyByINNThunk } from "../../../../bll/api/company.thunk";
// import { useNavigate } from "react-router-dom";
import { CreateCompanyRequest, ICompany } from "../../../../types/Company.type";
import { ReactComponent as Cross } from '../../../../assets/icons/cross.svg';
import { CreateCompany, GetCompanyByInfo } from '../../../../services/CompanyService';
import { ReactComponent as Arr } from "../../../../assets/icons/arrow-right.svg";
import success from '../../../../assets/icons/success.svg';
import { AddPaymentB2b } from '../../../../services/UserService';

interface B2BCompanyCreatorProps {
  creatingCompany: ICompany;
  close: () => void;
  setCreatingCompany: (params: ICompany | null) => void
}

type FormikValuesType = Required<CreateCompanyRequest>

export const B2BCompanyCreator: React.FC<B2BCompanyCreatorProps> = ({ creatingCompany, close, setCreatingCompany }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [okMessage, setOkMessage] = useState('')
  const [bankId, setBankId] = useState(0);

  const error = <div className={styles.error}>
    {errorMessage}
  </div>

  const okM = <div className={styles.okM}>{okMessage}</div>

  const dispatch = useAppDispatch();

  const { name, address, inn, ogrn, kpp, fio, phone, bank, rs, ks, bik } = creatingCompany;
  //@ts-ignore
  const mainOkved = creatingCompany.okved;

  // FORMIK
  const initialValues: FormikValuesType = {
    name,
    address,
    inn,
    ogrn,
    mainOkved: mainOkved || '',
    kpp: kpp || '',
    fio: fio || '',
    phone: phone || '',

    bank: bank || 0,
    rs: rs || '',
    ks: ks || '',
    bik: bik || '',
    // eslint-disable-next-line no-restricted-globals
    status,
  };

  // const [once, setOnce] = useState(false)

  const validate = (values: FormikValuesType) => {
    const errors = {} as Partial<Record<string, string>>;

    if (!values.name) errors.name = 'Required field!';
    if (!values.address) errors.address = 'Required field!';
    if (!values.phone) errors.phone = 'Required field!';
    if (!values.inn) errors.inn = 'Required field!';
    if (!values.kpp) errors.kpp = 'Required field!';
    if (!values.fio) errors.fio = 'Required field!';
    if (!values.mainOkved) errors.mainOkved = 'Required field!';
    if (!values.ogrn) errors.ogrn = 'Required field!';
    // if (!values.bank) errors.bank = 'Required field!';
    // if (!values.rs) errors.rs = 'Required field!';
    // if (!values.ks) errors.ks = 'Required field!';
    // if (!values.bik) errors.bik = 'Required field!';

    if (String(values.phone).length < 11) errors.phone = 'Incorrect number!';
    if (!new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im).test(values.phone)) errors.phone = 'Incorrect number!';
    if (String(values.ogrn).length < 13) errors.ogrn = 'Incorrect number!';
    if (values.rs && (String(values.rs).length !== 20 && String(values.rs).length !== 28)) errors.rs = 'Incorrect number!';
    if (values.ks && (String(values.ks).length !== 20 && String(values.ks).length !== 28)) errors.ks = 'Incorrect number!';
    if (values.bik && (String(values.bik).length !== 9)) errors.bik = 'Incorrect number!';

    // if (values.inn?.length === 10 && !once) {
    //   findCompanyByINN(values.inn)
    // }

    return errors;
  };

  const reqCreate = async (bankId: number, rs: string, ks: string, bik: string, bankName: string) => {
    const token = localStorage.getItem("access_token") ?? '';
    const data = await AddPaymentB2b(token, bankId, rs, bankName, bik, ks)
    setStep(3)
    setTimeout(() => {
      setOkMessage('');
      close();
      document.location.reload()
    }, 2000);
  }

  const companyCreate = async () => {
    const status = creatingCompany.status ? creatingCompany.status : statusCompany ?? '';
    const data = await CreateCompany(
      formik.values.inn,
      formik.values.address,
      formik.values.name,
      formik.values.ogrn,
      formik.values.phone,
      status,
      formik.values.mainOkved,
      formik.values.kpp,
      formik.values.fio,
      // bankId,
      // formik.values.rs,
      // formik.values.ks,
      // formik.values.bik,
    )
    if (data.status === 200) {
      if (formik.values.rs) {
        //@ts-ignore
        reqCreate(bankId, formik.values.rs, formik.values.ks, formik.values.bik, formik.values.bank)
      } else {
        setStep(3)
        setTimeout(() => {
          setOkMessage('');
          close();
          document.location.reload()
        }, 2000);
      }
    } else {
      setErrorMessage(data?.data)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000);
    }
  }

  const [statusCompany, setStatusCompany] = useState<string | undefined>(undefined)

  const onSubmit = async (values: FormikValuesType) => {
    const { mainOkved, kpp, fio, phone, ...requiredValues } = values;
    const requestData: CreateCompanyRequest = { ...requiredValues, bank: bankId, status: creatingCompany.status || statusCompany };
    if (mainOkved) requestData.mainOkved = mainOkved;
    if (kpp) requestData.kpp = kpp;
    if (fio) requestData.fio = fio;
    if (phone) requestData.phone = phone;
    if (ok) {
      companyCreate()
    }
  };

  const findCompanyByINN = (inn: string) => {
    dispatch(findCompanyByINNThunk(inn)).unwrap()
      .then(findCompany => {
        if (!findCompany) return
        //@ts-ignore
        const { name, address, ogrn, kpp, fio, phone, okved } = findCompany;
        // setOnce(true)
        setStatusCompany(findCompany.status)
        formik.setFieldValue('name', name)
        formik.setFieldValue('address', address)
        formik.setFieldValue('inn', inn)
        formik.setFieldValue('ogrn', ogrn)
        formik.setFieldValue('kpp', kpp)
        formik.setFieldValue('fio', fio)
        formik.setFieldValue('phone', phone)
        formik.setFieldValue('mainOkved', okved)
      });
  };


  const onChangeNumberHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!Number.isFinite(+e.currentTarget.value)) return;
    if (!e.currentTarget.value) {
      formik.setFieldValue(e.currentTarget.name, undefined);
    }
    formik.setFieldValue(e.currentTarget.name, e.currentTarget.value);
    if (e.currentTarget.name === 'inn' && e.currentTarget.value.length === 10) {
      findCompanyByINN(e.currentTarget.value)
    }
  };

  const onChangeNumberHandlerBik = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!Number.isFinite(+e.currentTarget.value)) return;
    if (!e.currentTarget.value) {
      formik.setFieldValue(e.currentTarget.name, undefined);
    }
    formik.setFieldValue(e.currentTarget.name, e.currentTarget.value);
    if (e.currentTarget.value.length === 9) {
      const data = await GetCompanyByInfo(e.currentTarget.value.slice(1));
      if (data.data.length === 1) {
        formik.setFieldValue('ks', data.data[0].ks)
        formik.setFieldValue('bank', data.data[0].title)
        setBankId(+data.data[0].id)
      }
    }
  };

  const onChangeStringHandler = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  const formik = useFormik({
    onSubmit,
    initialValues,
    validate,
  });

  // const isButtonDisabled = !!Object.keys(formik.errors).length;

  const [step, setStep] = useState(1)
  const [ok, setOk] = useState(false)


  const nextStep = (noReq = false) => {
    const errors = formik.errors
    if (step === 1) {
      if (
        !errors.inn &&
        !errors.name &&
        !errors.address &&
        !errors.mainOkved &&
        !errors.kpp &&
        !errors.ogrn &&
        !errors.fio &&
        !errors.phone &&
        formik.values.inn &&
        formik.values.name &&
        formik.values.address &&
        formik.values.mainOkved &&
        formik.values.kpp &&
        formik.values.ogrn &&
        formik.values.fio &&
        formik.values.phone
      ) {
        setStep(2)
      } else {
        setOk(false)
        setErrorMessage('Проверьте введённые данные')
        setTimeout(() => {
          setErrorMessage('')
        }, 2000);
      }
    } else if (step === 2) {
      if (noReq) {
        setOk(true)
      } else {
        if (
          !errors.bik &&
          !errors.bank &&
          !errors.rs &&
          !errors.ks &&
          formik.values.bik &&
          formik.values.bank &&
          formik.values.rs &&
          formik.values.ks
        ) {
          setOk(true)
        } else {
          setOk(false)
          setErrorMessage('Проверьте реквизиты')
          setTimeout(() => {
            setErrorMessage('')
          }, 2000);
        }
      }
    }
  }

  const back = () => {
    setStep(step > 1 ? step - 1 : step);
    setOk(false)
  }

  useEffect(() => {
    if (creatingCompany) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [creatingCompany])

  return (
    <div className={styles.b2bCompanyCreator}>
      <div className={styles.poptop}>
        <h3>Добавить компанию</h3>
        <Cross onClick={close} className={styles.cross} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.colsContainer}>
          {
            step === 1 ? (
              <div className={styles.step}>
                <h4>Данные компании:</h4>
                <StyledInput
                  disabled={!!inn}
                  type="text"
                  placeholder='ИНН'
                  name='inn'
                  value={formik.values.inn || ''}
                  onChange={onChangeNumberHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.inn && formik.errors.inn}
                />
                <StyledInput
                  disabled={!!name}
                  type="text"
                  placeholder='Наименование'
                  name='name'
                  value={formik.values.name}
                  onChange={onChangeStringHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
                <StyledInput
                  disabled={!!address}
                  type="text"
                  placeholder='Юридический адрес'
                  name='address'
                  value={formik.values.address}
                  onChange={onChangeStringHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address && formik.errors.address}
                />
                <StyledInput
                  disabled={!!mainOkved}
                  type="text"
                  placeholder='ОКВЭД'
                  name='mainOkved'
                  value={formik.values.mainOkved || ''}
                  onChange={onChangeNumberHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.mainOkved && formik.errors.mainOkved}
                />
                <StyledInput
                  disabled={!!kpp}
                  type="text"
                  placeholder='КПП'
                  name='kpp'
                  value={formik.values.kpp}
                  onChange={onChangeStringHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.kpp && formik.errors.kpp}
                />
                <StyledInput
                  disabled={!!ogrn}
                  type="text"
                  placeholder='ОГРН'
                  name='ogrn'
                  value={formik.values.ogrn}
                  onChange={onChangeStringHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.ogrn && formik.errors.ogrn}
                />
                <StyledInput
                  disabled={!!fio}
                  type="text"
                  placeholder='Руководитель'
                  name='fio'
                  value={formik.values.fio}
                  onChange={onChangeStringHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fio && formik.errors.fio}
                />
                <StyledInput
                  disabled={!!phone}
                  type="text"
                  placeholder='Телефон'
                  name='phone'
                  value={formik.values.phone}
                  onChange={onChangeStringHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && formik.errors.phone}
                />
              </div>
            ) : step === 2 ? (
              <div className={styles.step}>
                <div className={styles.titleWrap}>
                  <Arr className={styles.arr} onClick={back} />
                  <h4>Банковские реквизиты:</h4>
                </div>
                <StyledInput
                  disabled={!!bik}
                  type="text"
                  placeholder='БИК (9)'
                  name='bik'
                  value={formik.values.bik || ''}
                  onChange={onChangeNumberHandlerBik}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bik && formik.errors.bik}
                />
                <StyledInput
                  disabled={!!bank}
                  type="text"
                  placeholder='Банк'
                  name='bank'
                  value={formik.values.bank}
                  onChange={onChangeStringHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bank && formik.errors.bank}
                />
                <StyledInput
                  disabled={!!ks}
                  type="text"
                  placeholder='к/с (20/28)'
                  name='ks'
                  value={formik.values.ks || ''}
                  onChange={onChangeNumberHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.ks && formik.errors.ks}
                />
                <StyledInput
                  disabled={!!rs}
                  type="text"
                  placeholder='р/с (20/28)'
                  name='rs'
                  value={formik.values.rs || ''}
                  onChange={onChangeNumberHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.rs && formik.errors.rs}
                />
                {/* <span>р/c - необязатльное поле</span> */}
              </div>
            ) : step === 3 ? (
              <div className={styles.okWrap}>
                <img src={success} alt="success" />
              </div>
            ) : <></>
          }
        </div>
        {errorMessage ? error : <></>}
        {okMessage ? okM : <></>}
        {
          step === 2 ?
            <div className={styles.btnsContainer}>
              <StyledButton
                onClick={() => nextStep()}
                className={styles.addBtn}
              >
                Добавить
              </StyledButton>
              <StyledButton
                onClick={() => nextStep(true)}
                className={styles.addBtn}
              >
                Пропустить
              </StyledButton>
            </div>
            : step === 1 ?
              <StyledButton
                onClick={() => nextStep()}
                className={styles.addBtn}
              >
                Далее
              </StyledButton>
              :
              <StyledButton
                // onClick={() => nextStep()}
                className={styles.addBtn}
                disabled={true}
              >
                Компания успешно добавлена
              </StyledButton>
        }
      </form>
    </div>
  );
};