import styles from './FifthBlock.module.scss';
import icon1 from '../../../assets/icons/p2p/icon1.svg'
import icon2 from '../../../assets/icons/p2p/icon2.svg'
import icon3 from '../../../assets/icons/p2p/icon3.svg'
import icon4 from '../../../assets/icons/p2p/icon4.svg'
import icon5 from '../../../assets/icons/p2p/icon5.svg'
import icon6 from '../../../assets/icons/p2p/icon6.svg'
export const FifthBlock = () => {
  return (
    <>
      <div className={styles.advantagesBlock}>
        <h2>Преимущества Р2Р платформы</h2>
        <div className={styles.advantagesBox}>
          <div className={styles.advantagesItem}>
            <div className={styles.advantagesTop}>
              <strong>Стоимость</strong>
              <img src={icon1} />
            </div>
            <div className={styles.advantagesUp}>
              <p>У вас есть возможность определить желаемую цену при покупке или продаже криптовалюты</p>
            </div>
          </div>
          <div className={styles.advantagesItem}>
            <div className={styles.advantagesTop}>
              <strong>Комиссии</strong>
              <img src={icon2} />
            </div>
            <div className={styles.advantagesUp}>
              <p>На нашем сервисе нет скрытых платежей. Мы взимаем комиссию с создателя предложения только в размере 0,2%, а комиссия сети — единственная плата за вывод криптовалюты</p>
            </div>
          </div>
          <div className={styles.advantagesItem}>
            <div className={styles.advantagesTop}>
              <strong>Безопасность</strong>
              <img src={icon3} />
            </div>
            <div className={styles.advantagesUp}>
              <p>Наша команда способствует честным и надежным сделкам между контрагентами</p>
            </div>
          </div>
          <div className={styles.advantagesItem}>
            <div className={styles.advantagesTop}>
              <strong>Способы оплаты</strong>
              <img src={icon4} />
            </div>
            <div className={styles.advantagesUp}>
              <p>Мы обеспечиваем поддержку широкого спектра популярных способов оплаты и постоянно обновляем список новыми вариантами</p>
            </div>
          </div>
          <div className={styles.advantagesItem}>
            <div className={styles.advantagesTop}>
              <strong>Конфиденциальность</strong>
              <img src={icon5} />
            </div>
            <div className={styles.advantagesUp}>
              <p>В отличие от банковских переводов, платформы P2P не собирают никакой информации о покупателе и продавце</p>
            </div>
          </div>
          <div className={styles.advantagesItem}>
            <div className={styles.advantagesTop}>
              <strong>Лёгкий старт</strong>
              <img src={icon6} />
            </div>
            <div className={styles.advantagesUp}>
              <p>После того, как вы успешно завершили процесс KYC транзакции выполняют три простых  шага. Весь процесс обычно занимает от 5 до 10 минут.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
