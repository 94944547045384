import React from 'react'
import styles from './MessageList.module.scss'
import Message from './message/Message'
import { IMessage } from '../../../types/types'

interface MessageListPropsType {
  userId: number,
  messageList: IMessage[]
}

const MessageList = ({
  userId,
  messageList
}: MessageListPropsType) => {
  return (
    <div className={styles.messageBlock} id='messageBlock'>
      {
        messageList && messageList.map((message, index) => <Message userId={userId} message={message} key={index} />)
      }
    </div>
  )
}

export default MessageList