import React, { useCallback, useEffect, useState } from 'react';
import styles from './Trade.module.scss';
import { useLocation } from "react-router-dom";
import Container from '../../UI/container/Container';
import Skeleton from 'react-loading-skeleton';
import { ApealProps, IMessage, OrderB2bHistory, UserProfile } from '../../types/types';
import { ProfileInfo } from '../../services/UserService';
import TradeSide from './TradeSide';
import Steps from '../../components/paymentB2bComponents/steps/Steps';
import ChatBlockB2b from '../../components/paymentB2bComponents/chatBlockB2b/ChatBlockB2b';
import { GetAllB2bOffers } from '../../services/P2p';
import Chat from '../../components/chat/Chat';
import { GetMessageList, GetMessageListB2b, NewMessage } from '../../services/ChatService';

export const Trade = () => {
  const [openChat, setOpenChat] = useState(false)
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [showApeal, setShowApeal] = useState<ApealProps | null>(null)
  const location = useLocation();
  const token = localStorage.getItem("access_token") ?? '';

  const checkToken = async (token: string) => {
    const { data } = await ProfileInfo(token);
    if (data === 'Token не найден' || data === undefined) {
      localStorage.removeItem("access_token");
      document.location.href = '/'
    } else {
      setProfile(data)
    }
  }


  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? '';
    checkToken(token);
  }, [location])

  const [order, setOrder] = useState<OrderB2bHistory | null>(null)

  const [messageList, setMessageList] = useState<IMessage[]>([])
  const [isNewMessage, setIsNewMessage] = useState(false)
  const messageBlock = document.getElementById('messageBlock') || document.createElement("div");

  useEffect(() => {
    if (isNewMessage) {
      (messageBlock as HTMLDivElement).scrollTo(0, +messageBlock.scrollHeight);
      setIsNewMessage(false)
    }
  }, [isNewMessage, messageBlock])

  const sendNullMessage = async (order: OrderB2bHistory) => {
    if (!order || !profile) return;
    const userId = order.author_id !== profile.id ? order.company_id : order.author_id;
    const roomId = order.chat_room_id;
    const formData = new FormData();
    formData.append("text", '')
    formData.append("attachment_type", '')
    formData.append("sender_user_id", userId.toString())
    formData.append("chat_room_id", roomId.toString())
    const data = await NewMessage(formData)
  }

  const getMessageList = useCallback(async (order: OrderB2bHistory) => {
    if (!order) return
    const data = await GetMessageList(order.chat_room_id)
    if (data.status === 200 && data.data && data.data.Items) {
      if (data.data.Items.length > messageList.length) { setIsNewMessage(true) };
      setMessageList(data.data.Items.reverse())
    }
  }, [messageList.length, order])

  const getCurrentOrder = useCallback(async () => {
    if (!profile) return
    const data = await GetAllB2bOffers(token, profile.id)
    if (data.status === 200) {
      //@ts-ignore
      if ((location.state as OrderB2bHistory)?.orderId) {
        const currentOrder = (data.data as OrderB2bHistory[])
          //@ts-ignore
          .filter(ord => +ord.b2b_ads_id === +(location.state as OrderB2bHistory)?.orderId ?? 0);
        setOrder(currentOrder[currentOrder.length - 1])
        getMessageList(currentOrder[currentOrder.length - 1])
        sendNullMessage(currentOrder[currentOrder.length - 1])
      } else {
        const currentOrder = (data.data as OrderB2bHistory[])
          // .filter(ord => +ord.b2b_ads_id === +(location.state as OrderB2bHistory)?.b2b_ads_id ?? 0);
          .filter(ord => ord.start_date === (location.state as OrderB2bHistory)?.start_date ?? '');
        setOrder(currentOrder[currentOrder.length - 1])
        getMessageList(currentOrder[currentOrder.length - 1])
        sendNullMessage(currentOrder[currentOrder.length - 1])
      }
    }
  }, [location.state, profile, token])

  useEffect(() => {
    getCurrentOrder()
  }, [getCurrentOrder])

  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentOrder()
    }, 6000)
    return () => clearInterval(interval);
  }, [getCurrentOrder])

  const [unread, setUnread] = useState('');

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!reload || !order) return
    getMessageList(order);
    setReload(false)
  }, [getMessageList, order, reload, setReload])


  return (
    <Container className={styles.main}>
      {order && profile ?
        <>
          <div className={styles.topBlock}>
            <Steps step={order.status_history} offerData={order} />
          </div>
          <div className={styles.bottomBlock}>
            <div className={styles.leftBlock}>
              <TradeSide
                unread={unread}
                currentOfferData={order}
                profile={profile}
                setOpenChat={setOpenChat}
                setShowApeal={setShowApeal}
              />
            </div>
            <div className={styles.rightBlock}>
              {/* <ChatBlockB2b
                setUnread={setUnread}
                openChat={openChat}
                setOpenChat={setOpenChat}
                agentName={profile.id === order.author_id ? order.company : order.author}
                userId={order.author_id !== profile.id ? order.company_id : order.author_id}
                avatar={order.author_id === profile.id ? order.avatar_creator : order.avatar_author}
                orderId={order.b2b_ads_id}
                date={order.start_date}
              /> */}
              <Chat
                setReload={setReload}
                messageList={messageList}
                openChat={openChat}
                setOpenChat={setOpenChat}
                agentName={profile.id === order.author_id ? order.company : order.author}
                userId={order.author_id !== profile.id ? order.company_id : order.author_id}
                avatar={profile.id === order.author_id ? order.avatar_creator : order.avatar_author}
                setUnread={setUnread}
                roomId={order.chat_room_id}
              />
            </div>
          </div>
          <div className={styles.rightBlockSmall} style={openChat ? { display: 'block' } : { display: 'none' }}>
            {/* <ChatBlockB2b
              setUnread={setUnread}
              openChat={openChat}
              setOpenChat={setOpenChat}
              agentName={profile.id === order.author_id ? order.company : order.author}
              userId={order.author_id !== profile.id ? order.company_id : order.author_id}
              avatar={order.author_id === profile.id ? order.avatar_creator : order.avatar_author}
              orderId={order.b2b_ads_id}
              date={order.start_date}
            /> */}
            <Chat
              setReload={setReload}
              messageList={messageList}
              openChat={openChat}
              setOpenChat={setOpenChat}
              agentName={profile.id === order.author_id ? order.company : order.author}
              userId={order.author_id !== profile.id ? order.company_id : order.author_id}
              avatar={profile.id === order.author_id ? order.avatar_creator : order.avatar_author}
              setUnread={setUnread}
              roomId={order.chat_room_id}
            />
          </div>
        </>
        :
        <div style={{ display: 'block', width: '100%' }}>
          <Container className={styles.skeletonContainer}>
            <div className={styles.skeletonBlock1}>
              <div style={{ marginBottom: '10px' }}>
                <Skeleton height={150} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
              </div>
            </div>
            <div className={styles.containerS}>
              <div className={styles.skeletonBlock2}>
                <div>
                  <Skeleton height={500} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
                </div>
              </div>
              <div className={styles.skeletonBlock3}>
                <div>
                  <Skeleton height={500} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
                </div>
              </div>
            </div>
          </Container>
        </div>
      }
    </Container>
  );
};