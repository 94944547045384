import React, { useState } from 'react';
import styles from './Swap.module.scss';

interface ISwapFieldProps {
    side: "From" | "To",
    withMax?: number,
    icon: string,
    currency: string,
    amount: number,
    setAmount: React.Dispatch<React.SetStateAction<number>>,
    onClick?: () => void
}

export const SwapField = (props: ISwapFieldProps) => {
    return <div className={styles.swap__field}>
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: "center" }}>
                <span className={styles.field__side}>{props.side}</span>
                <div className={styles.field__token}>
                    <img alt="currency" src={props.icon} />
                    &nbsp;{props.currency}
                </div>
            </div>
            {!isNaN(Number(props?.withMax)) ? <button className={styles.maxBtn} onClick={props?.onClick}>
                Макс. {props?.withMax ?? ""}
            </button> : null}
        </div>
        <div className={styles.field__inputWrapper}>
            <input 
                value={props.amount > 0 ? props.amount : ""}
                onChange={e => {
                    const value = Number(e.target.value)
                    if (isNaN(value) || value.toString().includes(',')) return

                    props.setAmount(() => value)
                }}
                placeholder='0' 
                className={styles.field__input} 
                disabled={props.side == "To"} />
            <span className={styles.field__inputToken}>{props.currency}</span>
        </div>
    </div>
}