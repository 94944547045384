import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './News.module.scss';
import { NavLink } from 'react-router-dom';
import logo from "../../assets/img/logo.svg";
import Container from '../../UI/container/Container';
import Paginator from '../../components/paginator/Paginator';

const News = () => {
  const [page, setPage] = useState(1)
  // const url = `https://newsapi.org/v2/everything?q=p2p&language=ru&apiKey=68592866347b4cd396ecfa77554182ef&page=${page}&pageSize=20`;
  // const url = `https://api.thenewsapi.com/v1/news/all?search=p2p&language=ru&api_token=zeS2B0EFfuCj9gUoNhuntQyitoVG9P6zdMaQO6um&page=${page}&limit=20`;
  const url = `https://app.zenserp.com/api/v2/search?apikey=8fa6c7e0-da1f-11ee-bf55-0913a51bdfdd&q=p2p&num=20&start=${page}&tbm=nws&search_engine=google.ru`;
  const [content, setContent] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    axios.get(url)
      .then(res => {
        // setContent(res.data.articles)
        // setContent(res.data.data)
        setContent(res.data.news_results)
        setLoading(false)
      })
      .catch(e => {
        console.log(e);
      })
  }, [url])

  const transliterate = (word: any) => {
    var answer = ""
      , a: any = {};
    //@ts-ignore
    a["Ё"] = "YO"; a["Й"] = "I"; a["Ц"] = "TS"; a["У"] = "U"; a["К"] = "K"; a["Е"] = "E"; a["Н"] = "N"; a["Г"] = "G"; a["Ш"] = "SH"; a["Щ"] = "SCH"; a["З"] = "Z"; a["Х"] = "H"; a["Ъ"] = "'";
    //@ts-ignore
    a["ё"] = "yo"; a["й"] = "i"; a["ц"] = "ts"; a["у"] = "u"; a["к"] = "k"; a["е"] = "e"; a["н"] = "n"; a["г"] = "g"; a["ш"] = "sh"; a["щ"] = "sch"; a["з"] = "z"; a["х"] = "h"; a["ъ"] = "'";
    //@ts-ignore
    a["Ф"] = "F"; a["Ы"] = "I"; a["В"] = "V"; a["А"] = "A"; a["П"] = "P"; a["Р"] = "R"; a["О"] = "O"; a["Л"] = "L"; a["Д"] = "D"; a["Ж"] = "ZH"; a["Э"] = "E";
    //@ts-ignore
    a["ф"] = "f"; a["ы"] = "i"; a["в"] = "v"; a["а"] = "a"; a["п"] = "p"; a["р"] = "r"; a["о"] = "o"; a["л"] = "l"; a["д"] = "d"; a["ж"] = "zh"; a["э"] = "e";
    //@ts-ignore
    a["Я"] = "Ya"; a["Ч"] = "CH"; a["С"] = "S"; a["М"] = "M"; a["И"] = "I"; a["Т"] = "T"; a["Ь"] = "'"; a["Б"] = "B"; a["Ю"] = "YU";
    //@ts-ignore
    a["я"] = "ya"; a["ч"] = "ch"; a["с"] = "s"; a["м"] = "m"; a["и"] = "i"; a["т"] = "t"; a["ь"] = "'"; a["б"] = "b"; a["ю"] = "yu";
    for (let i in word) {
      if (word.hasOwnProperty(i)) {
        if (a[word[i]] === undefined) {
          answer += word[i];
        } else {
          answer += a[word[i]];
        }
      }
    }
    return answer;
  }



  const [offersToRender, setOffersToRender] = useState<any[]>([])

  return (
    <div id='here'>
      <Container className={styles.container}>
        <div className={styles.title}>Новости</div>
        <div className={styles.mainContainer}>
          {
            loading ?
              <div className={styles.newsContainer}>
                <div className={styles.loaderContainer}>
                  <div className={styles.ldsRoller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
              </div>
              :
              <div className={styles.newsContainer}>
                {
                  offersToRender ?
                    offersToRender.map((el: any, index: any) => {
                      return <NavLink
                        key={index}
                        to={`./${transliterate(el.title.replace(/\u00a0/g, " ").split(' ')[el.title.replace(/\u00a0/g, " ").split(' ').length - 3])}-${transliterate(el.title.replace(/\u00a0/g, " ").split(' ')[el.title.replace(/\u00a0/g, " ").split(' ').length - 2])}-${transliterate(el.title.replace(/\u00a0/g, " ").split(' ')[el.title.replace(/\u00a0/g, " ").split(' ').length - 1])}`}
                        state={el}
                        className={styles.newsItem}
                      >
                        <div className={styles.newsItem2}>
                          <div className={styles.elTitle}>{el.title.replace(/\u00a0/g, " ").split(' ').length > 10 ? el.title.replace(/\u00a0/g, " ").split(' ').slice(0, 10).join(' ') + '...' : el.title.replace(/\u00a0/g, " ")}</div>
                          <div className={styles.elTime}>
                            {/* {new Date(el.publishedAt).toLocaleDateString()}{' '}
                            {new Date(el.publishedAt).getHours().toString().length === 1 ? `0${new Date(el.publishedAt).getHours()}` : new Date(el.publishedAt).getHours()}
                            :
                            {new Date(el.publishedAt).getMinutes().toString().length === 1 ? `0${new Date(el.publishedAt).getMinutes()}` : new Date(el.publishedAt).getMinutes()}
                            :
                            {new Date(el.publishedAt).getSeconds().toString().length === 1 ? `0${new Date(el.publishedAt).getSeconds()}` : new Date(el.publishedAt).getSeconds()} */}
                            {el.date}
                          </div>
                          <div className={styles.elDesc}>{el.description.replace(/\u00a0/g, " ").split(' ').length > 20 ? el.description.replace(/\u00a0/g, " ").split(' ').slice(0, 20).join(' ') + '...' : el.description.replace(/\u00a0/g, " ")}</div>
                        </div>
                      </NavLink>
                    })
                    :
                    <></>
                }
                {content?.length ? (
                  <div className={styles.pagContainer}>
                    <Paginator offers={content} setOffersToRender={setOffersToRender} />
                  </div>
                ) : <></>}
              </div>
          }
          <div className={styles.asideContainer}>
            <img src={logo} alt="" className={styles.asideLogo} />
          </div>
        </div>
      </Container>
    </div>
  )
}
// 68592866347b4cd396ecfa77554182ef
export default News