import React, { useEffect, useState } from 'react';
import style from './RefBlock.module.scss';
import { StyledButton } from "../../../UI/StyledButton/StyledButton";
import { RefCalculator } from "./RefCalculator/RefCalculator";
import AuthorizationBlock from '../../AuthorizationComponents/AuthorizationBlock';

export const RefBlock = () => {
    const [openAuth, setOpenAuth] = useState(false);
    const [status, setStatus] = useState(false);
    const token = localStorage.getItem('access_token') ?? '';

    useEffect(() => {
        token ? setStatus(true) : setStatus(false);
    }, [token])

    const getRef = () => {
        token ? document.location = '/profile' : setOpenAuth(true);
    };

    return (
        <>
            <div className={style.refBlock}>
                <RefCalculator />
                <div className={style.rightContainer}>
                    <h3>Торгуй вместе с друзьями и зарабатывай больше</h3>
                    <StyledButton className={style.refButton} onClick={getRef}>
                        Получить реферальную ссылку
                    </StyledButton>
                </div>
            </div>
            <AuthorizationBlock
                type={'login'}
                openModal={openAuth}
                setOpenModal={setOpenAuth}
                setStatus={setStatus}
            />
        </>
    );
};