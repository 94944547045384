import React from 'react'
import styles from './Rules.module.scss'
import Container from '../../UI/container/Container'

const Rules = () => {
  return (
    <div className={styles.rulesContainer}>
      <Container>
        <div className={styles.main}>
          <div className={styles.title}>Правила проведения транзакций между пользователями P2P-платформы</div>
          <div className={styles.block}>
            <div className={styles.subtitle}>
              <span className={styles.num}>I.</span> ВСЕ ПОЛЬЗОВАТЕЛИ ДОЛЖНЫ СТРОГО ПРИДЕРЖИВАТЬСЯ ПРИНЦИПОВ ВЕРИФИКАЦИИ (KYC), ПРОТИВОДЕЙСТВИЯ ОТМЫВАНИЮ ДЕНЕГ И ФИНАНСИРОВАНИЮ ТЕРРОРИЗМА, А ТАКЖЕ ПРИНЦИПОВ ЗАКОННОСТИ, ИЗЛОЖЕННЫХ В НАСТОЯЩЕЙ ПОЛИТИКЕ.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.</span> Пользователи должны строго следовать правилам верификации на платформе (KYC), в том числе требованиям к проверке реального имени.
              Такие правила касаются личных данных пользователей и аккаунтов, связанных с P2P-транзакциями, в том числе спотовых аккаунтов и аккаунтов с фиатными валютами (все вместе далее — Аккаунты). Пользователи должны гарантировать, что Аккаунты, которые они задействуют на P2P-платформе (далее — «Платформа») зарегистрированы законно и используются только ими.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.</span> Если у Платформы возникнут подозрения в операциях по отмыванию денежных средств и финансированию терроризма, Пользователи должны активно сотрудничать с платформой в рамках проверки таких операций, а также по мере необходимости оказывать содействие в получении доказательств для контроля рисков (с учетом доступных в тот момент на платформе мер контроля риска).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.</span> Если Пользователи не выполнят условие пункта I.2., Платформа имеет право отключить их учетную запись полностью или частично.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.</span> Пользователи обязуются обеспечить наличие у них законных прав на осуществление транзакций в соответствии с применимым законодательством. Платформа не гарантирует законность транзакций пользователей. Платформа не представляет ни одну из сторон P2P-транзакций между пользователями, но в целях обеспечения законности всех операций Платформа может (но не обязуется) проверять законность транзакций, осуществляемых пользователями, а также предпринимать необходимые меры в соответствии с применимым законодательством и правовыми нормами.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.</span> Пользователи несут ответственность за соблюдение всех местных законов, определяющих правомерность использования Платформы в их юрисдикции, а также других применимых к Пользователям законов и правил. Пользователи также должны учитывать все аспекты местного законодательства в отношении налогообложения, удержаний, сборов, отчетности и отчислений в соответствующие налоговые органы.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>
              <span className={styles.num}>II.</span> Общие торговые инструкции
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.</span> Прежде чем разместить ордер с контрагентом, внимательно прочитайте его условия на странице описания объявления, чтобы избежать лишних споров между сторонами после размещения ордера.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.</span> Старайтесь четко описывать условия объявления. Мы предлагаем использовать списки, выделять пункты и отдельные абзацы.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.</span> Удостоверьтесь в том, что способ оплаты, указанный при оформлении ордера, соответствует способу оплаты, который вы выбрали при настройке объявления.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.</span> Избегайте участия в любых сделках с криптовалютами через сторонние каналы, включая социальные сети, мессенджеры и сделки при личной встрече. Платформа не несет и не может нести ответственности за любые убытки и ущерб для активов в результате транзакций, выполненных или инициированных пользователями вне Платформы.
              Платформа устанавливает правила транзакций между Продавцом и Покупателем исключительно для поддержки работы пользователей в сервисах, доступных на Платформе.
              Платформа не выступает ни одной из сторон в P2P-транзакциях.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.</span> Пользователям следует воздерживаться от использования оскорбительной и ненормативной лексики на Платформе. Это включает, помимо прочего, общение в чате с пользователями и в онлайн-чате службы поддержки, а также в комментариях ордера.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>6.</span> Обе стороны сделки должны самостоятельно оплачивать дополнительные операционные расходы, связанные с фиатными переводами, например комиссию за транзакции поставщика платежных услуг, если только до сделки ни одна из сторон не выразила четкого согласия на оплату дополнительных операционных расходов. Таким образом, сумма перевода должна включать все расходы по операции. (Например, если общая сумма ордера равняется 10 000 USD и поставщик платежных услуг дополнительно взимает 5 USD, покупатель должен заплатить 10 005, а не 10 000 USD). Информация для транзакций с RUB, UAH, KZT, IDR, BDT: Продавец всегда должен получать полную сумму, а Покупатель всегда несет ответственность за комиссии транзакций в фиатной валюте, которые взимает поставщик платежных услуг.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>
              <span className={styles.num}>III.</span> Инструкция для покупателя
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.</span> Вам необходимо выбрать способ оплаты, доступный для Продавца, и перевести сумму, указанную на странице ордера, в течение указанного Продавцом времени. Обязательно нажмите кнопку Оплачено, далее после оплаты.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.</span> Используйте способ оплаты, в котором имя владельца аккаунта соответствует вашему имени, указанному при регистрации на GREEnavi P2P. (оно должно соответствовать имени, подтвержденному верификацией на GREEnavi P2P).
              Платформа не поддерживает способы оплаты, которые не были верифицированы с использованием настоящего имени. Функция P2P будет ограничена для пользователя минимум на 15 дней при использовании способа оплаты, верифицированного с помощью ненастоящего имени.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.</span> Не нажимайте кнопку Оплачено, далее, пока платеж не будет выполнен и завершен. Если вы нажмете Оплачено, далее до совершения оплаты, ваш Аккаунт может быть заблокирован.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.</span> Если Продавец установил моментальную или быструю оплату в качестве способа оплаты, любые другие способы оплаты, к примеру, международный перевод, не могут быть использованы без предварительного согласия Продавца.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.</span> Во время отправки платежа Продавцу в фиатной валюте рекомендуется не использовать в поле с примечаниями к транзакции слова, связанные в цифровыми активами (BTC, USDT, криптовалюта, покупка на GREEnavi P2P и т. д.). Это позволит избежать возможных задержек и отказа платежа.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>6.</span> Если вы совершили платеж и нажали Оплачено, но Продавец не выполнил перевод соответствующих Цифровых активов (далее «Перевод») в течение указанного времени, вы должны сначала связаться с Продавцом в чате.
              Если вы уверены, что соблюдаете правила Платформы и выполнили все процедуры, предусмотренные ею (в том числе предоставили необходимые документы, соответствующие требованиям платформы), но не получили ответа от Продавца, то можете подать апелляцию.
              По запросу пользователя команда P2P может помочь оформить жалобу в соответствии с правилами подачи апелляции.
              При этом Платформа не выступает ни одной из сторон P2P-транзакции. Таким образом, Платформа не обязана вмешиваться в споры между участниками P2P-транзакций. Обратите внимание, что GREEnavi P2P оставляют за собой право на окончательную интерпретацию и заключение по апелляциям. Повторное рассмотрение апелляций возможно по усмотрению GREEnavi P2P в зависимости от конкретного случая.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.</span> В случае любых нарушений инструкций для покупателя Платформа может, в соответствии с настоящей Политикой, Условиями или соответствующими законами и нормативными актами, по своему собственному усмотрению принимать меры, которые посчитает подходящими, включая (помимо прочего) блокировку аккаунта нарушителя и отключение любых его функций на торговой платформе GREEnavi.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.</span> При размещении объявления инструкция для покупателей в Условиях торговли не должна противоречить правилам действующей Политики транзакций между пользователями (цена, комиссии, примечания и прочее).
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>
              <span className={styles.num}>IV.</span> Инструкция для продавца
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.</span> Имя владельца счета, используемого для получения оплаты, должно соответствовать вашему имени, использованному во время регистрации на GREEnavi P2P. (Имя должно совпадать с именем, использованным для верификации на GREEnavi P2P.)
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.</span> После подтверждения полной оплаты Покупателем, Продавец должен исполнить ордер (перевести криптовалюту покупателю) в течение 15 минут.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.</span> Если иное не предусмотрено платформой, никто не может требовать от Службы поддержки отмены ордера без согласия Покупателя.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.</span> Цены на ордера, установленные между Продавцом и Покупателем, считаются окончательными и не подлежат обсуждению.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.</span> Если у вас есть незавершенные сделки или разногласия с Покупателем, вы должны постоянно быть на связи. Вы должны быть доступны по номеру телефона или электронной почте, указанным во время регистрации на Платформе.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>6.</span> При наличии дополнительной комиссии со стороны платежной системы Продавец должен предоставить подтверждение транзакции с четко указанной списанной суммой.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.</span> При размещении объявления инструкция для Продавцов в Условиях торговли не должна противоречить правилам действующей Политики транзакций между пользователями (цена, комиссии, примечания и прочее).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.</span> В случае нарушения инструкции для продавцов Платформа вправе принять необходимые меры в соответствии с Политикой, Условиями, соответствующим законодательством и правовыми нормами или по собственному усмотрению.
              Такие меры могут включать, в числе прочего, блокировку аккаунта нарушителя и отключение его функций на торговой платформе GREEnavi P2P
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>
              <span className={styles.num}>V.</span> Предупреждение о рисках и отказ от ответственности
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.</span> Пользователи в полной мере осознают риски, связанные с P2P-транзакциями, которые в числе прочего включают риск изменения стоимости цифровых активов, риски, связанные с надежностью участников P2P-транзакций, а также юридические риски при проведении транзакций с фиатной валютой.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.</span> Пользователи должны обладать достаточными знаниями и опытом в области инвестиций, быть способными взять на себя риски, связанные с P2P-транзакциями и согласиться самостоятельно нести все риски, связанные с осуществлением P2P-транзакций.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.</span> Пользователи не должны указывать личную информацию (включая номер телефона, социальные сети, мессенджеры и т. д.) ни в условиях сделки, ни в автоматическом сообщении, ни в чате ордера.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.</span> Запрещается использовать GREEnavi P2P, названия местных банков и платежных систем, личную информацию и какие-либо конфиденциальные данные в никнейме.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.</span> Пользователи не могут использовать несколько аккаунтов для торговли на P2P-платформе. В случае обнаружения таких аккаунтов мы имеем право заблокировать возможность торговли для всех связанных с ними аккаунтов.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>6.</span> Перед тем как выполнять P2P-транзакцию, вы должны прочитать и понять все содержимое настоящей Политики, Условий и соответствующих правил GREEnavi P2P
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.</span> Платформа не является ни одной из сторон P2P-транзакций. Поэтому любые споры между пользователями, осуществляющими P2P-транзакции, не имеют отношения к Платформе. Платформа не обладает правами или обязательствами по разрешению возникающих на ней споров. Платформа не имеет финансовых или нефинансовых обязательств и не несет ответственности перед любой из сторон (в числе прочего не дает никаких гарантий компенсации).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.</span> Вы соглашаетесь и разрешаете Платформе предпринимать все разумные действия (в числе прочего в определенных обстоятельствах отменять операции и отключать функции аккаунтов при определенных обстоятельствах) в соответствии с настоящей Политикой, Условиями и соответствующими правилами использования платформ GREEnavi для защиты ваших законных прав, законных прав платформы и других пользователей.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.</span> Обратите внимание: способы оплаты, указанные на GREEnavi P2P, представляют возможные варианты фиатных транзакций между пользователями.
              GREEnavi P2P не устанавливает каких-либо коммерческих отношений с поставщиками указанных платежных методов на своей P2P-платформе.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}><span className={styles.num}>VI.</span> Нестандартное поведение при торговле и меры по противодействию ему</div>
            <div className={styles.text}>
              Вы соглашаетесь с тем, что, насколько это допускает действующее законодательство, Платформа не несет ответственности за любые убытки в результате P2P-транзакций и использования услуг в соответствии с настоящей Политикой, если такие убытки возникли по причинам, не связанным с преднамеренными действиями или грубой халатностью со стороны Платформы, в числе прочего, в результате хакерских атак, перебоев в электроснабжении и неустранимых технических сбоев.
            </div>
            <div className={styles.text}>
              Платформа имеет право ограничить, приостановить или прекратить действие вашего Аккаунта или ограничить доступ к услугам немедленно и без предварительного уведомления на период по собственному усмотрению, если:
            </div>
            <div className={styles.text}>
              • Мы обоснованно подозреваем вас в нарушении или возможности нарушения настоящей Политики.
            </div>
            <div className={styles.text}>
              • Мы обнаружили, что вы используете несколько аккаунтов для P2P-торговли;
            </div>
            <div className={styles.text}>
              • Мы посчитали необходимым защитить других пользователей. В случае принятия решения ограничить или отказать вам в доступе к сервису, мы не несем ответственность за любые последствия в результате ограничения или отказа доступа к Сервису, включая
              возможные задержки, убытки или неудобства.
            </div>
            <div className={styles.text}>
              • Мы посчитали необходимым защитить других пользователей. В случае принятия решения ограничить или отказать вам в доступе к сервису, мы не несем ответственность за любые последствия в результате ограничения или отказа доступа к Сервису, включая
              возможные задержки, убытки или неудобства.
            </div>
            <div className={styles.text}>
              • Выяснилось, что в процессе взаимодействия с другими пользователями вы употребляли нецензурную лексику в адрес контрагента, оскорбляли, вымогали, преследовали, угрожали или иным образом нарушили или пытались нарушить законные права (в числе прочего право на конфиденциальность, гласность и другие права интеллектуальной собственности) других пользователей, а также осуществляли сбор личной информации других пользователей с целью распространения ее другим лицам.
            </div>
            <div className={styles.text}>
              Вы признаете, что наше решение предпринять определенные действия, включая ограничение доступа, приостановку или удаление вашей учетной записи или кошелька, может быть основано на конфиденциальных критериях, которые являются важной составляющей управления рисками и протоколов безопасности. Вы соглашаетесь с тем, что Exnode не обязана раскрывать вам подробности внутренних процедур управления рисками и мер безопасности.
            </div>
            <div className={styles.text}>
              Мы снимем ограничения на аккаунте, как только причины наложения ограничений будут устранены. Однако, даже если ли ограничение будет снято, мы не обязаны уведомлять вас о времени снятия ограничений.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>
              <span className={styles.num}>VII.</span> Обнаружение подозрительных действий системой контроля рисков
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.</span> Система контроля рисков обнаружила подозрительные действия, и вовлеченные в них пользователи явно отказываются сотрудничать с Платформой или не делают этого активно в рамках проверки на отмывание денег и противодействия финансированию терроризма.
              Платформа может определить такие действия в Аккаунтах как подозрительные и предпринять соответствующие меры, в частности, повысить уровень риска пользователя, заблокировать его аккаунт и другие связанные с ним аккаунты до тех пор, пока предоставленные пользователем доказательства не пройдут проверку Платформы.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.</span> Аккаунты пользователя предположительно задействованы в мошенничестве (или попытке мошенничества), выводе денежных средств на платформы с высоким риском (включая нелицензированные криптовалютные платформы, онлайн-платформы азартных игр, платформы, подозреваемые в отмывании денег, и другие платформы с низким уровнем контроля рисков) или в других переводах активов с высоким уровнем риска.
              Платформа вправе заблокировать или отменить операцию, вывод средств и весь функционал связанных с такими действиями P2P-аккаунтов.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.</span> Аккаунт не используется пользователем с именем, с которым он был зарегистрирован, или незаконно используется для покупки цифровых активов другими людьми. Было обнаружено, что пользователи продали или одолжили Аккаунты другим людям или незаконно купили цифровые активы для других лиц.
              Предупреждение о риске: следите за безопасностью своих данных. Это поможет избежать использования их другими лицами для совершения преступных действий.
              Платформа вправе предпринять соответствующие меры, в частности: временно или навсегда отключить функцию P2P-торговли; увеличить срок вывода средств; отключить функцию вывода средств или заблокировать Аккаунты таких пользователей или связанные с ними Аккаунты.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.</span> Получение или предоставление цифровых активов или средств из незаконных источников. Пользователи знают или могут сделать обоснованное заключение, что полученные с других платформ средства обладают незаконным происхождением или характером, но все равно позволили перевести их на Аккаунты других пользователей Платформы.
              Платформа вправе напрямую и навсегда отключить любую или все функции Учетной записи таких Пользователей и связанных Учетных записей, а также прекратить поддержку любых услуг для таких Пользователей или выполнение любых договорных обязательств.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.</span> Обнаружено, что пользователь участвовал в отмывании денег. Система контроля рисков или сотрудники отдела контроля рисками выявили, что пользователи непосредственно участвовали в отмывании денег или оказывали помощь в таких действиях.
              Платформа вправе напрямую и навсегда отключить любую или все функции Аккаунта пользователей и связанных Аккаунтов, а также прекратить поддержку услуг для таких пользователей или выполнение любых договорных обязательств.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>6.</span> Блокировка Аккаунта по решению суда. Аккаунт заблокирован по запросу судебного органа.
              Платформа может заблокировать Аккаунт по письменному запросу судебного органа в соответствии с соответствующими законами и нормативными актами. Чтобы разблокировать Аккаунт, пользователю следует обращаться в соответствующий судебный орган, а не к сотрудникам Платформы
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Rules