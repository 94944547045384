import React from 'react'
import styles from './Terms.module.scss'
import Container from '../../UI/container/Container'

const Terms = () => {
  return (
    <div className={styles.termsContainer}>
      <Container>
        <div className={styles.main}>
          <div className={styles.title}>Условия соглашения</div>
          <div className={styles.block}>
            <div className={styles.text}><span className={styles.num}>Последнее обновление: 08.09.2023</span></div>
          </div>
          <div className={styles.block}>
            <div className={styles.text}>
              Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от <span className={styles.num}>27.07.2006. № 152-ФЗ «О персональных данных»</span> (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые GREEnavi P2P (далее — Платформа).
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.text}>
              Регистрируя учетную запись на платформе GREEnavi P2P или связанными с ней веб-сайтами, а также мобильными приложениями, включая все URL адреса, входящими в состав GREEnavi P2P, вы подтверждаете, что внимательно ознакомились, поняли и согласились со всеми условиями и положениями данного соглашения, включая политику конфиденциальности платформы GREEnavi P2P.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.text}>
              Данное соглашение содержит критически важную информацию касательно пользовательских обязательств, условий, ограничений и исключений, которые могут распространяться на вас. Мы настоятельно рекомендуем ознакомиться с данным документом.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>1. Сфера распространения и цели пользовательского соглашения</div>
            <div className={styles.text}>
              <span className={styles.num}>1.1.</span> Данное соглашение устанавливает требования и включает следующее:
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.1.1.</span> Порядок предоставления услуг платформой GREEnavi P2P
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.1.2.</span> Публичную оферту пользователей платформы GREEnavi P2P
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.1.3.</span> Ограничение ответственности
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.2.</span> GREEnavi P2P торговая площадка или сервис - оптимизированная онлайн платформа, предоставляющая возможности для покупки/продажи цифровых активов, нахождение и сфера работы которой находится в сети интернет по адресу <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.3.</span> Порядок урегулирования внешних споров, связанных с функционированием сервиса, юридической ответственностью сторон за причинение вреда, а также рассмотрения споров в претензионном и судебном порядке установлен в приложении №1 к настоящему соглашению и является неотъемлемой частью настоящего соглашения, а также является обязательным для любого пользователя.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.4.</span> Перед использованием сервиса и услуг GREEnavi P2P пользователь обязан ознакомиться с условиями настоящего соглашения, приложениями к соглашению, политикой конфиденциальности сервиса, опубликованной на веб-сайте сервиса и принять их.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.5.</span> Использование сервисов сервиса возможно только в том случае, если Пользователь принимает все условия Соглашения и документы, в соответствии с пунктом 1.5. настоящего соглашения.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.6.</span> Действующая версия соглашения размещена для публичного доступа на сайте сервиса. Администрация сервиса имеет право в одностороннем порядке изменять условия настоящего соглашения в любое время. Такие изменения вступают в силу по истечении 3 (трех) календарных дней с даты размещения новой версии соглашения на веб-сайте, если в новой версии соглашения специально не предусмотрен иной порядок внесения изменений.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>2. Термины и определения</div>
            <div className={styles.text}>
              <span className={styles.num}>2.1.</span> Условное депонирование означает специальный условный крипто кошелек сервиса, на котором замораживается криптовалюта пользователя до выполнения обязательств по встречному возврату средств в счет транзакции, используемый сервисом в момент совершения пользователями транзакций по объявлениям.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.2.</span> KYC и AML - обязательная проверка “Знай своего клиента” и борьба с отмыванием денег.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.3.</span> P2P - (`Peer-to-Peer`) - способ осуществления переводов (транзакций) между физическими лицами, осуществляемый напрямую (без посредника).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.4.</span> Учетная запись – уникальная учетная запись на сервисе, которая идентифицирует пользователя.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.5.</span> Блокчейн означает непрерывную последовательную цепочку блоков (связанный список), построенную по определенным правилам, содержащую информацию о транзакциях между цифровыми ключами (кошельками) пользователей, которая может быть передана с одного компьютера (человека) на другой через интернет.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.6.</span> Заявка означает выражение намерения Пользователя воспользоваться одной из услуг, предлагаемых Сервисом.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.7.</span> Цифровой актив означает любой актив, который может быть передан через блоки в блокчейне, включая криптовалютные активы, такие как: USDT, Bitcoin, Ethereum, LTC и др.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.8.</span> Курс означает соотношение стоимости между цифровым активом и электронными деньгами, фиатной валютой или другим цифровым активом на момент совершения сделки.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.9.</span> Сервис означает систему, предоставляющую пользователям возможность обменять криптовалюту на электронные деньги и (или) национальную валюту, а также обменять электронные деньги и (или) национальную валюту на криптовалюту, расположенную и функционирующую на веб-сайте в сети интернет по адресу: <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.10.</span> Национальная валюта (фиатная валюта) - законное платежное средство соответствующего государства.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.11.</span> Объявление (Ads) - предложение о покупке и/или продаже цифровых активов, размещенное пользователями на сервисе, содержащее обязательные условия, предусмотренные формой, опубликованной на веб-сайте сервиса, либо через приложения iOS или Android для мобильных устройств, управляемое GREEnavi P2P
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.12.</span> Партнер - лицо, предоставляющее сервису услуги по привлечению потенциальных пользователей, условия которых описаны в настоящем соглашении.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.13.</span> Платеж означает передачу цифрового актива, электронных денег или фиатной валюты от одного пользователя другому пользователю или от пользователя к сервису, а также от сервиса к пользователю.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.14.</span> Платежная система - это программный и/или аппаратный продукт, разработанный третьей стороной и представляющий собой механизм для осуществления учета денежных обязательств и организации взаиморасчетов между ее пользователями.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.14.1.</span> Сервис предоставляет услугу P2P-участия в оплате, согласно Приложению №1 к настоящему соглашению.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.15.</span> Пользователь (Продавец, покупатель) означает дееспособное физическое лицо старше 18 лет и/или должным образом назначенный представитель юридического лица, который пользуется сервисом.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.16.</span> Сообщения - письма, отправленные Пользователю сервисом по электронной почте.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.17.</span> Средства означают цифровые активы, включая криптовалюты, электронные деньги и фиатную валюту, в соответствии с разделом 5 настоящего Соглашения.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.18.</span> Наши сервисы означает помощь в операциях обмена цифровыми активами P2P, а также другие услуги, информация о которых размещена в окне сервиса.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.19.</span> Электронные деньги означают средства на счетах пользователей электронных платежных систем.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>3. Режим работы сервиса. Поддержка. Контакты</div>
            <div className={styles.text}>
              <span className={styles.num}>3.1.</span> Служба поддержки платформы работает круглосуточно.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.2.</span> Пользователи могут связаться со службой поддержки, используя специальную форму по адресу <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>4. Использование учетной записи</div>
            <div className={styles.text}>
              <span className={styles.num}>4.1.</span> Учетная запись предназначена только для личного использования. Регистрируясь на сайте, пользователь дает свое согласие на передачу достоверных данных о себе в GREEnavi P2P в соответствии с процедурой регистрации на сайте. Пользователь также соглашается с тем, что он не будет использовать никакую учетную запись, кроме своей собственной, и не будет пытаться получить несанкционированный доступ к учетным записям других пользователей или к инфраструктуре сервиса.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.2.</span> Сервис имеет право проводить дополнительные проверки информации пользователя и запрашивать любую необходимую документацию по любой причине, связанной с использованием им Сервиса и/или в качестве подтверждающих доказательств для любой информации, которую пользователь предоставляет сервису (например, запрос скриншотов, видео подтверждений, онлайн-скриншоты и т.д.) непосредственно от пользователя или от сторонних поставщиков, таких как поставщики услуг AML/KYC и/или сторонние поставщики платежных услуг (например, поставщики услуг эквайринга и т.д.).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.3.</span> Администрация платформы может в любое время и по своему собственному усмотрению отказать пользователю в возможности открыть учетную запись, заблокировать ее или приостановить любую транзакцию до окончательного рассмотрения предоставленной пользователем информации.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.3.1.</span> Во время урегулирования споров, возникающих в связи с осуществлением транзакций по объявлениям пользователей, сервис может запросить любые доказательства у сторон транзакции. Порядок предоставления доказательств определяется Службой поддержки индивидуально для каждого случая.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>5. Предоставление услуг</div>
            <div className={styles.text}>
              <span className={styles.num}>5.1.</span> Использование услуг и инструментов сервиса осуществляется пользователем путем отправки заявки через веб-сайт сервиса. Заявки пользователей на покупку/продажу криптовалюты реализуются посредством создания объявлений.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.2.</span> Используя услуги и инструменты, пользователь подтверждает, что он законно владеет, использует и распоряжается цифровыми активами, электронными деньгами или фиатной валютой, участвуя в соответствующем объявлении (сделке, транзакции).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.3.</span> Платформа GREEnavi P2P не является агентом по взаиморасчетам по каким-либо транзакциям между Пользователем и любыми третьими лицами.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.4.</span> Заполняя объявление, пользователь размещает предложение о покупке/продаже цифрового актива через сервис, доступное другим Пользователям, от своего имени и за свой счет.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.5.</span> Пользователь, разместивший объявление, должен немедленно обработать все входящие запросы на это объявление. Если из-за бездействия пользователя происходит 3 последовательных отмены транзакций, его объявление автоматически закрывается. На период бездействия Пользователь, разместивший объявление, должен самостоятельно деактивировать своё объявление или установить правильное расписание в настройках объявления.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.6.</span> Отправка транзакций
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.6.1.</span> Сервис считает транзакцию завершенной, если есть 2 (два) подтверждения сети.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.6.2.</span> В момент отправки цифрового актива взимается сетевая комиссия. Сервис отправляет цифровые активы в течение 15 минут.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.6.3.</span> Сервис не владеет базовыми программными протоколами, которые управляют транзакциями с цифровыми активами, и не контролирует их. Лежащие в основе протоколы могут быть с открытым исходным кодом, и любой желающий может использовать, копировать, изменять и распространять их.
              Платформа не несет ответственности за работу базовых протоколов и не может гарантировать функциональность или безопасность сетевых операций.
              В частности, всегда существует возможность непредвиденных изменений в правилах использования протоколов (включая `вилки`). Такие важные изменения могут оказать существенное влияние на доступность, стоимость, функциональность и/или название цифрового актива. Служба не может повлиять на сроки и свойства этих существенных операционных изменений.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.6.4.</span> Сервис предоставляет возможность осуществлять транзакции с цифровыми активами (включая такие криптовалюты, как Bitcoin, Ethereum, Tether, LTC). Сервис не несет ответственности, если пользователь размещает криптовалюты, основанные на коде Bitcoin, Ethereum, Tether, LTC и др., которые не являются этими “токенами”. Пользователь понимает и принимает возможность потери своих активов, размещенных в нарушение настоящих правил Сервиса.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.6.5.</span> Стоимость цифровых активов может непредсказуемо меняться, в случае чего, возникает риск того, что вы потеряете деньги, покупая, продавая, удерживая или инвестируя в цифровые активы. Вам следует тщательно оценить свое финансовое состояние и обдумать, подходит ли вам подобная финансовая деятельность.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.7.</span> Покупка цифровых активов
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.7.1.</span> При создании объявления, пользователь должен принять во внимания все возможные комиссии. Полная стоимость передается продавцу, который указан в условиях объявления.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.7.2.</span> Объявление о покупке цифрового актива становится активным на платформе сразу после его публикации пользователем при условии наличия минимальной суммы на балансе пользователя, разместившего это объявление. Размер минимально необходимой суммы можно увидеть на странице создания/редактирования объявлений в информационном блоке.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.8.</span> Продажа цифровых активов
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.8.1.</span> Если в ходе сделки по объявлению происходит существенное изменение курса от значения, установленного при создании объявления, споры и претензии по поводу стоимости сделки невозможны. Если спор возникает после факта оплаты покупателем продавцу, сделка может быть отменена после возврата платежа покупателю и предоставления соответствующих доказательств.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.8.2.</span> Если покупатель нарушил порядок оплаты сделки, установленный правилами площадки, а также прописанный в условиях объявления (пр. не указан требуемый комментарий, осуществлен перевод несколькими транзакциями, выбран некорректный метод оплаты), продавец имеет право отказаться от выполнения условий сделки. Средства за несостоявшуюся сделку должны быть возвращены покупателю. В случае невозможности совершить возврат, требуется открыть апелляцию.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.8.3.</span> Продавец обязан самостоятельно проверить поступление денежных средств от покупателя, несмотря на доказательства и заявлений со стороны покупателя, прежде чем освободить цифровой актив от автоматического депонирования. Как только продавец снимает депонирование, сервис теряет возможность удерживать цифровой актив на балансе продавца, и цифровой актив становится доступным для снятия. Продавца становится доступным для снятия.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.8.4.</span> Максимальное время внесение цифровой валюта на условное депонирование сервиса составляет 4 часа.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.9.</span> Курсы и тарифы
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.9.1.</span> При указании рыночных курсов цифровых активов сервис GREEnavi P2P использует данные из сторонних источников (поставщиков обменных курсов). Таким образом, сервис не несет никакой ответственности за любые возможные потери/выгоды, которые произошли с пользователем на основании информации о курсе. Платформа делает все возможное, чтобы предоставить каждому пользователю наиболее точные курсы, однако платформа не может быть привлечена к ответственности за любые отклонения рыночной цены цифровых активов, которые произошли по вине поставщика курса, ложной рекламы, технических и любых других проблем.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.10.</span> При возникновении спорных ситуаций во время проведении сделки по объявлению на платформе, в случае неполучения ответа от одной из сторон в течение 10 часов с момента начала сделки, сервис может принять решение по вопросу без учета позиции стороны, которая не смогла ответить в установленный срок.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.11.</span> Запрещается отмечать сделку как оплаченную до фактического получения платежа.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.12.</span> Запрещается использовать нецензурные выражения при входе в систему, а также слова, характеризующие пользователя, как представителя платформы. Также запрещено использовать названия сторонних платформ для торговли криптовалютами.
              Несоблюдение данных требований может привести к приостановке действия учетной записи и/или штрафом в соответствии с применимым законодательством.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.13.</span> Сервис модерирует отзывы пользователей. Удаление/изменение отзыва возможна по запросу пользователя в службу поддержки, однако окончательное решение об удалении/изменении отзыва остается исключительно на усмотрение администрации сервиса.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.14.</span> Любое вмешательство в статистические данные учетной записи системы (искажение информации об отзывах, рейтингах, объемах и т.д.) строго запрещено.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.15.</span> В течение времени, указанного при создании объявления, пользователь обязан совершить перевод цифрового актива, электронных денег или фиатной валюты, согласно условиям проведения сделки. В случае невыполнения данного требования, пользователи имеют право открыть апелляцию.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.16.</span> В случае обнаружения подозрительной активности в процессе проведения сделки по объявлению, сервис, во избежание ущерба сторонам сделки имеет право приостановить выполнение всех операций до выяснения причин такой активности.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.17.</span> Сервис имеет право отказать в предоставлении услуг пользователю, если пользователь не предоставит полные данные, необходимые для его идентификации, и заблокировать средства, полученные от такого пользователя, до получения такой информации.
              Стандартный срок удержания средств сервисом составляет до 14 дней, однако, в соответствии с пунктом 4.3 соглашения, средства могут быть удержаны до разрешения разбирательства.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.18.</span> Пользуясь услугами сервиса, пользователи осуществляют транзакции по покупке/продаже цифровых активов за электронные деньги или фиатную валюту, или наоборот.
              Сервис не осуществляет и не облегчает операции с иностранной валютой в отношении национальной валюты и не подпадает под действие национального и международного законодательства о валютном регулировании и валютном контроле.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.19.</span> Сервис имеет право отменить сделки по объявлениям, если подозревает, что пользователь получил средства в результате какой-либо незаконной деятельности в соответствии с применимым законодательством или внутренним законодательством пользователя.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.20.</span> Сервис прилагает все возможные усилия для бесперебойной работы веб-сайта в соответствии с настоящим соглашением. Однако сервис может приостановить работу веб-сайта, мобильного приложения или любого другого инструмента для проведения технических работ и приложит достаточные усилия, чтобы уведомить пользователей заранее. Таким образом, пользователь соглашается с тем, что он не может всегда пользоваться услугами сервиса или совершать срочные транзакции с использованием учетной записи.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.21.</span> При работе с объявлениями пользователей администрация сервиса имеет право:
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.21.1.</span> Прекратить общение с пользователем, который нарушает деловой этикет, задает вопросы, не связанные с предоставлением услуг, или не предоставляет сервису информацию, необходимую для предоставления услуг.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.21.2.</span> При необходимости приостановить проведение сделки до тех пор, пока им не будут предоставлены полные данные для идентификации личности.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.21.3.</span> Привлекать сторонних подрядчиков для выполнения своих обязательств.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.21.4.</span> Отправлять пользователю на указанный им в учетной записи e-mail уведомления о статусе процесса сделки, а также иную информацию, касающуюся деятельности сервиса, в том числе рекламного характера.
              Пользователь может отказаться от подписки на рекламные рассылки, нажав на соответствующую кнопку в полученном письме, либо в личном кабинете.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>6. Тарифы торговой площадки</div>
            <div className={styles.text}>
              <span className={styles.num}>6.1.</span> Тарифы, сборы и комиссии за предоставление услуг определяются администрацией сервиса и публикуются на странице веб-сайта.
              Сервис имеет право в любой момент изменять тарифы без уведомления пользователей.
              Рекомендуется всегда проверять текущие тарифы на соответствующей странице.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>6.2.</span> С создателя объявления взимается фиксированная комиссия, установленная администрацией сервиса.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>6.3.</span> Вывод криптовалюты облагается дополнительной комиссией, которая определяется ценой за приоритетную транзакцию в сети и не зависит от администрации сервиса. Данная комиссия указывается в личном кабинете при выводе криптовалют.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>7. Налогообложение</div>
            <div className={styles.text}>
              <span className={styles.num}>7.1.</span> Сервис не является налоговым консультантом или налоговым агентом пользователя и не рассчитывает налоговые платежи, следовательно, не обязан уведомлять пользователя о его налоговых расходах. Пользователь обязуется самостоятельно нести ответственность за налоговые вычеты, а также уплачивать все налоги, согласно юрисдикции налогового резидентства пользователя.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.2.</span> Взаимодействие между сервисом и пользователем не может трактоваться, как установление агентских отношений, партнерских отношений, отношений совместной деятельности, личных трудовых отношений или любых других правовых отношений, прямо не предусмотренных соглашением между пользователем и сервисом.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>8. Урегулирование споров</div>
            <div className={styles.text}>
              <span className={styles.num}>8.1.</span> GREEnavi P2P сервис работает на основе законодательства Свободной зоны Аджман Медиа Сити, эмират Аджман, Объединенные Арабские Эмираты, а также ратифицированных международных соглашений (применимое право).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.2.</span> Следующая процедура должна применяться к спорам между пользователями до начала любых других процедур урегулирования споров:
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.2.1.</span> Покупатель должен подтвердить, что денежные средства были отправлены продавцу не позднее указанного в объявлении трейдера промежутка времени после того, как стороны согласуют сумму, указанную в объявлении. Если покупатель не подтвердит отправку средств, сделка автоматически прерывается, и цифровой актив направляется обратно на счет продавца.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.2.2.</span> В случае любых сбоев, расхождений и/или несоответствий между суммой цифрового актива, предлагаемого в объявлении, и суммой указанного цифрового актива, полученного покупателем, покупатель должен не позднее, чем через 120 (сто двадцать) минут после того, как Объявление отмечено как оплаченное, открыть спор.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.2.3.</span> Покупатель должен предоставить продавцу и сервису (действующему в качестве арбитра) адекватные доказательства того, что продавец не выполнил свои обязательства по объявлению, включая скриншоты, банковские квитанции и другие применимые документы.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.2.4.</span> Если в ходе процедуры урегулирования спора покупатель не предоставит сервису действительных доказательств того, что продавец не выполнил вышеуказанные обязательства или каким-либо другим образом совершил мошенническое поведение, сделка считается завершенной.
              Покупатель, который не предоставил сервису адекватных доказательств того, что продавец не выполнил свои обязательства, также может столкнуться со штрафными санкциями.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.3.</span> При урегулировании споров между сервисом и пользователем, возникающих в связи с предоставлением услуг сервисом, обязательна досудебная претензионная процедура. Официальным адресом электронной почты для таких претензий является p2p@GREEnavi.ru. Срок рассмотрения претензии составляет 30 календарных дней с даты получения претензии пользователя сервисом. В подтверждение своего утверждения пользователь должен предоставить все соответствующие доказательства.
              Если спор не урегулирован в рамках процедуры подачи жалобы, урегулирование спора передается в Международный арбитражный центр Дубая.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.</span> Арбитражная процедура
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.1.</span> Пользователь и сервис договорились о том, что любой спор, возникающий в связи с настоящим соглашением или другими услугами сервиса, будет урегулирован посредством обязательного арбитража на индивидуальной (ad hoc) основе в соответствии с правилами Дубайского международного арбитражного центра в отношении споров с участием потребителей. В соответствии с применимыми правовыми требованиями потребительский истец (лицо, осуществляющее операции для личных, семейных или деловых нужд) может подать свой иск в суд по мелким искам вместо арбитража, при условии, что иск рассматривается в суде по мелким искам на индивидуальной основе. Порядок (не на коллективной или представительской основе).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.2.</span> Расходы на арбитраж должны включать административные сборы Центра за рассмотрение иска и любого встречного иска, а также сборы и расходы Трибунала, установленные Центром в соответствии с Таблицей сборов и расходов, действующей на момент начала арбитража, и должны включать любые расходы, понесенные Трибунала, а также гонорары и расходы любых экспертов, назначенных Трибуналом.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.3.</span> Центр устанавливает аванс на покрытие расходов, соответствующих сумме спора, в размере, который, вероятно, покроет гонорары и расходы Трибунала и административные расходы Центра по претензиям и встречным искам в соответствии с Таблицей гонораров и расходов.
              Эта сумма может быть скорректирована в любое время в ходе арбитражного разбирательства.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.4.</span> Если спорные суммы не были указаны в претензии или встречном иске, Центр может зафиксировать аванс на покрытие расходов по своему усмотрению.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.5.</span> Аванс на покрытие расходов, установленный Центром, выплачивается Истцом и Ответчиком в равных долях. Если какая-либо из сторон не выплачивает свою долю, другая сторона может оплатить эту долю наличными или путем предоставления безусловной банковской гарантии на эту дополнительную сумму способом, приемлемым для Исполнительного комитета.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.6.</span> Если, помимо претензий, подаются встречные требования, Центр может установить отдельные авансы на покрытие расходов по претензиям и встречным искам.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.7.</span> Если Центр установил отдельные авансы на покрытие расходов, каждая из сторон выплачивает аванс на покрытие расходов, соответствующий ее претензии.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.8.</span> Материалы дела не передаются в Трибунал до тех пор, пока не будет выплачен авансовый платеж, установленный Центром.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.9.</span> Трибунал информирует Центр о любом увеличении суммы претензий или встречных исков.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.10.</span> Если просьба о выплате аванса на покрытие расходов не была удовлетворена, Администратор передает вопрос в Исполнительный комитет для принятия решения о том, следует ли приостановить работу Трибунала и установить срок, который не должен превышать 15 дней, по истечении которого соответствующие претензии (или встречные иски) считается отозванным. Если соответствующая сторона желает возразить против этой меры, она должна подать запрос в течение вышеупомянутого периода, чтобы Исполнительный комитет принял решение по этому вопросу. Такой стороне не должно быть запрещено на основании такого отзыва предъявлять те же требования или встречные иски позднее в рамках другого разбирательства.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.11.</span> Прежде чем может быть начата любая экспертиза, заказанная Трибуналом, стороны или одна из них должны выплатить установленный Трибуналом аванс на покрытие расходов, достаточный для покрытия ожидаемых гонораров и издержек экспертов, определенных Трибуналом. Если арбитражное разбирательство прекращается до вынесения окончательного решения, Исполнительный комитет определяет расходы на арбитраж по своему собственному усмотрению, принимая во внимание стадию, достигнутую арбитражем, и любые другие соответствующие обстоятельства.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.12.</span> Если арбитраж прекращается до вынесения окончательного решения, Исполнительный комитет устанавливает расходы на арбитраж по своему усмотрению, принимая во внимание стадию, достигнутую арбитражным разбирательством, и любые другие соответствующие обстоятельства.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.13.</span> Суммы, выплачиваемые Трибуналу, не включают какие-либо возможные налоги или сборы, применимые к гонорарам Трибунала.
              Стороны обязаны уплатить любые такие налоги или сборы; однако взыскание любых таких налогов или сборов является вопросом исключительно между арбитром и сторонами.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>9. Ограничение ответственности</div>
            <div className={styles.text}>
              <span className={styles.num}>9.1.</span> Сервис не несет ответственности перед пользователем за финансовые убытки, вызванные незаконными действиями третьих лиц.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.2.</span> Сервис не несет ответственности за любые отложенные или невыполненные объявления, вызванные ошибкой/опечаткой в банковских реквизитах, указанных пользователем в заполненном объявлении. Пользователь соглашается с тем, что в этом случае все претензии будут отправлены в соответствующую платежную систему и/или банк. Сервис, по запросу пользователя, помогает в предоставлении документов, подтверждающих характер транзакций, на случай, когда пользователь подает жалобу или запрос администрации соответствующей платежной системы или в банк.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.3.</span> Сервис не проверяет законность владения пользователем цифровым активом, криптовалютой, электронными деньгами или фиатной валютой, задействованными в конкретной операции. Сервис предполагает добросовестность и законность владения, использования и распоряжения пользователем банковскими картами (счетами) и Денежными средствами на них, указанными Пользователем при Оплате. Сервис не несет ответственности за владение, использование и распоряжение банковскими картами (счетами) и денежными средствами пользователя. Все риски и ответственность за владение, использование и распоряжение банковскими картами (счетами) и денежными средствами на них лежат на пользователе.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.4.</span> Если необходимо внести изменения в данные учетной записи пользователя, такой Пользователь должен обратиться в Службу поддержки Сервиса в соответствии с пунктом 3.2.Соглашения.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.5.</span> Пользователь должен немедленно уведомить администрацию сервиса о любом несанкционированном доступе к учетной записи пользователя или паролю, компрометации учетных данных, предполагаемом взломе Учетной записи или любом другом нарушении безопасности по электронной почте на p2p@GREEnavi.ru.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.6.</span> Пользователь не должен использовать какие-либо средства для сокрытия своего истинного местоположения.
              Пользователь должен сообщить администрации сервиса, по ее запросу, о своем точном и истинном местоположении.
              Если сервис определит, что активность пользователя подозрительна или связана с каким-либо видом незаконной деятельности, сервис может приостановить действие учетной записи, заблокировать невыполненные транзакции и/или отклонить последующие транзакции.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.7.</span> Пользователь несет ответственность за точность и полноту информации и данных, предоставленных при регистрации на сервисе. Если пользователь ввел неточные или некорректные персональные данные или предоставил неверные данные для оформления Заявки/Объявления, сервис не может нести ответственность за любые убытки такого пользователя.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.8.</span> Сервис не может быть привлечен к ответственности за любые убытки, возникшие в результате доступа пользователя к поддельному и/или фишинговому веб-сайту, или Telegram-боту, или приложениям сервиса, имитирующим реальный адрес веб-сайта Сервиса, реального бота и/или приложений, администрируемых Сервисом. Пожалуйста, проверьте, является ли веб-сайт, к которому вы пытаетесь получить доступ: <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.9.</span> Использование сервиса для любого вида незаконной деятельности запрещено.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.10.</span> Все услуги сервиса предоставляются без каких-либо явных или подразумеваемых гарантий, в частности, без подразумеваемых гарантий товарной пригодности и пригодности для определенной цели. Сервис будет стремиться предоставить пользователю свои услуги как можно скорее, но нет никакой гарантии, что доступ не будет прерван или что не будет задержек, сбоев, ошибок, пропусков или потери передаваемой информации, в том числе по вине третьих лиц.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.11.</span> Если настоящее соглашение принято, пользователь подтверждает, что:
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.11.1.</span> Они предоставили сервису полную и достоверную информацию о себе, а также подлинные идентификационные данные.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.11.2.</span> Они не участвуют ни в какой незаконной деятельности, включая отмывание денег, финансирование терроризма, торговлю запрещенными наркотиками и/или оружием и т.д., и они не включены ни в какие “черные списки”, “стоп-листы” или списки лиц, причастных к легализации (отмывание) доходов и финансирование терроризма на территории его страны проживания.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.11.3.</span> Они не признаны виновными в легализации (отмывании денег) и финансировании терроризма, а также в совершении мошенничества или любых других незаконных действий, связанных с кражей, тяжким преступлением, шантажом и/или грабежом.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.11.4.</span> Торговля, а также любые действия, связанные с оборотом цифровых активов, не являются незаконными в соответствии с законодательством государства проживания пользователя.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.12.</span> Пользователь обязуется не нарушать работу сервиса путем вмешательства в его программное или аппаратное обеспечение, а также путем искажения параметров (команд), передаваемых сервису. Пользователь осведомлен об ответственности за киберпреступления, регулируемой законодательством ОАЭ - Федеральным законом № 5 от 2012 года о борьбе с киберпреступлениями.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.13.</span> Если в результате действий пользователя, будь, то преднамеренных или непреднамеренных, сервису был нанесен ущерб его программному обеспечению, аппаратному обеспечению или любым убыткам, понесенным в результате действий пользователя, пользователь обязуется компенсировать такой ущерб или убытки в полном объеме.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.14.</span> Пользователь признает и соглашается с тем, что сервис не выступает в качестве финансового консультанта, не предоставляет услуги инвестиционного консалтинга, и любая информация, передаваемая сервисом пользователю, не должна рассматриваться как совет или руководство к действию.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.15.</span> Пользователь понимает и принимает на себя все риски, связанные с оборотом цифровых активов, включая риски, связанные с волатильностью таких активов. Сервис не гарантирует пользователю, что его цифровые активы будут проданы, равно как и Сервис не гарантирует цену, по которой такие активы могут быть проданы.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.16.</span> Любые споры, связанные с условиями настоящего соглашения, стороны оферты обязуются урегулировать путем переговоров. В случае неспособности достичь соглашения, все споры подлежат разрешению в соответствии с законами Свободной зоны Аджман Медиа Сити, как указано выше.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.17.</span> Информация об обменных операциях хранится в базе данных сервиса и является приоритетным источником, которым руководствуются стороны оферты, установленной настоящим соглашением, при разрешении споров.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.18.</span> Сервис и / или аффилированные с сервисом лица не несут и не будут нести ответственность за любую часть пользовательского контента, размещенного в сервисе, и не владеют никаким пользовательским контентом, а также какой-либо интеллектуальной собственностью третьих лиц, включая логотипы третьих лиц, иконки, любую графику и /или другие торговые марки. И/или иным образом защищенную информацию, ее распространение и использование третьими лицами. Однако, если будет установлено, что какой-либо пользовательский контент нарушает интеллектуальную собственность третьих лиц и/или другие права, сервис по запросу рассмотрит и заблокирует или удалит такой контент
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>10. Форс-мажорные обстоятельства</div>
            <div className={styles.text}>
              <span className={styles.num}>10.1.</span> Ни Пользователь, ни Сервис не несут ответственности друг перед другом за неисполнение обязательств, связанных с предоставлением услуг сервисом, вызванное обстоятельствами, которые возникли вопреки воле и пожеланиям сторон и/или которые нельзя было предвидеть или избежать, в то время как обе соответствующие стороны действовали с должной осмотрительностью и добросовестно, из-за непреодолимых обстоятельств и не может претендовать на какие-либо убытки или ущерб, возникшие в результате таких обстоятельств.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>10.2.</span> Сторона, которая не выполняет свое обязательство из-за форс-мажорных обстоятельств, должна незамедлительно уведомить другую сторону о форс-мажорных обстоятельствах и их влиянии на исполнение обязательств, но не позднее 3 (трех) календарных дней с даты указанных обстоятельств или начала таких обстоятельств.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>10.3.</span> Сторона, которая не уведомила другую сторону о невозможности выполнения своих обязательств по настоящему соглашению, утрачивает право ссылаться на такие причины.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>11. Прочие положения</div>
            <div className={styles.text}>
              <span className={styles.num}>11.1.</span> Информация о пользователе и о его операциях хранится на сервере веб-сайта сервиса. По желанию пользователя доступ к учетной записи может быть ограничен или удален.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>11.2.</span> Время ответа сервиса на запросы пользователя обычно составляет до пяти рабочих дней с момента получения такого запроса. Время отклика может варьироваться при определенных обстоятельствах (например, государственные праздники, технические неполадки и т.д.)
            </div>
            <div className={styles.text}>
              <span className={styles.num}>11.3.</span> Если какие-либо положения настоящего соглашения противоречат применимому законодательству, соглашение является законным и обязательным для исполнения во всех положениях, которые не противоречат применимому законодательству. Если какой-либо пункт(ы) соглашения будет признан судом недействительным, соглашение продолжает оставаться действительным, юридическим и обязательным соглашением между соответствующими сторонами во всех пунктах, которые не были признаны недействительными.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>11.4.</span> Информация, размещенная на веб-сайте Сервиса <span className={styles.num}><a href="/">https://greenavi.com</a></span> включая всю графику, текстовую информацию, программные коды и т.д., защищены национальными и международными законами об авторском праве и смежными законами. Несанкционированное копирование материалов не допускается и влечет за собой полное возмещение убытков, причиненных сервису.
              Все права защищены. Несанкционированное копирование запрещено.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>11.5.</span> Настоящее соглашение, наряду с политикой конфиденциальности и политикой AML/KYC, представляет собой юридически обязательное соглашение между сервисом и пользователем.
              Регистрируясь на сервисе, пользователь принимает условия настоящего соглашения в полном объеме и соглашается быть связанным им.
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Terms