import React from 'react';
import style from './WorksBlock.module.scss';
import { WorkOption } from "./WorkOption/WorkOption";
import kukoin from '../../../assets/icons/main/kukoin.svg';
import gemini from '../../../assets/icons/main/gemini.svg';
import binance from '../../../assets/icons/main/binance.svg';
import coinbase from '../../../assets/icons/main/coinbase.png';
import gate from '../../../assets/icons/main/gate.png';
import bitstamp from '../../../assets/icons/main/bitstamp.svg';
import bybit from '../../../assets/icons/main/bybit.svg';
import bitfinex from '../../../assets/icons/main/bitfinex.png';
import okx from '../../../assets/icons/main/okx.svg';


export const WorksBlock = () => {
    return (
        <div className={style.blockWrapper}>
            <div className={style.worksBlock}>
                <div className={style.title}>
                    <h3>Гринави принимает и отправляет переводы на все централизованные биржи</h3>
                    {/* <p>С крупными криптовалютными биржами</p> */}
                    <div className={style.worksBox}>
                        <img src={bitfinex} alt="" />
                        <img src={kukoin} alt="" />
                        <img src={coinbase} alt="" />
                        <img src={bitstamp} alt="" />
                        <img src={gemini} alt="" />
                        <img src={binance} alt="" />
                        <img src={gate} alt="" />
                        <img src={bybit} alt="" />
                        <img src={okx} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};