import React from 'react'
import styles from './Message.module.scss'
import classNames from 'classnames'
import read from '../../../../assets/icons/read.svg'
// import notRead from '../../../../assets/icons/not-read.svg'
import { IMessage } from '../../../../types/types'

interface MessageProps {
  message: IMessage
  userId: number
  // currentChannel: string
  // addMessageReaction: (messageTimetoken: number, message: any) => void
}

const Message = ({
  message, userId,
}: MessageProps
) => {

  const ADMIN_ID = 631;

  const isMyMessage = userId === +message.sender_user_id
  const isAdminMessage = ADMIN_ID === +message.sender_user_id
  // const isMessageRead = message?.actions?.reaction?.check_mark[0]

  // useEffect(() => {
  //   if (!isMyMessage && !isMessageRead) {
  //     addMessageReaction(message.timetoken, message)
  //   }
  // }, [addMessageReaction, isMessageRead, isMyMessage, message, message.timetoken])

  return (
    <div className={isMyMessage ? styles.myMessageContainer : isAdminMessage ? styles.adminMessageContainer : styles.messageContainer}>
      <div
        className={classNames(styles.messageBox, {
          [styles.myMessage]: isMyMessage,
          [styles.adminMessage]: isAdminMessage,
          [styles.message]: !isMyMessage && !isAdminMessage
        })}
      >
        <p className={styles.messageCreator}>{isAdminMessage ? 'Администратор' : ''}</p>
        {message.attachment ? (
          <div>
            <p className={styles.messageText}>
              {message.primary_message}
            </p>
            <img className={styles.messageImg} src={`/uploads/${message.attachment}`} alt='img' />
          </div>
        ) : (
          <div>
            <p className={styles.messageText}>{message.primary_message}</p>
          </div>
        )}
        {
          isMyMessage && (
            // isMessageRead ?
            <img className={styles.tick} src={read} alt="read" />
            // :
            // <img className={styles.tick} src={notRead} alt="notRead" />
          )
        }
      </div>
    </div>
    // <div>{message.primary_message}</div>
  )
}

export default Message