import React from 'react';
import style from './AskBlock.module.scss';
import { TradeButton } from "./buttons/PayButton/TradeButton";
import { TelegramButton } from "./buttons/TelegramButton/TelegramButton";

export const AskBlock = () => {
    return (
        <div className={style.askBlock}>
            <h3>КТО МЫ?</h3>
            <div className={style.askItems}>
                <p><span>GREEnavi B2B</span> - это возможность покупать криптовалюту Юридическим лицам, и совершать сделки между собой и отправлять денежные средства в любую точку мира.</p>
                <p><span>Llc greenavi</span> была зарегистрирована на карибском острове невис, там же была получена самая обновленная криптолицензия которая полностью соответствует законодательству по криптолегурированию 2022 г. </p>
                <p>Самые передовые способы защиты средств и пользовательских данных, над которыми работала опытная команда программистов</p>
            </div>
            <a className={style.askBtn} href="/p2p/buy">Начать</a>
            {/* <div className={style.buttonWrapper}>
                <TradeButton />
                <TelegramButton />
            </div> */}
        </div>
    );
};