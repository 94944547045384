import { ApiClient } from "./Client"

export const GetMessageList = async (chat_room_id: number) => {
  const token = localStorage.getItem("access_token") ?? '';
  return await ApiClient({
    url: '/chat/get-messages-by-room',
    method: 'POST',
    headers: {
      'Autorization': token
    },
    data: {
      chat_room_id: chat_room_id
    }
  })
}

export const GetMessageListB2b = async (chat_room_id: number) => {
  const token = localStorage.getItem("access_token") ?? '';
  return await ApiClient({
    url: '/chat/get-messages-by-room-b2b',
    method: 'POST',
    headers: {
      'Autorization': token
    },
    data: {
      chat_room_id: chat_room_id
    }
  })
}

export const NewMessage = async (formData: any) => {
  return await ApiClient({
    url: '/chat/new-message',
    method: "POST",
    headers: {
      "Content-type": "multipart/form-data"
    },
    data: formData
  })
}