import React, { useState } from 'react'
import styles from './OfferClosed.module.scss'
import { OfferData, UserProfile } from '../../../types/types';
import AuthorizationBlock from '../../AuthorizationComponents/AuthorizationBlock';
import a1 from '../../../assets/img/profile/avatar1.svg';
import a2 from '../../../assets/img/profile/avatar2.svg';
import a3 from '../../../assets/img/profile/avatar3.svg';
import a4 from '../../../assets/img/profile/avatar4.svg';
import a5 from '../../../assets/img/profile/avatar5.svg';
import a6 from '../../../assets/img/profile/avatar6.svg';

interface OfferClosedPropsType {
  offerData: OfferData;
  offerType: number;
  profile: UserProfile | null;
  setActiveOffer: (param: number) => void;
}

const OfferClosed = ({
  setActiveOffer,
  profile,
  offerData,
  offerType,
}: OfferClosedPropsType) => {
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(false);
  const token = localStorage.getItem("access_token") ?? '';

  const avatars = [a1, a2, a3, a4, a5, a6];

  return (
    <>
      <div className={styles.items}>
        <div className={styles.item1}>
          <img src={avatars[offerData.avatar - 1]} alt="avatar" className={styles.ava} />
          <div className={styles.infoContainer}>
            <p className={styles.username}>{offerData.user}</p>
            <p className={styles.userinfo}>{offerData.user_orders_count} исполнено / {offerData.user_orders_count_complete_percent}% выполнено</p>
            <p className={styles.userinfo}>{offerData.verify_status ? `Верифицирован` : `Не верифицирован`}</p>
          </div>
        </div>
        <p className={styles.priceName}>Цена</p>
        <div className={styles.item2}>
          <p className={styles.curPrice}>{(+offerData.course).toFixed(2)} <span>{offerData.currency}</span></p>
          <p className={styles.currency}></p>
        </div>
        <div className={styles.item3}>
          <div className={styles.limit}>
            <p className={styles.limitTitle}>Доступно</p>
            <p className={styles.limitTitle}>Лимиты</p>

          </div>
          <div className={styles.limit}>
            <p className={styles.limitText}>
              {`${+offerData.amount} ${offerData.chart}`}
            </p>
            <p className={styles.limitText}>{offerData.min_limit} - {offerData.max_limit} {offerData.currency}</p>
          </div>
        </div>
        <div className={styles.item4}>
          <div className={styles.banks}>
            {
              offerData.payments.map((el, index) => {
                return <p className={styles.bank} key={index}>{el.name}</p>
              })
            }
          </div>
        </div>
        <div className={styles.item5}>
          {
            profile && (offerData.user === profile.login) ?
              <div>Ваше объявление</div>
              :
              <div
                className={`${offerType === 2 ? styles.btnSell : styles.btnBuy}`}
                onClick={() => token ? setActiveOffer(offerData.order_id) : setOpenModal(true)}
              >
                <p>
                  {`${offerType === 1 ? `Купить` : `Продать`} `}
                  <i> {`${offerData.chart}`} </i>
                </p>
              </div>
          }
        </div>
        <div className={styles.item4Small}>
          <div className={styles.banks}>
            {
              offerData.payments.map((el, index) => {
                return <p key={index}>{el.name}</p>
              })
            }
          </div>
        </div>
      </div>
      <AuthorizationBlock
        type={'login'}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setStatus={setStatus}
      />
    </>
  )
}

export default OfferClosed