import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './B2bOffers.module.scss';
import { useAppSelector } from "../../utils/hooks/useAppSelector";
import { getB2bDiscount, getB2bFilters, getB2bLoading, getB2bOffers, getB2bSum } from "../../bll/selectors/b2b.selector";
import { B2BFilters } from "../../components/b2bOffersComponents/B2bFilters/B2bFilters";
import Container from "../../UI/container/Container";
import { Offers } from "../../components/b2bOffersComponents/Offers/Offers";
import { useAppDispatch } from "../../utils/hooks/useAppDispatch";
import { getB2bFullListThunk } from "../../bll/api/b2b.thunk";
import { getCompanyList } from "../../bll/selectors/company.selector";
import { getOkved } from '../../bll/selectors/app.selector';
import Paginator from '../../components/paginator/Paginator';
import { IB2bOffer } from '../../types/B2b.types';
import rafiki1 from '../../assets/img/b2b/rafiki1.svg'
import rafiki2 from '../../assets/img/b2b/rafiki2.svg'
import star1 from '../../assets/img/b2b/Star 1.svg'
import star2 from '../../assets/img/b2b/Star 2.svg'
import star3 from '../../assets/img/b2b/Star 3.svg'
import { WithPopUp } from '../../utils/hocs/WithPopUp/WithPopUp';
import { B2BCompanyCreator } from '../../components/b2bComponents/B2bCompanySelector/B2bCompanyCreator/B2bCompanyCreator';
import { ICompany } from '../../types/Company.type';
import { AddPaymentB2b, ProfileInfo } from '../../services/UserService';
import { GetCompanyByInfo } from '../../services/CompanyService';
import cross from '../../assets/icons/cross.svg';
import { UserProfile } from '../../types/types';

export const B2bOffers = () => {
    const isLoading = useAppSelector(getB2bLoading);
    const offers = useAppSelector(getB2bOffers);
    const filters = useAppSelector(getB2bFilters);
    const sum = useAppSelector(getB2bSum);
    const discount = useAppSelector(getB2bDiscount);
    const companyList = useAppSelector(getCompanyList);
    const okveds = useAppSelector(getOkved);
    const dispatch = useAppDispatch();
    const [offersToRender, setOffersToRender] = useState<IB2bOffer[]>([])
    const [profile, setProfile] = useState<UserProfile | null>(null)

    const getProfile = async (token: string) => {
        const { data } = await ProfileInfo(token);
        setProfile(data === 'Token не найден' ? null : data);
    }

    useEffect(() => {
        const token = localStorage.getItem("access_token") ?? '';
        getProfile(token);
    }, [])

    useEffect(() => {
        dispatch(getB2bFullListThunk(filters));
    }, [filters]);

    const selectedCompany = companyList[0];


    const [offersType, setOffersType] = useState(2);

    const [creatingCompany, setCreatingCompany] = useState<null | ICompany>(null);

    const createCompany = () => {
        setIsCreating(true);
    };

    useEffect(() => {
        creatingCompany ? createCompany() : <></>
    }, [creatingCompany])


    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [isCreating2, setIsCreating2] = useState<boolean>(false);

    const closeCreating = () => {
        setCreatingCompany(null)
        setIsCreating(false);
        setIsCreating2(false)
        const jivo = document.querySelectorAll("jdiv")[0];
        (jivo as HTMLDivElement).style.display = 'block';
    };

    const [isCreateOffer, setIsCreateOffer] = useState<boolean>(false);

    useEffect(() => {
        if (isCreateOffer || isCreating2) {
            jivoHide()
        } else {
            jivoShow()
        }
    }, [isCreateOffer, isCreating2])

    const jivoShow = () => {
        let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div')
            ; (jivo as HTMLDivElement).style.display = 'block'
        let body = document.getElementsByTagName('body')[0]
            ; (body as HTMLBodyElement).style.overflow = 'unset'
    }

    const jivoHide = () => {
        let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div')
            ; (jivo as HTMLDivElement).style.display = 'none'
        let body = document.getElementsByTagName('body')[0]
            ; (body as HTMLBodyElement).style.overflow = 'hidden'
    }

    const [errorMessage, setErrorMessage] = useState('')

    const error = <div className={styles.error}>
        {errorMessage}
    </div>

    const [okMessage, setOkMessage] = useState('')

    const ok = <div className={styles.ok}>{okMessage}</div>

    const [bik, setBik] = useState('')
    const [ks, setKs] = useState('')
    const [bankName, setBankName] = useState('')
    const [bankId, setBankId] = useState(0)

    const addPaymentB2b = async () => {
        if (rsOk && bankId && bankName && ks && bik) {
            const token = localStorage.getItem('access_token') ?? ''
            const data = await AddPaymentB2b(
                token,
                +bankId,
                rs,
                bankName,
                bik,
                ks
            )
            if (data.status === 200 && data.data.success) {
                setOkMessage(data.data.message)
                setTimeout(() => {
                    setOkMessage('')
                    setShowAddModal(false)
                    // getPaymentsB2B(token)
                    document.location.reload()
                }, 3000)
            } else {
                setErrorMessage(data.data)
                setTimeout(() => {
                    setErrorMessage('')
                }, 3000)
            }
        }
    }

    const [rs, setRs] = useState('')

    const onChangeNumberHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (!Number.isFinite(+e.target.value)) {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1)
            return
        };
        if (!e.target.value) {
            setRs('')
        }
        if (e.target.value.length > 28) {
            e.target.value = e.target.value.slice(0, 28)
        }
        setRs(e.target.value)
    };

    const onChangeNumberHandlerKs = (e: ChangeEvent<HTMLInputElement>) => {
        if (!Number.isFinite(+e.target.value)) {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1)
            return
        };
        if (!e.target.value) {
            setKs('')
        }
        if (e.target.value.length > 28) {
            e.target.value = e.target.value.slice(0, 28)
        }
        setKs(e.target.value)
    };

    const [rsOk, setRsOk] = useState(false);

    useEffect(() => {
        if (rs && (String(rs).length === 20 || String(rs).length === 28)) {
            setRsOk(true)
        } else {
            setRsOk(false)
        }
    }, [rs])

    const onChangeBankHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setBankName(e.target.value)
    }

    const [disabled, setDisabled] = useState(false)

    const onChangeBikHandler = async (e: ChangeEvent<HTMLInputElement>) => {
        const inpBank = (document.getElementById(`pr-inp-bank`) as HTMLInputElement) ?? document.createElement("input")
        const inpKs = (document.getElementById(`pr-inp-ks`) as HTMLInputElement) ?? document.createElement("input")
        if (e.target.value.length === 9) {
            const data = await GetCompanyByInfo(e.target.value.slice(1));
            if (data.data.length === 1) {
                setDisabled(true)
                setBik(e.target.value)
                setKs(data.data[0].ks)
                setBankName(data.data[0].title)
                setBankId(+data.data[0].id)
                inpBank.value = data.data[0].title
                inpKs.value = data.data[0].ks
            }
        }
    }

    const [showAddModal, setShowAddModal] = useState(false)

    const bg = <div className={styles.twoFaMain} onClick={() => setShowAddModal(false)}></div>
    const addModalB2b = (
        <div className={styles.passRoot}>
            <div className={styles.topBlock}>
                <p className={styles.topBlocktitle}>Добавить расчётный счёт</p>
                <img src={cross} alt='' className={styles.cross} onClick={() => setShowAddModal(false)} />
            </div>
            <div>
                <p className={styles.subtitle}>Бик</p>
                <input
                    type='text'
                    className={styles.number}
                    placeholder='бик'
                    disabled={disabled}
                    onChange={e => onChangeBikHandler(e)}
                />
            </div>
            <div className={styles.paymentSelect}>
                <p className={styles.subtitle}>Банк</p>
                <input
                    id='pr-inp-bank'
                    type='text'
                    className={styles.number}
                    placeholder='банк'
                    onChange={e => onChangeBankHandler(e)}
                />
            </div>
            <div>
                <p className={styles.subtitle}>Номер расчётного счёта</p>
                <input
                    type='text'
                    className={styles.number}
                    placeholder='р/с (20/28)'
                    onChange={e => onChangeNumberHandler(e)}
                />
            </div>
            <div>
                <p className={styles.subtitle}>Номер корреспондентского счёта</p>
                <input
                    id='pr-inp-ks'
                    type='text'
                    className={styles.number}
                    placeholder='к/с (20/28)'
                    onChange={e => onChangeNumberHandlerKs(e)}
                    disabled={disabled}
                />
            </div>
            {errorMessage ? error : <></>}
            {okMessage ? ok : <></>}
            <button className={rsOk && bankId && bankName && ks && bik ? styles.btnOk : styles.btnDis} onClick={addPaymentB2b}>
                Добавить
            </button>
        </div>
    )

    useEffect(() => {
        if (showAddModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showAddModal])

    return (
        <Container>
            <div className={styles.b2bOffers}>
                <div className={styles.banner}>
                    <div className={styles.bannerTop}>В2В - платформа для вашего бизнеса</div>
                    <div className={styles.bannerBottom}>Мы привносим реальные решения в мир платежей, используя общедоступные блокчейны</div>
                </div>
                <div className={styles.headWrapper} style={(isCreateOffer || isCreating2) ? { zIndex: 600 } : { zIndex: 500 }}>
                    <B2BFilters
                        profile={profile}
                        isCreateOffer={isCreateOffer}
                        setIsCreateOffer={setIsCreateOffer}
                        filters={filters}
                        sum={sum}
                        discountValue={discount}
                        disabled={isLoading}
                        setOffType={setOffersType}
                        selectedCompany={selectedCompany}
                        isCreating={isCreating2}
                        setIsCreating={setIsCreating2}
                    />

                </div>
                <Offers
                    offersType={offersType}
                    offers={offersToRender}
                    filterSum={sum || 0}
                    isLoading={isLoading}
                    okveds={okveds}
                    selectedCompany={selectedCompany}
                    setCreatingCompany={setCreatingCompany}
                    setShowAddModal={setShowAddModal}
                />
                <Paginator offers={offers} setOffersToRender={setOffersToRender} />
            </div>
            <div className={styles.b2bInfo}>
                <div className={styles.textBlock}>
                    <div className={styles.textItem}>
                        <p><span>GREEnavi B2B</span> - это новое предприятие, созданное при инвестициях и сотрудничестве с <span>CUCOIN, GEMINI, BINANCE</span> и тд.</p>
                    </div>
                    <div className={styles.textItem}>
                        <p>Мы намереваемся <span>запустить</span> первую в своем роде <span>расчетную палату</span> для бизнеса, которая стремится способствовать следующему этапу <span>развития рынка</span>.</p>
                    </div>
                    <div className={styles.textItem}>
                        <p>Централизованная <span>платформа с поддержкой API</span> решает ключевые проблемы оптимизацией бизнес процессов и поиском подходящих и проверенных контрагентов.</p>
                    </div>
                    <button className={styles.textBtn}>Начать</button>
                </div>


                <div className={styles.B2BBlock}>
                    <h2><span>В2В</span> сервис</h2>
                    <ul className={styles.B2BBox}>
                        <li className={styles.B2BItem}>
                            Помогает осуществить взаимодействие между юридическими лицами по <span>всему миру</span>
                        </li>
                        <li className={styles.B2BItem}>
                            Алгоритмы анализа <span>благонадежности</span> контрагентов
                        </li>
                        <li className={styles.B2BItem}>
                            <span>Оптимизация</span> бизнес процессов
                        </li>
                        <li className={styles.B2BItem}>
                            <span>Поиск</span> подходящих и проверенных <span>контрагентов</span>
                        </li>
                        <li className={styles.B2BItem}>
                            Возможность приобретения и продажи <span>ТМЦ и услуг</span>
                        </li>
                        <li className={styles.B2BItem}>
                            <span>Безопасное</span> заключение международных сделок
                        </li>
                        <li className={styles.B2BItem}>
                            <span>Криптовалютные</span> переводы
                        </li>
                    </ul>

                    <img src={rafiki2} alt="" />
                </div>



                <div className={styles.cardBlock}>
                    <div className={styles.cardBox}>

                        <div className={styles.cartItem}>
                            <div className={styles.cartNumber}>1</div>
                            <div className={styles.cartText}>
                                <strong>Безопасное заключение международных сделок</strong>
                                <p>Получили после проверки рейтинг от службы безопасности платформы о благонадежности контрагента</p>
                            </div>
                        </div>
                        <div className={styles.cartItem}>
                            <div className={styles.cartNumber}>2</div>
                            <div className={styles.cartText}>
                                <strong>Обмены по цепочке</strong>
                                <p>Прямые переводы контрагенту</p>
                            </div>
                        </div>
                        <div className={styles.cartItem}>
                            <div className={styles.cartNumber}>3</div>
                            <div className={styles.cartText}>
                                <strong>Взаимодействие между юридическими лицами по всему миру</strong>
                                <p>Возможность приобретения и продажи ТМЦ и услуг</p>
                            </div>
                        </div>

                    </div>
                </div>



                <div className={styles.stepsBlock}>
                    <h2>Как это происходит</h2>
                    <p><span>Безопасное</span> заключение сделки между юридическими лицами на платформе <span>GREEnavi</span></p>
                    <div className={styles.stepsBox}>

                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>1</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Продавец/покупатель выкладывает ордер, в котором содержится коммерческое предложение</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>2</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Платформа предоставляет информацию о благонадежности сторон (галочка благонадежности)</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>3</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Покупатель и продавец договариваются об условиях сделки в конфиденциальном чате и подписывают договор (им видны только данные о ЮЛ, сама коммуникация конфиденциальна</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>4</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Отправляют подписанный скан договора с двух сторон на платформу</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>5</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Проводится проверка администратором платформы</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>6</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Продавец перечисляет средства на счет платформы</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>7</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Продавец выполняет обязательства по договору</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>8</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Контрагенты обмениваются первичной финансово-хозяйственной документацией (договорами, счетами-фактурами, актами и т.д.)</p>
                            </div>
                        </div>
                        <div className={styles.stepsItem}>
                            <div className={styles.stepsNum}>
                                <span>9</span>
                                <div className={styles.stepsLine}></div>
                            </div>
                            <div className={styles.stespsText}>
                                <p>Продавец получает денежные средства на расчетный счет</p>
                            </div>
                        </div>

                    </div>
                </div>


                <div className={styles.searchBlock}>
                    <h2>Поиск</h2>
                    <p><span>проверенных</span> контрагентов на платформе</p>
                    <div className={styles.searchBox}>

                        <div className={styles.searchItem}>
                            <div className={styles.searchNum}>
                                <span>01</span>
                            </div>
                            <div className={styles.searchText}>
                                <p>Компания и ее ген. директор прошли верификацию</p>
                            </div>
                        </div>
                        <div className={styles.searchItem}>
                            <div className={styles.searchNum}>
                                <span>02</span>
                            </div>
                            <div className={styles.searchText}>
                                <p>Указали корректные данные (инн и оквэд)</p>
                            </div>
                        </div>
                        <div className={styles.searchItem}>
                            <div className={styles.searchNum}>
                                <span>03</span>
                            </div>
                            <div className={styles.searchText}>
                                <p>Получили после проверки рейтинг от службы безопасности платформы о благонадежности контрагента</p>
                            </div>
                        </div>
                        <div className={styles.searchItem}>
                            <div className={styles.searchNum}>
                                <span>04</span>
                            </div>
                            <div className={styles.searchText}>
                                <p>Получили возможность размещать свои коммерческие предложения в виде ордеров и находить для себя проверенных и подходящих подрядчиков</p>
                            </div>
                        </div>

                    </div>
                    <img src={rafiki1} alt="" />
                </div>


                <div className={styles.workBlock}>
                    <h2>Как это работает</h2>
                    <ul className={styles.workBox}>
                        <li className={styles.workItem}>
                            <div className={styles.workimg}>
                                <img src={star1} alt="" />
                            </div>
                            <div className={styles.workText}>
                                <p> <span>Создайте</span> учетную запись</p>
                                <p><span>Скачайте</span> бесплатное приложение и подпишитесь.
                                    <span>Зарегистрируйте</span> и <span>подтвердите</span> свою учетную запись.</p>
                            </div>
                        </li>
                        <li className={styles.workItem}>
                            <div className={styles.workimg}>
                                <img src={star2} alt="" />
                            </div>
                            <div className={styles.workText}>
                                <p><span>Найдите</span> интересующего вас контрагента. </p>
                                <p>На нашей платформе все компании проходят <span>полную верификацию</span>, вплоть до ген. директоров.</p>
                            </div>
                        </li>
                        <li className={styles.workItem}>
                            <div className={styles.workimg}>
                                <img src={star3} alt="" />
                            </div>
                            <div className={styles.workText}>
                                <p><span>Получите</span> возможность <span>создавать</span> ордера. </p>
                                <p>После полной проверки у вас появится возможность <span>создавать коммерческие предложения</span> на основе ордеров, а также вы сможете с легкостью <span>найти</span> себе подходящих <span>подрядчиков</span>.</p>
                            </div>
                        </li>

                    </ul>

                </div>
                {isCreating && creatingCompany && (
                    <WithPopUp close={closeCreating}>
                        <B2BCompanyCreator close={closeCreating} creatingCompany={creatingCompany} setCreatingCompany={setCreatingCompany} />
                    </WithPopUp>
                )}
                {showAddModal ? bg : <></>}
                {showAddModal ? addModalB2b : <></>}
            </div>
        </Container>
    );
};