import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import styles from './ProfileInfo.module.scss'
import BalanceBlock from '../balanceBlock/BalanceBlock'
import Statistics from '../statistics/Statistics'
// import Lastoperation from '../lastoperation/Lastoperation'
import { AddPayment, AddPaymentB2b, DelPayment, DelPaymentB2b, GetPayments, GetPaymentsB2B } from '../../../services/UserService'
import cross from '../../../assets/icons/cross.svg'
import Select from 'react-select'
import { BankProps, TokenProps } from '../../filters/Filters'
import { GetB2bCharts, GetCharts, GetPayment } from '../../../services/P2p'
import { Payments, UserProfile } from '../../../types/types'
// import { ReactComponent as Cross } from "../../../assets/icons/cross.svg";
import { Link } from 'react-router-dom'
import Success from '../../../UI/Success/Success'
import Error from '../../../UI/Error/Error'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import Transfer from '../modals/transfer/Transfer'
import { useAppSelector } from '../../../utils/hooks/useAppSelector'
import { getAllWallets, getB2bWallets } from '../../../bll/selectors/app.selector'
import MoveIn from '../modals/MoveIn/MoveIn'
import MoveOut from '../modals/MoveOut/MoveOut'
import PayIn from '../modals/payIn/PayIn'
import PayOut from '../modals/payOut/PayOut'
import B2bPayOut from '../modals/b2bPayOut/B2bPayOut'
import a1 from '../../../assets/img/profile/avatar1.svg'
import a2 from '../../../assets/img/profile/avatar2.svg'
import a3 from '../../../assets/img/profile/avatar3.svg'
import a4 from '../../../assets/img/profile/avatar4.svg'
import a5 from '../../../assets/img/profile/avatar5.svg'
import a6 from '../../../assets/img/profile/avatar6.svg'
import { useAccount } from 'wagmi'
import { Chart } from '../wallet/Wallet'
import { GetCompanyByInfo } from '../../../services/CompanyService'
import arr from '../../../assets/icons/arrow-right.svg'

const ProfileInfo = (props: any) => {
	const [payments, setPayments] = useState<Payments[]>([])
	const [paymentsB2B, setPaymentsB2B] = useState<Payments[]>([])
	const [showAddModal, setShowAddModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [okMessage, setOkMessage] = useState('')
	const [selectedBankOption, setSelectedBankOption] = useState<any>({ label: 'SberBank', value: 32 })
	// const [selectedBankB2bOption, setSelectedBankB2bOption] = useState<any>({ label: 'СИБИРСКОЕ ГУ БАНКА РОССИИ', value: 90 })
	const [bankList, setBankList] = useState<BankProps[]>([{ label: 'SberBank', value: 32 }])
	// const [bankListB2b, setBankListB2b] = useState<BankProps[]>([{ label: 'SberBank', value: 32 }])
	const [cardNumber, setCardNumber] = useState('')
	const [telNumber, setTelNumber] = useState('')
	const [fio, setFio] = useState('')
	const [checked, setChecked] = useState(false)
	const [totalBalance, setTotalBalance] = useState<number>(0)
	const [totalBlocked, setTotalBlocked] = useState<number>(0)
	const [b2bBalance, setB2bBalance] = useState<number>(0)
	const [b2bBlocked, setB2bBlocked] = useState<number>(0)
	const [p2pChartList, setP2pChartList] = useState<Chart[]>([])

	const [profile, setProfile] = useState<UserProfile>(props)

	useEffect(() => {
		setProfile(props)
	}, [props])

	const allWallets = useAppSelector(getAllWallets)

	const getBalance = useCallback(async () => {
		//@ts-ignore
		if (allWallets[0] === 'нет данных') {
			setTotalBalance(0)
			setTotalBlocked(0)
			setP2pChartList([])
		} else {
			let row = allWallets
			let tempTotal = row.map(el => +el.balance)
			let total = tempTotal.reduce((prev, cur) => +prev + +cur, 0)
			setTotalBalance(total)
			let tempBlocked = row.map(el => (el?.blocked ? +el.blocked : 0))
			let totalBlocked = tempBlocked.reduce((prev, cur) => +prev + +cur, 0)
			setTotalBlocked(totalBlocked)
			let tempP2pChartList: Chart[] = row.map(el => {
				return { symbol: el.symbol, icon: el.icon, balance: el.balance, blocked: el.blocked }
			})
			setP2pChartList(tempP2pChartList)
		}
	}, [allWallets])

	const b2bWallets = useAppSelector(getB2bWallets)

	const getB2bBalance = useCallback(async () => {
		//@ts-ignore
		if (b2bWallets[0] === 'нет данных') {
			setB2bBalance(0)
			setB2bBlocked(0)
		} else {
			let row = b2bWallets
			let tempTotal = row.map(el => +el.balance)
			let total = tempTotal.reduce((prev, cur) => +prev + +cur, 0)
			setB2bBalance(total)
			let tempBlocked = row.map(el => (el?.blocked ? +el.blocked : 0))
			let totalBlocked = tempBlocked.reduce((prev, cur) => +prev + +cur, 0)
			setB2bBlocked(totalBlocked)
		}
	}, [b2bWallets])

	const getPayments = async (token: string) => {
		const data = await GetPayments(token)
		if (data.status === 200) {
			setPayments(data.data)
		}
	}
	const getPaymentsB2B = async (token: string) => {
		const data = await GetPaymentsB2B(token)
		if (data.status === 200) {
			setPaymentsB2B(data.data)
		}
	}
	const getBanks = async (token: string) => {
		const data = await GetPayment(token, 1)
		if (data.status === 200 && data.data.length) {
			const temp: BankProps[] = []
			data.data.forEach((el: any) => {
				temp.push({ label: el.name, value: el.id.toString() })
			})
			setBankList(temp)
		}
	}

	const [currentB2bListBanks, setCurrentB2bListBanks] = useState<BankProps[]>([])

	const getBanksB2b = async () => {
		const data = await GetCompanyByInfo()
		if (data.status === 200) {
			const temp: BankProps[] = []
			data.data.forEach((el: any) => {
				temp.push({ label: el.title, value: el.id.toString() })
			})
			setCurrentB2bListBanks(temp)
		}
	}

	const addPayment = async () => {
		if (checked) {
			const token = localStorage.getItem('access_token') ?? ''
			const data = await AddPayment(
				token,
				+selectedBankOption.value,
				selectedBankOption.label === 'QIWI' ? telNumber : cardNumber,
				fio
			)
			if (data.status === 200 && data.data.success) {
				setOkMessage(data.data.message)
				setTimeout(() => {
					setOkMessage('')
					setShowAddModal(false)
					getPayments(token)
				}, 3000)
			} else {
				setErrorMessage(data.data)
				setTimeout(() => {
					setErrorMessage('')
				}, 3000)
			}
		}
	}

	const [bik, setBik] = useState('')
	const [ks, setKs] = useState('')
	const [bankName, setBankName] = useState('')
	const [bankId, setBankId] = useState(0)

	const addPaymentB2b = async () => {
		if (rsOk && bankId && bankName && ks && bik) {
			const token = localStorage.getItem('access_token') ?? ''
			const data = await AddPaymentB2b(
				token,
				+bankId,
				rs,
				bankName,
				bik,
				ks
			)
			if (data.status === 200 && data.data.success) {
				setOkMessage(data.data.message)
				setTimeout(() => {
					setOkMessage('')
					setShowAddModal(false)
					getPaymentsB2B(token)
				}, 3000)
			} else {
				setErrorMessage(data.data)
				setTimeout(() => {
					setErrorMessage('')
				}, 3000)
			}
		}
	}

	const delPayment = async (payment: Payments) => {
		const token = localStorage.getItem('access_token') ?? ''
		const data = await DelPayment(token, payment.id)
		if (data.data.success && data.status === 200) {
			getPayments(token)
		} else {
			setShowErr(data.data)
			setTimeout(() => {
				setShowErr('')
			}, 5000)
		}
	}

	const delPaymentB2b = async (payment: Payments) => {
		const token = localStorage.getItem('access_token') ?? ''
		const data = await DelPaymentB2b(token, payment.id)
		if (data.status === 200) {
			getPaymentsB2B(token)
		} else {
			setShowErr(data.data)
			setTimeout(() => {
				setShowErr('')
			}, 5000)
		}
	}

	const checkTelValue = (e: ChangeEvent<HTMLInputElement>) => {
		const numbers = /^[0-9 ]*$/
		// const reg = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
		//@ts-ignore
		if ((e.nativeEvent.inputType === 'insertText' && !numbers.test(e.target.value)) || e.target.value.length > 11) {
			e.target.value = e.target.value.slice(0, e.target.value.length - 1)
			return
		}
		if (e.target.value.length === 11) {
			setTelNumber(e.target.value)
		} else {
			setTelNumber('')
		}
	}

	const checkValue = (e: ChangeEvent<HTMLInputElement>) => {
		const numbers = /^[0-9 ]*$/
		const regExp = /[0-9]{4}/

		// не позволяем ввести ничего, кроме цифр 0-9, ограничиваем размер поля 19-ю символами
		//@ts-ignore
		if ((e.nativeEvent.inputType === 'insertText' && !numbers.test(e.target.value)) || e.target.value.length > 19) {
			e.target.value = e.target.value.slice(0, e.target.value.length - 1)
			return
		}
		// обеспечиваем работу клавиш "backspace","delete"
		let value = e.target.value
		//@ts-ignore
		if (e.nativeEvent.inputType === 'deleteContentBackward' && regExp.test(value.slice(-4))) {
			e.target.value = e.target.value.slice(0, e.target.value.length - 1)
			return
		}
		// добавяем пробел после 4 цифр подряд
		if (regExp.test(value.slice(-4)) && value.length < 19) {
			e.target.value += ' '
		}
		if (e.target.value.length === 19) {
			setCardNumber(e.target.value)
		} else {
			setCardNumber('')
		}
	}


	const onChangeBankHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setBankName(e.target.value)
	}

	const [disabled, setDisabled] = useState(false)

	const onChangeBikHandler = async (e: ChangeEvent<HTMLInputElement>) => {
		const inpBank = (document.getElementById(`pr-inp-bank`) as HTMLInputElement) ?? document.createElement("input")
		const inpKs = (document.getElementById(`pr-inp-ks`) as HTMLInputElement) ?? document.createElement("input")
		if (e.target.value.length === 9) {
			const data = await GetCompanyByInfo(e.target.value.slice(1));
			if (data.data.length === 1) {
				setDisabled(true)
				setBik(e.target.value)
				setKs(data.data[0].ks)
				setBankName(data.data[0].title)
				setBankId(+data.data[0].id)
				inpBank.value = data.data[0].title
				inpKs.value = data.data[0].ks
			}
		}
	}

	const [rs, setRs] = useState('')

	const onChangeNumberHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (!Number.isFinite(+e.target.value)) {
			e.target.value = e.target.value.slice(0, e.target.value.length - 1)
			return
		};
		if (!e.target.value) {
			setRs('')
		}
		if (e.target.value.length > 28) {
			e.target.value = e.target.value.slice(0, 28)
		}
		setRs(e.target.value)
	};

	const onChangeNumberHandlerKs = (e: ChangeEvent<HTMLInputElement>) => {
		if (!Number.isFinite(+e.target.value)) {
			e.target.value = e.target.value.slice(0, e.target.value.length - 1)
			return
		};
		if (!e.target.value) {
			setKs('')
		}
		if (e.target.value.length > 28) {
			e.target.value = e.target.value.slice(0, 28)
		}
		setKs(e.target.value)
	};

	const [rsOk, setRsOk] = useState(false);

	useEffect(() => {
		if (rs && (String(rs).length === 20 || String(rs).length === 28)) {
			setRsOk(true)
		} else {
			setRsOk(false)
		}
	}, [rs])

	useEffect(() => {
		if (selectedBankOption.label === 'QIWI') {
			if (telNumber && selectedBankOption.value && fio) {
				setChecked(true)
			} else {
				setChecked(false)
			}
		} else {
			if (cardNumber && selectedBankOption.value && fio) {
				setChecked(true)
			} else {
				setChecked(false)
			}
		}
	}, [cardNumber, selectedBankOption.value, fio, selectedBankOption.label, telNumber])

	useEffect(() => {
		const token = localStorage.getItem('access_token') ?? ''
		getBalance()
		getB2bBalance()
		getPayments(token)
		getPaymentsB2B(token)
		getBanks(token)
		getBanksB2b()
	}, [getB2bBalance, getBalance])

	const [bankListB2b, setBankListB2b] = useState<BankProps[]>([])

	const error = <div className={styles.error}>{errorMessage}</div>

	const ok = <div className={styles.ok}>{okMessage}</div>

	const bg = <div className={styles.twoFaMain} onClick={() => setShowAddModal(false)}></div>
	const addModal = (
		<div className={styles.passRoot}>
			<div className={styles.topBlock}>
				<p className={styles.topBlocktitle}>Добавить способ оплаты</p>
				<img src={cross} alt='' className={styles.cross} onClick={() => setShowAddModal(false)} />
			</div>
			<div className={styles.paymentSelect}>
				<p className={styles.subtitle}>Банк</p>
				<Select
					options={bankList}
					classNames={{
						control: state => styles.select2,
						singleValue: state => styles.select4,
						menuList: state => styles.select6,
						menu: state => styles.select61,
						option: state => styles.select7
					}}
					classNamePrefix='react-select'
					placeholder={`Способы оплаты`}
					defaultValue={selectedBankOption}
					value={selectedBankOption}
					onChange={setSelectedBankOption}
				/>
			</div>
			{selectedBankOption.label === 'QIWI' ? (
				<div>
					<p className={styles.subtitle}>Номер телефона</p>
					<input
						type='text'
						className={styles.number}
						placeholder='8 (000) 000 00 00'
						onChange={e => checkTelValue(e)}
					/>
				</div>
			) : (
				<div>
					<p className={styles.subtitle}>Номер карты</p>
					<input
						type='text'
						className={styles.number}
						placeholder='____ ____ ____ ____'
						onChange={e => checkValue(e)}
					/>
				</div>
			)}
			<div>
				<p className={styles.subtitle}>ФИО получателя</p>
				<input
					type='text'
					className={styles.number}
					placeholder='Иванов Иван Иванович'
					onChange={e => setFio(e.target.value)}
				/>
			</div>
			{errorMessage ? error : <></>}
			{okMessage ? ok : <></>}
			<button className={checked ? styles.btnOk : styles.btnDis} onClick={addPayment}>
				Добавить
			</button>
		</div>
	)
	const addModalB2b = (
		<div className={styles.passRoot}>
			<div className={styles.topBlock}>
				<p className={styles.topBlocktitle}>Добавить расчётный счёт</p>
				<img src={cross} alt='' className={styles.cross} onClick={() => setShowAddModal(false)} />
			</div>
			<div>
				<p className={styles.subtitle}>Бик</p>
				<input
					type='text'
					className={styles.number}
					placeholder='бик'
					disabled={disabled}
					onChange={e => onChangeBikHandler(e)}
				/>
			</div>
			<div className={styles.paymentSelect}>
				<p className={styles.subtitle}>Банк</p>
				<input
					id='pr-inp-bank'
					type='text'
					className={styles.number}
					placeholder='банк'
					onChange={e => onChangeBankHandler(e)}
				/>
			</div>
			<div>
				<p className={styles.subtitle}>Номер расчётного счёта</p>
				<input
					type='text'
					className={styles.number}
					placeholder='р/с (20/28)'
					onChange={e => onChangeNumberHandler(e)}
				/>
			</div>
			<div>
				<p className={styles.subtitle}>Номер корреспондентского счёта</p>
				<input
					id='pr-inp-ks'
					type='text'
					className={styles.number}
					placeholder='к/с (20/28)'
					onChange={e => onChangeNumberHandlerKs(e)}
					disabled={disabled}
				/>
			</div>
			{errorMessage ? error : <></>}
			{okMessage ? ok : <></>}
			<button className={rsOk && bankId && bankName && ks && bik ? styles.btnOk : styles.btnDis} onClick={addPaymentB2b}>
				Добавить
			</button>
		</div>
	)

	const [isOpenTransferModal, setIsOpenTransferModal] = useState(false)
	const [tokenList, setTokenList] = useState<TokenProps[]>([])
	const [tokenB2bList, setTokenB2bList] = useState<TokenProps[]>([])

	const getKrypto = async (token: string) => {
		const data = await GetCharts(token)
		if (data.status === 200) {
			const temp: TokenProps[] = []
			data.data.forEach((el: any) => {
				temp.push({
					label: (
						<div className={styles.fiatBox}>
							<img src={el.icon} alt={el.name} className={styles.fiat} />
							{el.symbol}
						</div>
					),
					value: el.id
				})
			})
			setTokenList(temp)
		}
	}
	const getB2bKrypto = async (token: string) => {
		const data = await GetB2bCharts(token)
		if (data.status === 200) {
			const temp: TokenProps[] = []
			data.data.forEach((el: any) => {
				temp.push({
					label: (
						<div className={styles.fiatBox}>
							<img src={el.icon} alt={el.name} className={styles.fiat} />
							{el.symbol}
						</div>
					),
					value: el.id
				})
			})
			setTokenB2bList(temp)
		}
	}

	useEffect(() => {
		const token = localStorage.getItem('access_token') ?? ''
		getKrypto(token)
		getB2bKrypto(token)
	}, [])

	const getBank = async (token: string, fiatID: number) => {
		const data = await GetPayments(token, fiatID)
		if (data.status === 200) {
			const temp: BankProps[] = []
			data.data.forEach((el: any) => {
				temp.push({ label: el.name, value: el.payment_id.toString() })
			})
			setBankListB2b(temp)
		}
	}

	useEffect(() => {
		const token = localStorage.getItem('access_token') ?? ''
		getBank(token, 1)
		// getKrypto(token)
		// getB2bKrypto(token)
	}, [totalBalance])

	const [isOpenMoveInModal, setIsOpenMoveInModal] = useState(false)
	const [isOpenMoveOutModal, setIsOpenMoveOutModal] = useState(false)
	const [isMoveOutModal, setIsMoveOutModal] = useState(false)
	const [isOpenPayInModal, setIsOpenPayInModal] = useState(false)
	const [isOpenPayOutModal, setIsOpenPayOutModal] = useState(false)
	const [isOpenB2bPayOutModal, setIsOpenB2bPayOutModal] = useState(false)

	const [selectedTokenOption, setSelectedTokenOption] = useState<any>({ label: '', value: 0 })
	// const [selectedTokenOption, setSelectedTokenOption] = useState<any>({ label: <div className={styles.fiatBox}>USDT</div>, value: 259 });
	const [selectedTokenB2bOption, setSelectedTokenB2bOption] = useState<any>({ label: '', value: 0 })
	// const [selectedTokenB2bOption, setSelectedTokenB2bOption] = useState<any>({ label: <div className={styles.fiatBox}>USDT</div>, value: 259 });

	const [action, setAction] = useState<'total' | 'b2b'>('total')

	const [showErr, setShowErr] = useState('')

	const { isConnected } = useAccount()

	const errModal = (
		<div className={styles.errModalContainer}>
			{showErr}
			<div className={styles.marq}></div>
		</div>
	)

	const avatars = [a1, a2, a3, a4, a5, a6]

	useEffect(() => {
		if (showAddModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [showAddModal])

	const [modal, setModal] = useState<'P2P' | 'B2B'>('P2P')
	const [activePayments, setActivePayments] = useState(0)

	const checkSlider = (e: any) => {
		setActivePayments(e.activeIndex ?? 0)
	}

	const [swiperInstance, setSwiperInstance] = useState<any>()

	const changeSwipe = (nav: 'prev' | 'next') => {
		if (nav === 'next' && swiperInstance) {
			swiperInstance.slideNext()
		} else if (nav === 'prev' && swiperInstance) {
			swiperInstance.slidePrev()
		}
	}

	return (
		<div className={styles.main}>
			{showAddModal ? bg : <></>}
			{showAddModal ? modal === 'P2P' ? addModal : addModalB2b : <></>}
			{showErr ? errModal : <></>}
			<div className={styles.mobileInfo}>
				<h2 className={styles.title}>Профиль</h2>
				<div className={styles.userInfo}>
					<div className={styles.iconWrap}>
						<img className={styles.div} src={avatars[profile.avatar - 1]} alt='avatar' />
					</div>
					<div className={styles.info}>
						<h2 className={styles.titleInfo}>{profile.login}</h2>
						<h2 className={styles.titleInfo2}>{profile.email}</h2>
						<div className={styles.errorWrap}>
							<Link to={'/profile/verify'}>
								{!profile.verify_status ? (
									<Error title={`Профиль не подтвержден`} />
								) : (
									<Success title={`Профиль подтвержден`} />
								)}
							</Link>
							<Link to={'/profile/safety'}>
								{!profile.email ? <Error title='Email' /> : <Success title='Email' />}
							</Link>
							<Link to={'/profile/safety'}>
								{!profile.two_factor ? (
									<Error title={`Двухфакторная аутентификация (2FA)`} />
								) : (
									<Success title={`Двухфакторная аутентификация (2FA)`} />
								)}
							</Link>
							<button style={{ background: 'transparent', border: 0 }}>
								{!isConnected ? (
									<Error title={`Подключение MetaMask`} />
								) : (
									<Success title={`Подключение MetaMask`} />
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.balanceContainer}>
				<BalanceBlock
					setAction={setAction}
					balance={totalBalance}
					blocked={totalBlocked}
					title='total'
					setIsOpenTransferModal={setIsOpenTransferModal}
					setIsOpenMoveInModal={setIsOpenMoveInModal}
					setIsOpenMoveOutModal={setIsOpenMoveOutModal}
					setIsMoveOutModal={setIsMoveOutModal}
					setIsOpenPayInModal={setIsOpenPayInModal}
					setIsOpenPayOutModal={setIsOpenPayOutModal}
					setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
				/>
				<BalanceBlock
					setAction={setAction}
					balance={b2bBalance}
					blocked={b2bBlocked}
					title='b2b'
					setIsOpenTransferModal={setIsOpenTransferModal}
					setIsOpenMoveInModal={setIsOpenMoveInModal}
					setIsOpenMoveOutModal={setIsOpenMoveOutModal}
					setIsMoveOutModal={setIsMoveOutModal}
					setIsOpenPayInModal={setIsOpenPayInModal}
					setIsOpenPayOutModal={setIsOpenPayOutModal}
					setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
				/>
			</div>

			<div className={styles.mobileNav}>
				{
					activePayments === 0 ?
						<div className={styles.right} onClick={() => changeSwipe('next')}>
							B2B кошелёк
							<img src={arr} alt="" />
						</div>
						:
						<div className={styles.left} onClick={() => changeSwipe('prev')}>
							<img src={arr} alt="" />
							Общий кошелёк
						</div>
				}
			</div>
			<div className={styles.swiperContainer} style={{ width: `${window.innerWidth - 20}px` }}>
				<Swiper spaceBetween={50} slidesPerView={1} onSlideChange={(e) => checkSlider(e)}
					onSwiper={(swiper) => setSwiperInstance(swiper)}
				>
					<SwiperSlide>
						<BalanceBlock
							setAction={setAction}
							balance={totalBalance}
							blocked={totalBlocked}
							title='total'
							setIsOpenTransferModal={setIsOpenTransferModal}
							setIsOpenMoveInModal={setIsOpenMoveInModal}
							setIsOpenMoveOutModal={setIsOpenMoveOutModal}
							setIsMoveOutModal={setIsMoveOutModal}
							setIsOpenPayInModal={setIsOpenPayInModal}
							setIsOpenPayOutModal={setIsOpenPayOutModal}
							setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<BalanceBlock
							setAction={setAction}
							balance={b2bBalance}
							blocked={b2bBlocked}
							title='b2b'
							setIsOpenTransferModal={setIsOpenTransferModal}
							setIsOpenMoveInModal={setIsOpenMoveInModal}
							setIsOpenMoveOutModal={setIsOpenMoveOutModal}
							setIsMoveOutModal={setIsMoveOutModal}
							setIsOpenPayInModal={setIsOpenPayInModal}
							setIsOpenPayOutModal={setIsOpenPayOutModal}
							setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
						/>
					</SwiperSlide>
				</Swiper>
			</div>

			{/* <Lastoperation /> */}

			<Statistics />

			<div className={styles.allPayments}>
				<div className={styles.payments}>
					<div className={styles.title}>
						Способ(-ы) оплаты P2P
						<p>
							Способы оплаты для P2P: при продаже криптовалюты добавленный Вами способ оплаты будет показан
							покупателю в качестве доступного способа для совершения платежа. Убедитесь, что имя владельца
							счета соответствует подтвержденному Вами имени на GREEnavi. Можно добавить до 3 способов
							оплаты.
						</p>
					</div>
					{payments.map((el, index) => {
						return (
							<div className={styles.payment} key={index}>
								<div className={styles.paymentTop}>
									<div className={styles.paymentName}>{el.name}</div>
									<div className={styles.paymentDel} onClick={() => delPayment(el)}>
										Удалить
									</div>
								</div>
								<div className={styles.paymentBottom}>
									<div className={styles.paymentRec}>
										<p className={styles.paymentTitle}>Фамилия Имя</p>
										<p>{el.payment_receiver}</p>
									</div>
									<div className={styles.paymentVal}>
										<p className={styles.paymentTitle}>
											{el.name === 'QIWI' ? 'Номер телефона' : 'Номер банковской карты'}
										</p>
										<p>{el.value}</p>
									</div>
								</div>
							</div>
						)
					})}
					{
						payments.length < 3 ?
							<button className={styles.btnOk} onClick={() => { setShowAddModal(true); setModal('P2P') }}>
								Добавить способ оплаты
							</button>
							:
							<></>
					}
				</div>
				<div className={styles.payments}>
					<div className={styles.title}>
						Способ(-ы) оплаты B2B
						<p>
							Способы оплаты для B2B: при продаже криптовалюты добавленный Вами способ оплаты будет показан
							покупателю в качестве доступного способа для совершения платежа. Убедитесь, что имя владельца
							счета соответствует подтвержденному Вами имени на GREEnavi. Можно добавить до 3 способов
							оплаты.
						</p>
					</div>
					{paymentsB2B.map((el, index) => {
						return (
							<div className={styles.payment} key={index}>
								<div className={styles.paymentTop}>
									<div className={styles.paymentName}>{currentB2bListBanks.filter(bank => +bank.value === +el.payment_id)[0]?.label ?? ''}</div>
									<div className={styles.paymentDel} onClick={() => delPaymentB2b(el)}>
										Удалить
									</div>
								</div>
								<div className={styles.paymentBottom}>
									{/* <div className={styles.paymentRec}>
										<p className={styles.paymentTitle}>Фамилия Имя</p>
										<p>{el.payment_receiver}</p>
									</div> */}
									<div className={styles.paymentVal}>
										<p className={styles.paymentTitle}>
											Расчётный счёт
										</p>
										<p>{el.value}</p>
									</div>
								</div>
							</div>
						)
					})}
					{
						paymentsB2B.length < 3 ?
							<button className={styles.btnOk} onClick={() => { setShowAddModal(true); setModal('B2B') }}>
								Добавить способ оплаты
							</button>
							:
							<></>
					}
				</div>
			</div>
			<div className={styles.allPaymentsMobile}>
				<div className={styles.payments} style={activePayments === 0 ? { display: 'block' } : { display: 'none' }}>
					<div className={styles.title}>
						Способ(-ы) оплаты P2P
						<p>
							Способы оплаты для P2P: при продаже криптовалюты добавленный Вами способ оплаты будет показан
							покупателю в качестве доступного способа для совершения платежа. Убедитесь, что имя владельца
							счета соответствует подтвержденному Вами имени на GREEnavi. Можно добавить до 3 способов
							оплаты.
						</p>
					</div>
					{payments.map((el, index) => {
						return (
							<div className={styles.payment} key={index}>
								<div className={styles.paymentTop}>
									<div className={styles.paymentName}>{el.name}</div>
									<div className={styles.paymentDel} onClick={() => delPayment(el)}>
										Удалить
									</div>
								</div>
								<div className={styles.paymentBottom}>
									<div className={styles.paymentRec}>
										<p className={styles.paymentTitle}>Фамилия Имя</p>
										<p>{el.payment_receiver}</p>
									</div>
									<div className={styles.paymentVal}>
										<p className={styles.paymentTitle}>
											{el.name === 'QIWI' ? 'Номер телефона' : 'Номер банковской карты'}
										</p>
										<p>{el.value}</p>
									</div>
								</div>
							</div>
						)
					})}
					{
						payments.length < 3 ?
							<button className={styles.btnOk} onClick={() => { setShowAddModal(true); setModal('P2P') }}>
								Добавить способ оплаты
							</button>
							:
							<></>
					}
				</div>
				<div className={styles.payments} style={activePayments === 1 ? { display: 'block' } : { display: 'none' }}>
					<div className={styles.title}>
						Способ(-ы) оплаты B2B
						<p>
							Способы оплаты для B2B: при продаже криптовалюты добавленный Вами способ оплаты будет показан
							покупателю в качестве доступного способа для совершения платежа. Убедитесь, что имя владельца
							счета соответствует подтвержденному Вами имени на GREEnavi. Можно добавить до 3 способов
							оплаты.
						</p>
					</div>
					{paymentsB2B.map((el, index) => {
						return (
							<div className={styles.payment} key={index}>
								<div className={styles.paymentTop}>
									<div className={styles.paymentName}>{currentB2bListBanks.filter(bank => +bank.value === +el.payment_id)[0]?.label ?? ''}</div>
									<div className={styles.paymentDel} onClick={() => delPaymentB2b(el)}>
										Удалить
									</div>
								</div>
								<div className={styles.paymentBottom}>
									{/* <div className={styles.paymentRec}>
										<p className={styles.paymentTitle}>Фамилия Имя</p>
										<p>{el.payment_receiver}</p>
									</div> */}
									<div className={styles.paymentVal}>
										<p className={styles.paymentTitle}>
											Расчётный счёт
										</p>
										<p>{el.value}</p>
									</div>
								</div>
							</div>
						)
					})}
					{
						paymentsB2B.length < 3 ?
							<button className={styles.btnOk} onClick={() => { setShowAddModal(true); setModal('B2B') }}>
								Добавить способ оплаты
							</button>
							:
							<></>
					}
				</div>
			</div>
			<MoveIn
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenMoveInModal}
				setIsOpenModal={setIsOpenMoveInModal}
			/>
			<MoveOut
				bankList={bankList}
				selectedBankOption={selectedBankOption}
				setSelectedBankOption={setSelectedBankOption}
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenMoveOutModal}
				setIsOpenModal={setIsOpenMoveOutModal}
				isMoveOutModal={isMoveOutModal}
				p2pChartList={p2pChartList.filter(el => el.symbol === 'USDT')}
			/>
			<PayIn
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenPayInModal}
				setIsOpenModal={setIsOpenPayInModal}
			/>
			<PayOut
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenPayOutModal}
				setIsOpenModal={setIsOpenPayOutModal}
			/>
			<B2bPayOut
				profile={props}
				bankList={bankListB2b}
				bankListToAdd={bankList}
				isOpenModal={isOpenB2bPayOutModal}
				setIsOpenModal={setIsOpenB2bPayOutModal}
				tokenList={tokenB2bList}
				selectedTokenOption={selectedTokenB2bOption}
				setSelectedTokenOption={setSelectedTokenB2bOption}
			/>
			<Transfer
				action={action}
				tokenP2pList={tokenList}
				tokenB2bList={tokenB2bList}
				isOpenModal={isOpenTransferModal}
				setIsOpenModal={setIsOpenTransferModal}
			/>
		</div>
	)
}

export default ProfileInfo

