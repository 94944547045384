import React from 'react'
import styles from './Privacy.module.scss'
import Container from '../../UI/container/Container'

const Privacy = () => {
  return (
    <div className={styles.privacyContainer}>
      <Container>
        <div className={styles.main}>
          <div className={styles.title}>Политика конфиденциальности</div>
          <div className={styles.block}>
            <div className={styles.subtitle}>1. Общие положения</div>
            <div className={styles.text}>
              Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые P2P GREEnavi (далее — Платформа).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.1.</span> Платформа ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>1.2.</span> Настоящая политика Платформы в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Платформа может получить о посетителях веб-сайта <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>2. Основные понятия, используемые в Политике</div>
            <div className={styles.text}>
              <span className={styles.num}>2.1.</span> Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.2.</span> Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.3.</span> Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.4.</span> Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.5.</span> Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.6.</span> Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.7.</span> Платформа — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.8.</span> Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.9.</span> ерсональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.10.</span> Пользователь — любой посетитель веб-сайта <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.11.</span> Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.12.</span> Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.13.</span> Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>2.14.</span> Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>3. Основные права и обязанности Платформы</div>
            <div className={styles.text}>
              <span className={styles.num}>3.1.</span> Платформа имеет право:
            </div>
            <div className={styles.text}>
              — получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;
            </div>
            <div className={styles.text}>
              — в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Платформа вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;
            </div>
            <div className={styles.text}>
              — самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>3.2.</span> Платформа обязана:
            </div>
            <div className={styles.text}>
              — предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;
            </div>
            <div className={styles.text}>
              — организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;
            </div>
            <div className={styles.text}>
              — отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;
            </div>
            <div className={styles.text}>
              — сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;
            </div>
            <div className={styles.text}>
              — принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;
            </div>
            <div className={styles.text}>
              — прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;
            </div>
            <div className={styles.text}>
              — исполнять иные обязанности, предусмотренные Законом о персональных данных.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>4. Основные права и обязанности субъектов персональных данных</div>
            <div className={styles.text}>
              <span className={styles.num}>4.1.</span> Субъекты персональных данных имеют право:
            </div>
            <div className={styles.text}>
              — получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Платформой в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;
            </div>
            <div className={styles.text}>
              — требовать от Платформы уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;
            </div>
            <div className={styles.text}>
              — выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;
            </div>
            <div className={styles.text}>
              — на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки персональных данных;
            </div>
            <div className={styles.text}>
              — обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Платформы при обработке его персональных данных;
            </div>
            <div className={styles.text}>
              — на осуществление иных прав, предусмотренных законодательством РФ.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.2.</span> Субъекты персональных данных обязаны:
            </div>
            <div className={styles.text}>
              — предоставлять Платформе достоверные данные о себе;
            </div>
            <div className={styles.text}>
              — сообщать Платформе об уточнении (обновлении, изменении) своих персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>4.3.</span> Лица, передавшие Платформе недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>5. Принципы обработки персональных данных</div>
            <div className={styles.text}>
              <span className={styles.num}>5.1.</span> Обработка персональных данных осуществляется на законной и справедливой основе.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.2.</span> Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.3.</span> Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.4.</span> Обработке подлежат только персональные данные, которые отвечают целям их обработки.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.5.</span> Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.6.</span> При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Платформа принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных, или неточных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>5.7.</span> Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо, обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>6. Цели обработки персональных данных</div>
            <div className={styles.text}>
              Цель обработки предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте
            </div>
            <div className={styles.text}>
              Персональные данные:
            </div>
            <div className={styles.text}>
              • фамилия, имя, отчество
            </div>
            <div className={styles.text}>
              • электронный адрес
            </div>
            <div className={styles.text}>
              • номера телефонов
            </div>
            <div className={styles.text}>
              • год, месяц, дата и место рождения
            </div>
            <div className={styles.text}>
              • фотографии
            </div>
            <div className={styles.text}>
              • реквизиты документа, удостоверяющего личность
            </div>
            <div className={styles.text}>
              • адрес фактического места проживания и регистрации по месту жительства и/или по месту пребывания
            </div>
            <div className={styles.text}>
              • сведения о доходах
            </div>
            <div className={styles.text}>
              Правовые основания:
            </div>
            <div className={styles.text}>
              • договоры, заключаемые между платформой и субъектом персональных данных
            </div>
            <div className={styles.text}>
              Виды обработки персональных данных:
            </div>
            <div className={styles.text}>
              • Сбор, запись, систематизация, накопление, хранение, уничтожение и обезличивание персональных данных
            </div>
            <div className={styles.text}>
              • Отправка информационных писем на адрес электронной почты
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>7. Условия обработки персональных данных</div>
            <div className={styles.text}>
              <span className={styles.num}>7.1.</span> Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.2.</span> Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления возложенных законодательством Российской Федерации на платформу функций, полномочий и обязанностей.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.3.</span> Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.4.</span> Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.5.</span> Обработка персональных данных необходима для осуществления прав и законных интересов платформы или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.6.</span> Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе (далее — общедоступные персональные данные).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>7.7.</span> Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>8. Порядок сбора, хранения, передачи и других видов обработки персональных данных</div>
            <div className={styles.text}>
              Безопасность персональных данных, которые обрабатываются Платформой, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.1.</span> Платформа обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.2.</span> Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом персональных данных дано согласие Платформе на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.3.</span> Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных данных и/или с указанными документами. Платформа не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.4.</span> Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством РФ.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.5.</span> Платформа при обработке персональных данных обеспечивает конфиденциальность персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.6.</span> Платформа осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>8.7.</span> Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных, отзыв согласия субъектом персональных данных или требование о прекращении обработки персональных данных, а также выявление неправомерной обработки персональных данных.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>9. Перечень действий, производимых Платформой с полученными персональными данными</div>
            <div className={styles.text}>
              <span className={styles.num}>9.1.</span> Платформа осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>9.2.</span> Платформа осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно телекоммуникационным сетям или без таковой.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>10. Трансграничная передача персональных данных</div>
            <div className={styles.text}>
              <span className={styles.num}>10.1.</span> Платформа до начала осуществления деятельности по трансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных (такое уведомление направляется отдельно от уведомления о намерении осуществлять обработку персональных данных).
            </div>
            <div className={styles.text}>
              <span className={styles.num}>10.2.</span> Платформа до подачи вышеуказанного уведомления, обязан получить от органов власти иностранного государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача персональных данных, соответствующие сведения.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>11. Конфиденциальность персональных данных</div>
            <div className={styles.text}>
              Платформа и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>12. Заключительные положения</div>
            <div className={styles.text}>
              <span className={styles.num}>12.1.</span> В данном документе будут отражены любые изменения политики обработки персональных данных Платформой. Политика действует бессрочно до замены ее новой версией.
            </div>
            <div className={styles.text}>
              <span className={styles.num}>12.2.</span> Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <span className={styles.num}><a href="/">https://greenavi.com</a></span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Privacy