import React, { useEffect, useState } from "react";
import styles from "./FirstStep.module.scss";
import arrow from "../../../assets/icons/arrow-right.svg";
import Select from "react-select";
import { FiatProps, TokenProps } from "../../filters/Filters";
import { GetRate } from "../../../services/P2p";

interface FirstStepContentModalProps {
  price: string;
  setPrice: (price: string) => void;
  selectedFiatOption: any;
  setSelectedFiatOption: (param: any) => void;
  selectedTokenOption: any;
  setSelectedTokenOption: (param: any) => void;
  tokenList: TokenProps[];
  fiatList: FiatProps[];
  rate: number;
  setRate: (param: number) => void;
}

const FirstStepContentModal = ({
  price,
  setPrice,
  selectedFiatOption,
  setSelectedFiatOption,
  selectedTokenOption,
  setSelectedTokenOption,
  tokenList,
  fiatList,
  rate,
  setRate,
}: FirstStepContentModalProps) => {
  const [type, setType] = useState("fix");
  const [percents, setPercents] = useState('100');


  const getRates = async (token: string, currency_id: number, chart_id: number) => {
    const data = await GetRate(token, currency_id, chart_id);
    if (data.status === 200) {
      setRate(+data.data.price)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    getRates(token, +selectedFiatOption.value, +selectedTokenOption.value)
  }, [selectedFiatOption, selectedTokenOption, setSelectedFiatOption, setSelectedTokenOption])

  useEffect(() => {
    if (type !== 'fix') {
      setPrice((+rate / 100 * +percents).toFixed(2))
    } else {
      setPrice((+rate).toFixed(2))
    }
  }, [type])

  useEffect(() => {
    if (type !== 'fix') {
      setPrice((+rate / 100 * +percents).toFixed(2))
    }
  }, [percents])

  const checkVal = (e: any) => {
    const pattern = new RegExp("^(\\d*)\\.{0,1}(\\d*)$")
    if (pattern.test(e.target.value)) {
      if (type === 'fix') {
        if (e.target.value.trim().length > 9) {
          setPrice(e.target.value.trim().slice(0, 9));
        } else if (e.target.value.trim() === '') {
          setPrice('');
        } else {
          setPrice(e.target.value.trim());
        }
      } else {
        if (e.target.value.trim().length > 3) {
          setPercents(e.target.value.slice(0, 3))
          e.target.value = e.target.value.trim().slice(0, 3);
        } else if (+e.target.value.trim() > 110) {
          setPercents('110')
          e.target.value = 110;
        } else if (e.target.value.trim() === '') {
          setPercents('');
        } else {
          setPercents(e.target.value.trim())
        }
      }
    }
  }

  const [choiseList, setChoiseList] = useState([
    { label: 'Текущий курс', value: 0 },
    { label: 'Ваш курс', value: 1 }
  ])
  const [selectedСhoiseOption, setSelectedChoiseOption] = useState<any>({ label: 'Текущий курс', value: 0 })

  useEffect(() => {
    if (+selectedСhoiseOption.value === 0) {
      setPrice((+rate).toFixed(2))
      setPercents('100')
    }
  }, [selectedСhoiseOption, setSelectedChoiseOption])

  useEffect(() => {
    setPrice((+rate).toFixed(2))
  }, [rate, setRate])

  return (
    <div className={styles.main}>
      <div className={styles.inputs}>
        <div className={styles.input}>
          <p className={styles.title}>Актив</p>
          <Select
            options={tokenList.slice(1)}
            classNames={{
              container: (state) => styles.select3,
              singleValue: (state) => styles.select4,
              control: (state) => styles.select2,
              menuList: (state) => styles.select6,
              menu: (state) => styles.select61,
              option: (state) => styles.select7,
            }}
            classNamePrefix="react-select"
            defaultValue={selectedTokenOption}
            onChange={(e) => setSelectedTokenOption(e)}
            placeholder={"USDT"}
          />
        </div>
        <img src={arrow} alt="" className={styles.arr} />
        <div className={styles.input}>
          <p className={styles.title}>За фиат</p>
          <Select
            options={fiatList.slice(1)}
            classNames={{
              container: (state) => styles.select3,
              singleValue: (state) => styles.select4,
              control: (state) => styles.select2,
              menuList: (state) => styles.select6,
              menu: (state) => styles.select61,
              option: (state) => styles.select7,
            }}
            classNamePrefix="react-select"
            defaultValue={selectedFiatOption}
            onChange={(e) => setSelectedFiatOption(e)}
            placeholder={"RUB"}
          />
        </div>
      </div>
      <div className={styles.content}>
        {/* <div className={styles.contentBlock}>
          <div className={styles.prices}>
            <div className={styles.yourPrice}>
              <p className={styles.title}>Ваша цена</p>
              <div className={styles.price}>
                {type === "fix"
                  ? +price === 0
                    ? `00.00`
                    : String((+price).toFixed(2)).length <= 4
                      ? `0${(+price).toFixed(2)}`
                      : (+price).toFixed(2)
                  : ((+rate / 100) * +percents).toFixed(2)}{" "}
                {selectedFiatOption.label}
              </div>
            </div>
            <div className={styles.actual}>
              <p className={styles.title}>Текущий курс</p>
              <div className={styles.price}>{rate} {selectedFiatOption.label}</div>
            </div>
          </div>
          <div className={styles.type}>
            <div
              className={styles.typeContent}
              onClick={() => setType("notFix")}
            >
              <div className={styles.mainCircle}>
                {type === "notFix" && <div className={styles.circle} />}
              </div>
              <p>Плавающая</p>
            </div>
            <div className={styles.typeContent} onClick={() => setType("fix")}>
              <div className={styles.mainCircle}>
                {type === "fix" && <div className={styles.circle} />}
              </div>
              <p>Фиксированная</p>
            </div>
          </div>
        </div> */}
        <div className={styles.summ}>
          <div className={styles.choise}>
            <Select
              options={choiseList}
              classNames={{
                container: (state) => styles.select3,
                singleValue: (state) => styles.select4,
                control: (state) => styles.select2c,
                menuList: (state) => styles.select6,
                menu: (state) => styles.select61,
                option: (state) => styles.select7,
              }}
              classNamePrefix="react-select"
              defaultValue={selectedСhoiseOption}
              onChange={(e) => setSelectedChoiseOption(e)}
              placeholder={selectedСhoiseOption}
            />
          </div>
          <div>
            {type === "fix" ? (
              <div
              // className={styles.summ}
              >
                {/* <p>Фиксированная цена</p> */}
                <div className={styles.yourPrice}>
                  <p className={styles.title}>{+selectedСhoiseOption.value === 0 ? `Текущий курс` : `Ваш курс`}</p>
                  <div className={styles.price}>
                    {type === "fix"
                      ? +price === 0
                        ? `00.00`
                        : String((+price).toFixed(2)).length <= 4
                          ? `0${(+price).toFixed(2)}`
                          : (+price).toFixed(2)
                      : ((+rate / 100) * +percents).toFixed(2)}{" "}
                    {selectedFiatOption.label}
                  </div>
                </div>
                <p style={+selectedСhoiseOption.value === 0 ? { display: 'none' } : { display: 'block' }}>Фиксированная цена</p>
                <div className={styles.counter} style={+selectedСhoiseOption.value === 0 ? { display: 'none' } : { display: 'flex' }}>
                  <button onClick={() => setPrice((+price + 1).toString())}>+</button>
                  <input
                    type="text"
                    placeholder={`Введите сумму`}
                    value={price}
                    onChange={(e) => checkVal(e)}
                  />
                  <button onClick={() => setPrice((+price === 0 ? 0 : +price - 1).toString())}>-</button>
                </div>
              </div>
            ) : (
              <div
              // className={styles.summ}
              >
                {/* <p>Коэффициент маржи для плавающей цены</p> */}
                <div className={styles.yourPrice}>
                  <p className={styles.title}>{+selectedСhoiseOption.value === 0 ? `Текущий курс` : `Ваш курс`}</p>
                  <div className={styles.price}>
                    {type === "fix"
                      ? +price === 0
                        ? `00.00`
                        : String((+price).toFixed(2)).length <= 4
                          ? `0${(+price).toFixed(2)}`
                          : (+price).toFixed(2)
                      : ((+rate / 100) * +percents).toFixed(2)}{" "}
                    {selectedFiatOption.label}
                  </div>
                </div>
                <p style={+selectedСhoiseOption.value === 0 ? { display: 'none', fontSize: '14px' } : { display: 'block', fontSize: '14px' }} >Коэффициент маржи для плавающей цены</p>
                <div className={styles.counter} style={+selectedСhoiseOption.value === 0 ? { display: 'none' } : { display: 'flex' }}>
                  <button onClick={() => setPercents((+percents === 130 ? +percents : +percents + 1).toString())}>+</button>
                  <input
                    type="text"
                    placeholder={`Введите процент`}
                    value={percents}
                    onChange={(e) => checkVal(e)}
                  />
                  <button onClick={() => setPercents((+percents === 0 ? 0 : +percents - 1).toString())}>-</button>
                </div>
                <div className={styles.hint} style={+selectedСhoiseOption.value === 0 ? { display: 'none' } : { display: 'flex' }}>{`${rate} * ${percents}% ≈ ${(+rate / 100 * +percents).toFixed(2)}`} {selectedFiatOption.label}</div>
              </div>
            )}
          </div>
          <div className={styles.type} style={+selectedСhoiseOption.value === 0 ? { display: 'none' } : { display: 'flex' }}>
            <div
              className={styles.typeContent}
              onClick={() => setType("notFix")}
            >
              <div className={styles.mainCircle}>
                {type === "notFix" && <div className={styles.circle} />}
              </div>
              <p>Плавающая</p>
            </div>
            <div className={styles.typeContent} onClick={() => setType("fix")}>
              <div className={styles.mainCircle}>
                {type === "fix" && <div className={styles.circle} />}
              </div>
              <p>Фиксированная</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstStepContentModal;
