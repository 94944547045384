import React from 'react'
import styles from './BalanceBlock.module.scss'
import arrow from '../../../assets/icons/profile/balance/1.svg'
import arrow2 from '../../../assets/icons/profile/balance/2.svg'
import add from '../../../assets/icons/profile/balance/3.svg'
import exchange from '../../../assets/icons/profile/balance/4.svg'
import ArrowsIcon from '../../../assets/icons/swap/arrows.svg'
import { Link, useLocation } from 'react-router-dom'

interface BalanceBlockProps {
	balance: number
	blocked: number
	title: 'total' | 'b2b'
	setAction: (param: 'total' | 'b2b') => void
	setIsOpenTransferModal: (param: boolean) => void
	setIsOpenMoveInModal: (param: boolean) => void
	setIsOpenMoveOutModal: (param: boolean) => void
	setIsOpenPayInModal: (param: boolean) => void
	setIsOpenPayOutModal: (param: boolean) => void
	setIsOpenB2bPayOutModal: (param: boolean) => void
	setIsMoveOutModal: (param: boolean) => void
}

const BalanceBlock = ({
	balance,
	blocked,
	title,
	setAction,
	setIsOpenTransferModal,
	setIsOpenMoveInModal,
	setIsOpenMoveOutModal,
	setIsOpenPayInModal,
	setIsOpenPayOutModal,
	setIsOpenB2bPayOutModal,
	setIsMoveOutModal
}: BalanceBlockProps) => {
	const location = useLocation()
	const availableBalance = balance


	const jivoHide = () => {
		let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div')
			; (jivo as HTMLDivElement).style.display = 'none'
		let body = document.getElementsByTagName('body')[0]
			; (body as HTMLBodyElement).style.overflow = 'hidden'
	}

	return (
		<div className={styles.main}>
			<div className={styles.content}>
				<div className={styles.topBlock}>
					<div className={styles.balanceInfo}>
						<p className={styles.generalText}>
							{title === 'total' ? (
								'Общий баланс'
							) : (
								<>
									Баланс <span>B2B</span>
								</>
							)}
						</p>
					</div>
					<div className={styles.precentInfo}></div>
				</div>
				<div className={styles.mainBalance}>
					<p className={styles.balanceText}>≈ {+balance}</p>
					<p className={styles.currency}>$</p>
				</div>
				<div className={styles.infoBlock}>
					<p className={styles.available}>Доступно: {+availableBalance <= 0 ? 0 : availableBalance}</p>
					<p className={styles.frozen}>Заморожено: {blocked}</p>
				</div>
				<div className={styles.btnsBlock}>
					{title === 'total' ? (
						<>
							<div
								className={styles.btnBlock}
								onClick={() => {
									setIsOpenPayInModal(true)
									setIsMoveOutModal(false)
									jivoHide()
								}}
							>
								<img src={arrow} alt='' className={`${styles.img}`} />
								<p className={styles.btnText}>Купить</p>
							</div>
							<div
								className={styles.btnBlock}
								onClick={() => {
									setIsOpenPayOutModal(true)
									setIsMoveOutModal(false)
									jivoHide()
								}}
							>
								<img src={arrow2} alt='' className={`${styles.img}`} />
								<p className={styles.btnText}>Продать</p>
							</div>
							<div
								className={styles.btnBlock}
								onClick={() => {
									setIsOpenMoveInModal(true)
									setIsMoveOutModal(false)
									jivoHide()
								}}
							>
								<img src={add} alt='' className={`${styles.buyImg} ${styles.img}`} />
								<p className={styles.btnText}>Ввод</p>
							</div>
							<div
								className={styles.btnBlock}
								onClick={() => {
									setIsOpenMoveOutModal(true)
									setIsMoveOutModal(true)
									jivoHide()
								}}
							>
								<img src={exchange} alt='' className={`${styles.sellImg} ${styles.img}`} />
								<p className={styles.btnText}>Вывод</p>
							</div>
							<div
								className={styles.btnBlock}
								onClick={() => {
									setIsOpenTransferModal(true)
									setIsMoveOutModal(false)
									setAction(title)
									jivoHide()
								}}
							>
								<img src={exchange} alt='' className={`${styles.img}`} />
								<p className={styles.btnText}>Перевод</p>
							</div>
						</>
					) : (
						<>
							<div
								className={styles.btnBlock}
								onClick={() => {
									window.location.href = '/b2b'
								}}
							>
								<img src={arrow} alt='' className={`${styles.img}`} />
								<p className={styles.btnText}>Купить</p>
							</div>
							<div
								className={styles.btnBlock}
								onClick={() => {
									setIsOpenB2bPayOutModal(true)
									setIsMoveOutModal(false)
									jivoHide()
								}}
							>
								<img src={arrow2} alt='' className={`${styles.img}`} />
								<p className={styles.btnText}>Продать</p>
							</div>
							<div
								className={styles.btnBlock}
								onClick={() => {
									setIsOpenTransferModal(true)
									setIsMoveOutModal(false)
									setAction(title)
									jivoHide()
								}}
							>
								<img src={exchange} alt='' className={`${styles.img}`} />
								<p className={styles.btnText}>Перевод</p>
							</div>
						</>
					)}
					{/* <Link className={styles.btnBlock} to={'/profile/swap'}>
						<img src={ArrowsIcon} alt='' className={`${styles.img}`} />
						<p className={styles.btnText}>Обмен</p>
					</Link> */}
				</div>
			</div>
			<Link
				className={styles.navLink}
				to={'/profile/wallet'}
				style={location.pathname === '/profile/wallet' ? { display: 'none' } : { display: 'block' }}
			>
				<button className={styles.bottomBtn}>Перейти в кошелёк</button>
			</Link>
		</div>
	)
}

export default BalanceBlock
