import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import styles from './SendBlock.module.scss'
import fileIcon from "../../../assets/icons/file.svg";
import { NewMessage } from '../../../services/ChatService';
import send from '../../../assets/icons/send.svg'
import remove from '../../../assets/icons/close.svg'

interface SendBlockPropsType {
  userId: number,
  roomId: number,
  setReload: (param: boolean) => void,
}

const SendBlock = ({
  userId,
  roomId,
  setReload,
}: SendBlockPropsType) => {

  const [file, setFile] = useState<any>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      if (!e.target.files[0]) return;
      setFile(e.target.files[0]);
    } else { setFile(null) }
  };

  const inpList = document.querySelectorAll('#text');

  const sendMessage = async (formData: any) => {
    const data = await NewMessage(formData)
    inpList.forEach(el => (el as HTMLInputElement).value = '');
    setReload(true);
    setFile(null);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!e.target) return
    // Prevent the browser from reloading the page
    const form = e.target;

    //@ts-ignore
    const formData = new FormData(form);

    // Read the form data

    if (file?.name) {
      formData.append('attachment_title', file.name);
      formData.append('attachment', file);
      formData.append("attachment_type", '1');
    } else {
      formData.append("attachment_type", "");
    };
    formData.append("sender_user_id", userId.toString());
    formData.append("chat_room_id", roomId.toString());

    sendMessage(formData)

  }

  useEffect(() => {
    const formData = new FormData();
    formData.append("text", '')
    formData.append("attachment_type", '')
    formData.append("sender_user_id", userId.toString())
    formData.append("chat_room_id", roomId.toString())

    sendMessage(formData)
  }, [])

  return (
    <form method="post" onSubmit={handleSubmit} className={styles.sendBlock}>
      {
        file ?
          <div className={styles.filesNames}>
            <div>{file.name} <img src={remove} alt="remove" onClick={() => setFile(null)} /></div>
          </div>
          : <></>
      }
      <div className={styles.main}>
        {
          !file ?
            <>
              <input
                // accept="jpg"
                type="file"
                id='fileInput'
                className={styles.fileInput}
                onChange={handleFileChange}
              />
              <label htmlFor="fileInput" style={file ? { display: 'none' } : { display: 'flex' }}>
                <img src={fileIcon} alt="" />
              </label>
            </>
            :
            <></>
        }
        <input name="text" defaultValue="" placeholder='Сообщение' className={styles.inputText} id='text' />
        <button type="submit" className={styles.submitBtn}>
          <img src={send} alt="send" className={styles.sendIcon} />
        </button>
      </div>
    </form>
  )
}

export default SendBlock