import React, { useCallback, useEffect, useState } from 'react'
import styles from './MoveOut.module.scss'
import { ReactComponent as Cross } from '../../../../assets/icons/cross.svg'
import Select from 'react-select'
import { BankProps, TokenProps } from '../../../filters/Filters'
import { GetChainList, GetFeeMethod, moveOut } from '../../../../services/PayService'
import { Chart } from '../../wallet/Wallet'
import { useDebouncedCallback } from 'use-debounce';
import trc20 from '../../../../assets/icons/coins/trc20.svg'
import erc20 from '../../../../assets/icons/coins/erc20.svg'

interface MoveOutModalProps {
	selectedBankOption: any
	setSelectedBankOption: (param: any) => void
	isOpenModal: boolean
	setIsOpenModal: (isOpenModal: boolean) => void
	tokenList: TokenProps[]
	bankList: BankProps[]
	selectedTokenOption: any
	setSelectedTokenOption: (param: any) => void
	p2pChartList: Chart[]
	isMoveOutModal: boolean
}

const MoveOut = ({
	isOpenModal,
	setIsOpenModal,
	selectedTokenOption,
	setSelectedTokenOption,
	selectedBankOption,
	setSelectedBankOption,
	bankList,
	p2pChartList,
	isMoveOutModal
}: MoveOutModalProps) => {
	const [summ, setSumm] = useState('10')
	const [address, setAddress] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [okMessage, setOkMessage] = useState('')
	const [fee, setFee] = useState(0)
	// const [rate, setRate] = useState(0)

	const error = <div className={styles.error}>{errorMessage}</div>
	const ok = <div className={styles.ok}>{okMessage}</div>

	let prev = ''
	const priceChange = (e: any) => {
		const pattern = new RegExp('^(\\d*)\\.{0,1}(\\d*)$')
		if (pattern.test(e.target.value.trim())) {
			if (e.target.value.trim().length > 48) {
				setSumm(e.target.value.trim().slice(0, 48))
				prev = e.target.value.trim().slice(0, 48)
			} else {
				setSumm(e.target.value.trim())
				prev = e.target.value.trim()
			}
		} else if (e.target.value.trim() === '') {
			setSumm('')
			prev = ''
		} else {
			e.target.value = prev
		}
	}

	const addressChange = (e: any) => {
		const pattern = new RegExp('^[a-zA-Z0-9]*$')
		if (pattern.test(e.target.value.trim()) && e.target.value.trim().length <= 48) {
			setAddress(e.target.value.trim())
		}
	}
	const [load, setLoad] = useState(false)

	const getChains = useCallback(async () => {
		const token = localStorage.getItem('access_token') ?? ''
		const data = await GetChainList(token, +selectedTokenOption.value)
		if (data.status === 200) {
			let temp = data.data.map((el: any) => {
				// return { label: el.name.toUpperCase(), value: el.id }
				let newName = (el.name as string).startsWith('usdt') ? el.name.slice(4) : el.name
				return {
					label: <div className={styles.fiatBox}>
						<img src={el.name === 'usdterc20' ? erc20 : el.name === 'usdttrc20' ? trc20 : ''} alt="" className={styles.fiat} />
						{newName.toUpperCase()}</div>, value: el.id
				}
			})
			setChainList(temp)
			setSelectedChainOption(temp[0])
			setLoad(false)
		}
	}, [selectedTokenOption])

	useEffect(() => {
		setLoad(true)
		getChains()
	}, [getChains])

	const [currentBalance, setCurrentBalance] = useState(0)

	useEffect(() => {
		let temp = +p2pChartList[0]?.balance ? +p2pChartList[0]?.balance : 0
		setCurrentBalance(temp)
	}, [p2pChartList])

	const [loading, setLoading] = useState(false)
	const [tokenList, setTokenList] = useState<TokenProps[]>([])
	const [chainList, setChainList] = useState<any>([
		{ label: <div className={styles.fiatBox}><img src={erc20} alt="" className={styles.fiat} />ERC20</div>, value: 54 },
		{ label: <div className={styles.fiatBox}><img src={trc20} alt="" className={styles.fiat} />TRC20</div>, value: 56 },
	])
	const [selectedChainOption, setSelectedChainOption] = useState<any>({ label: 'ERC20', value: 56 })

	// const fetchTokenList = async () => {
	// try {
	// 	const response = await axios.get('https://greenavi.com/api/payment/list-currencies', {
	// 		headers: { accept: 'application/json' }
	// 	})
	// 	if (response.status === 200) {
	// 		const currencies = response.data.currencies.map((currency: string) => ({
	// 			label: currency.toUpperCase(),
	// 			value: currency
	// 		}))
	// 		setTokenList([{ label: 'USDT', value: 259 }])
	// 	} else {
	// 		console.error('Failed to fetch currencies:', response.statusText)
	// 	}
	// } catch (error) {
	// 	console.error('Error fetching currencies:', error)
	// }
	// }

	const getFee = useDebouncedCallback(async () => {
		const token = localStorage.getItem("access_token") ?? '';
		const currentChain = selectedChainOption?.label.props.children[1] === 'ERC20' ? 'USDTERC20' : selectedChainOption?.label.props.children[1] === 'TRC20' ? 'USDTTRC20' : selectedChainOption?.label.props.children[1];
		const data = await GetFeeMethod(token, currentChain ?? '', +summ)
		if (data.status === 200) {
			setFee(+(+data.data.fee as number).toFixed(2))
			setLoading(false)
		}
	}
		, 1000
	)

	useEffect(() => {
		if (isMoveOutModal) {
			setLoading(true)
			if (+summ >= 10) {
				getFee()
			}
		}
	}, [getFee, summ, selectedChainOption, isMoveOutModal])

	const moveOutRequest = async () => {
		if (+summ && address) {
			setLoading(true)
			if (+summ <= +currentBalance) {
				try {
					const token = localStorage.getItem('access_token') ?? ''
					const currency = +selectedChainOption.value
					const chart_id = +selectedTokenOption.value
					const amount = parseFloat(summ)
					const outAddress = address

					const response = await moveOut(token, outAddress, chart_id, amount, currency)
					if (response.status === 200 && response.data.success) {
						setOkMessage(response.data.message || 'Запрос ушёл на обработку...')
						setTimeout(() => {
							setOkMessage('')
							window.location.href = '/profile/wallet'
						}, 2000)
					} else {
						setErrorMessage(response.data.message)
						setTimeout(() => {
							setErrorMessage('')
							setLoading(false)
						}, 2000)
					}
				} catch (error) {
					console.error(error)
					setErrorMessage('Что-то пошло не так..')
					setTimeout(() => {
						setErrorMessage('')
						setLoading(false)
					}, 2000)
				}
			} else {
				setErrorMessage('Введённая сумма превышает баланс')
				setTimeout(() => {
					setErrorMessage('')
					setLoading(false)
				}, 2000)
			}
		} else {
			setErrorMessage('Проверьте введённые данные')
			setTimeout(() => {
				setErrorMessage('')
				setLoading(false)
			}, 2000)
		}
	}

	useEffect(() => {
		// fetchTokenList()
		setTokenList([{ label: 'USDT', value: 259 }])
	}, [])

	const jivoShow = () => {
		let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div')
			; (jivo as HTMLDivElement).style.display = 'block'
		let body = document.getElementsByTagName('body')[0]
			; (body as HTMLBodyElement).style.overflow = 'unset'
	}

	useEffect(() => {
		if ((selectedTokenOption.label !== 'USDT') && isMoveOutModal) {
			setSelectedTokenOption({ label: 'USDT', value: 259 })
		}
	}, [isMoveOutModal, selectedTokenOption, setSelectedTokenOption])

	return (
		<>
			{isOpenModal && (
				<>
					<div
						className={styles.popupIn}
						onClick={() => {
							setIsOpenModal(false)
							jivoShow()
						}}
					></div>
					<div className={styles.popupIn__block} onClick={e => e.stopPropagation()}>
						<div className={styles.popupIn__title}>Вывод</div>
						<Cross
							className={styles.popupIn__close}
							onClick={() => {
								setIsOpenModal(false)
								jivoShow()
							}}
						/>
						<div className={styles.popupIn__info}>
							Вывод криптовалюты USDT осуществляется только в сетях (TRC20) или (ERC20)
						</div>
						<div className={styles.popupIn__text}>Выберите валюту</div>
						<Select
							options={tokenList}
							placeholder={`Выберите криптовалюту`}
							classNames={{
								container: state => styles.select3,
								singleValue: state => styles.select4,
								control: state => styles.select2,
								menuList: state => styles.select6,
								menu: state => styles.select61,
								option: state => styles.select7
							}}
							classNamePrefix='react-select'
							onChange={setSelectedTokenOption}
							defaultValue={{ label: 'USDT', value: 259 }}
						/>
						{!load ? (
							<div>
								<div className={styles.popupIn__text}>Выберите сеть</div>
								<Select
									options={chainList}
									placeholder={`Выберите сеть`}
									classNames={{
										container: state => styles.select3,
										singleValue: state => styles.select4,
										control: state => styles.select2,
										menuList: state => styles.select6,
										menu: state => styles.select61,
										option: state => styles.select7
									}}
									classNamePrefix='react-select'
									onChange={setSelectedChainOption}
									defaultValue={chainList[0]}
								/>
							</div>
						) : (
							<></>
						)}
						<div className={styles.popupIn__text}>Кошелек</div>
						<div className={styles.popupIn__input}>
							<input
								type='text'
								placeholder={`Введите адрес`}
								value={address}
								onChange={e => addressChange(e)}
								id='inp4'
								maxLength={48}
							/>
						</div>
						<div className={styles.popupIn__text}>Сумма</div>
						<div className={styles.popupIn__input}>
							<input
								type='text'
								placeholder={`Введите сумму`}
								value={summ}
								onChange={e => priceChange(e)}
								id='inp3'
								maxLength={20}
							/>
						</div>
						<div className={styles.popupIn__balance}>
							Текущий баланс:
							<div className={styles.curVal}>
								<p>{currentBalance} {'USDT'}</p>
							</div>
						</div>
						<div className={styles.popupIn__balance}>
							Комиссия ≈
							<div className={styles.curVal}>
								<p>{fee} {'USDT'}</p>
							</div>
						</div>
						<div className={styles.popupIn__balance}>
							Минимальная сумма для вывода:
							<div className={styles.curVal}>
								<p>{10} {'USDT'}</p>
							</div>
						</div>
						<div className={styles.popupIn__balance}>
							Сумма к выводу:
							<div className={styles.curVal}>
								<p>{(+summ + +fee).toFixed(2)} {'USDT'}</p>
							</div>
						</div>
						{errorMessage ? error : <></>}
						{okMessage ? ok : <></>}
						<div
							className={loading || !address || !selectedTokenOption.label ? styles.btnDis : styles.btnOk}
							onClick={() => !loading && address && selectedTokenOption.label ? moveOutRequest() : <></>}
						>
							Вывести криптовалюту
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default MoveOut
