import React, { useState } from 'react'
import styles from './HistoryTransactionsTable.module.scss'
import { IWalletHistory } from '../../../../types/types'
import { ReactComponent as RollUp } from "../../../../assets/icons/profile/balance/Button.svg";

interface HistoryTransactionsTableItemPropsType {
  historyItem: IWalletHistory;
  type: number;
}

const HistoryTransactionsTableItem = ({
  historyItem,
  type,
}: HistoryTransactionsTableItemPropsType) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div className={styles.blockItem}>
        <div
          onClick={() => setIsVisible((prev) => !prev)}
          className={styles.blockItemTop}
        >
          <div className={styles.item}>
            {historyItem.type}
          </div>
          <div className={styles.blockItemTopCenter}>
          </div>
          <div>
            <RollUp className={`${isVisible && styles.blockItemBtn}`} />
          </div>
        </div>
        <div
          className={`${styles.blockItemBody} ${isVisible && styles.blockItemBodyVisible
            }`}
        >
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Сумма</span>
            {parseFloat((+historyItem.start_price).toString()).toString().split(".")[1] ?
              parseFloat((+historyItem.start_price).toString()).toFixed(4) :
              parseFloat((+historyItem.start_price).toString())} {historyItem.start_symbol}
          </div>
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Дата</span>
            {historyItem.date.split(' ')[0]}
          </div>
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Тип операции</span>
            {type === 3 ? 'на' : ''} {historyItem.wallet_direct_id} {type === 3 ? 'счёт' : ''}
          </div>
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Статус</span>
            <span className={styles.blockItemBodyRowRight}>
              {historyItem.status}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.itemBlock} style={historyItem.payment_id !== '-' ? { gridTemplateColumns: 'repeat(7, 1fr)' } : { gridTemplateColumns: 'repeat(6, 1fr)' }}>
        <div className={styles.item}>{historyItem.uuid}</div>
        <div className={styles.item}>{historyItem.type}</div>
        <p className={styles.item}>{parseFloat((+historyItem.start_price).toString()).toString().split(".")[1] ?
          parseFloat((+historyItem.start_price).toString()).toFixed(4) :
          parseFloat((+historyItem.start_price).toString())} {historyItem.start_symbol}</p>
        <p className={styles.item}>{historyItem.date.split(' ')[0]}</p>
        <p className={styles.item}>{historyItem.payment_id !== '-' ? historyItem.wallet_direct_id.split('(')[0] : historyItem.wallet_direct_id}</p>
        <p className={styles.item}>{historyItem.status}</p>
        <p className={styles.item} style={historyItem.payment_id !== '-' ? { display: 'block' } : { display: 'none' }}>{historyItem.payment_id}</p>
      </div>
    </>
  )
}

export default HistoryTransactionsTableItem