import React from 'react';
import { v1 } from 'uuid';
import style from './GeneralBlock.module.scss';
import generalV from '../../../assets/icons/main/v.svg';



export const GeneralBlock = () => {

    return (
        <div className={style.generalBlockWrapper}>

            <div className={style.mainOptions}>
                <h3>GREEnav<span>i</span> - это</h3>
                <ul className={style.mainOptionsList}>
                    <li>
                        <img src={generalV} alt="" />
                        <p>Взаимодействие между юридическими лицами на локальном и международном уровнях</p>
                    </li>
                    <li>
                        <img src={generalV} alt="" />
                        <p>Торговая платформа мирового уровня</p>
                    </li>
                    <li>
                        <img src={generalV} alt="" />
                        <p>Возможность получать пассивный доход</p>
                    </li>
                    <li>
                        <img src={generalV} alt="" />
                        <p>Минимальные комиссии на рынке</p>
                    </li>
                    <li>
                        <img src={generalV} alt="" />
                        <p>Стабильная работа платформы и круглосуточная техподдержка</p>
                    </li>
                </ul>
            </div>
        </div>
    );
};