import React, { useEffect, useState } from 'react'
import styles from './Transfer.module.scss'
import { ReactComponent as Cross } from "../../../../assets/icons/cross.svg";
import Select from 'react-select'
import { TokenProps } from '../../../filters/Filters';
import { BalanceInfo, GetWallets, TransferWallets } from '../../../../services/UserService';
import arrDown from '../../../../assets/icons/arrow-right.svg'
import { ChartBalance } from '../b2bPayOut/B2bPayOut';
import { WalletBalance } from '../../../../types/types';
import Skeleton from 'react-loading-skeleton';
// import { GetRate } from '../../../../services/P2p';

interface TransferPropsType {
  action: 'total' | 'b2b';
  isOpenModal: boolean;
  setIsOpenModal: (param: boolean) => void;
  tokenP2pList: TokenProps[];
  tokenB2bList: TokenProps[];
}

export interface WalletList {
  value: number;
  label: any;
}

const Transfer = ({
  action,
  isOpenModal,
  setIsOpenModal,
  tokenP2pList,
  tokenB2bList,
}: TransferPropsType) => {
  const [summ, setSumm] = useState('10');
  const [errorMessage, setErrorMessage] = useState('')
  const [okMessage, setOkMessage] = useState('')
  const [wallets, setWallets] = useState([{ id: 0, title: 'Финансовый', active: 1 }, { id: 1, title: 'B2B', active: 1 }])

  const [selectedTokenFromOption, setSelectedTokenFromOption] = useState<any>({ label: <div className={styles.fiatBox}><img src={''} style={{ display: "none" }} alt={''} className={styles.fiat} />USDT</div>, value: 259 });
  // const [selectedTokenToOption, setSelectedTokenToOption] = useState<any>({ label: <div className={styles.fiatBox}><img src={''} style={{ display: "none" }} alt={''} className={styles.fiat} />USDT</div>, value: 259 });
  const [selectedWalletFrom, setSelectedWalletFrom] = useState<any>(action === 'total' ? { value: 0, label: 'Финансовый' } : { value: 1, label: 'B2B' });
  const [selectedWalletTo, setSelectedWalletTo] = useState<any>(action === 'total' ? { value: 1, label: 'B2B' } : { value: 0, label: 'Финансовый' });
  const [walletListFrom, setWalletListFrom] = useState<WalletList[]>([{ value: 0, label: 'Финансовый' }, { value: 1, label: 'B2B' }]);
  const [walletListTo, setWalletListTo] = useState<WalletList[]>([{ value: 0, label: 'Финансовый' }, { value: 1, label: 'B2B' }]);

  // const [rate, setRate] = useState(0);
  const [chartsBalances, setChartsBalances] = useState<ChartBalance[]>([])
  const [currentChartBalance, setCurrentChartBalance] = useState<ChartBalance>({
    id: 259,
    balance: 0,
    price: 0
  })

  useEffect(() => {
    let row = wallets.filter(el => el.title !== selectedWalletFrom.label);
    let temp = row.map(el => { return { label: el.title, value: el.id } })
    setWalletListTo(temp)
    let row2 = wallets.filter(el => el.title !== selectedWalletTo.label);
    let temp2 = row2.map(el => { return { label: el.title, value: el.id } })
    setWalletListFrom(temp2)
  }, [selectedWalletFrom.label, selectedWalletTo.label, wallets])

  const getWallets = async (token: string) => {
    const data = await GetWallets(token);
    if (data.status === 200 && data.data) {
      setWallets(data.data.slice(0, 6))
    }
  }


  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    getWallets(token);
  }, [])

  useEffect(() => {
    // const token = localStorage.getItem("access_token") ?? '';
    if (selectedTokenFromOption.value) {
      // getRates(token, 1, selectedWalletFrom.label === 'B2B' ? +selectedTokenB2bOption.value : +selectedTokenP2pOption.value);
      let temp = chartsBalances.filter(el => el.id === (selectedTokenFromOption.value));
      setCurrentChartBalance(temp[0]);
    }
  }, [chartsBalances, selectedTokenFromOption.value,
    // selectedTokenToOption.value, 
    selectedWalletFrom.label, selectedTokenFromOption,
    //  selectedTokenToOption
  ])

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    getBalance(token)
  }, [selectedWalletFrom.value])

  // const getRates = async (token: string, currency_id: number, chart_id: number) => {
  //   const data = await GetRate(token, currency_id, chart_id);
  //   if (data.status === 200) {
  //     setRate(data.data.price)
  //   }
  // }

  const getBalance = async (token: string) => {
    const data = await BalanceInfo(token, +selectedWalletFrom.value);
    if (data.status === 200) {
      let row: WalletBalance[] = data.data;
      let tempList: ChartBalance[] = row.map(el => {
        return {
          id: +el.id,
          balance: +el.balance,
          price: +el.price
        }
      })
      setChartsBalances(tempList)
    }
  }

  let prev = ''
  const priceChange = (e: any) => {
    const pattern = new RegExp("^(\\d*)\\.{0,1}(\\d*)$")
    if (pattern.test(e.target.value.trim())) {
      if (e.target.value.trim().length > 12) {
        setSumm(e.target.value.trim().slice(0, 12));
        prev = e.target.value.trim().slice(0, 12);
      } else {
        setSumm(e.target.value.trim());
        prev = e.target.value.trim();
      }
    } else if (e.target.value.trim() === '') {
      setSumm('');
      prev = '';
    } else {
      e.target.value = prev;
    }
  }

  const error = <div className={styles.error}>
    {errorMessage}
  </div>

  const ok = <div className={styles.ok}>
    {okMessage}
  </div>

  const transfer = async () => {
    const token = localStorage.getItem("access_token") ?? '';
    const data = await TransferWallets(
      token,
      +selectedWalletFrom.value,
      +selectedWalletTo.value,
      +selectedTokenFromOption.value,
      // +selectedTokenToOption.value,
      +summ
    )
    if (data.status === 200) {
      setOkMessage(data.data.message)
      setTimeout(() => {
        setOkMessage('')
        document.location.reload()
      }, 2000);
    } else {
      setErrorMessage(data.data.message)
      setTimeout(() => {
        setErrorMessage('')
      }, 2000);
    }

  }

  const endsList = [
    'c Финансового',
    'c B2B',
    'co Спотового',
    'c Маржинального',
    'c Торгового',
    'c Инвестиционного'
  ]

  const jivoShow = () => {
    let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div');
    (jivo as HTMLDivElement).style.display = 'block';
    let body = document.getElementsByTagName('body')[0];
    (body as HTMLBodyElement).style.overflow = 'unset';
  }

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setSelectedWalletFrom(action === 'total' ? { value: 0, label: 'Финансовый' } : { value: 1, label: 'B2B' });
    setSelectedWalletTo(action === 'total' ? { value: 1, label: 'B2B' } : { value: 0, label: 'Финансовый' });
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [action])

  return (
    <>
      {isOpenModal && (
        <>
          <div className={styles.popupIn} onClick={() => { setIsOpenModal(false); jivoShow() }}></div>
          <div
            className={styles.popupIn__block}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.popupIn__title}>Перевод</div>
            <Cross
              className={styles.popupIn__close}
              onClick={() => { setIsOpenModal(false); jivoShow() }}
            />
            <div className={styles.popupIn__text}>Выбор кошельков </div>
            {
              loading ?
                <div className={styles.skeletonContainer}>
                  <div className={styles.skeletonBlock1}>
                    <div>
                      <Skeleton height={40} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
                    </div>
                  </div>
                  <div className={styles.arr}>
                    <img src={arrDown} alt="arr" />
                  </div>
                  <div className={styles.skeletonBlock2}>
                    <div>
                      <Skeleton height={40} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
                    </div>
                  </div>
                </div>
                :
                <div className={styles.flexBox}>
                  <Select
                    options={walletListFrom}
                    placeholder={`Выберите кошелёк`}
                    classNames={{
                      container: (state) => styles.select3,
                      singleValue: (state) => styles.select4,
                      control: (state) => styles.select2,
                      menuList: (state) => styles.select6,
                      menu: (state) => styles.select61,
                      option: (state) => styles.select7,
                      valueContainer: (state) => styles.select5,
                    }}
                    classNamePrefix="react-select"
                    onChange={setSelectedWalletFrom}
                    defaultValue={selectedWalletFrom}
                  />
                  <div className={styles.arr}>
                    <img src={arrDown} alt="arr" />
                  </div>
                  <Select
                    options={walletListTo}
                    placeholder={`Выберите кошелёк`}
                    classNames={{
                      container: (state) => styles.select3,
                      singleValue: (state) => styles.select4,
                      control: (state) => styles.select2,
                      menuList: (state) => styles.select6,
                      menu: (state) => styles.select61,
                      option: (state) => styles.select7,
                      valueContainer: (state) => styles.select5,
                    }}
                    classNamePrefix="react-select"
                    onChange={setSelectedWalletTo}
                    defaultValue={selectedWalletTo}
                  />
                </div>
            }
            <div className={styles.popupIn__text}>Валюта </div>
            <div className={styles.flexBoxVal}>
              <Select
                options={selectedWalletFrom.label === 'B2B' ? tokenB2bList : tokenP2pList}
                placeholder={`Выберите валюту`}
                classNames={{
                  container: (state) => styles.select3Val,
                  singleValue: (state) => styles.select4,
                  control: (state) => styles.select2Val,
                  menuList: (state) => styles.select6,
                  menu: (state) => styles.select61,
                  option: (state) => styles.select7,
                  valueContainer: (state) => styles.select5,
                }}
                classNamePrefix="react-select"
                onChange={setSelectedTokenFromOption}
                defaultValue={selectedTokenFromOption}
              />
              {/* <div className={styles.arr}>
                <img src={arrDown} alt="arr" />
              </div>
              <Select
                options={selectedWalletTo.label === 'B2B' ? tokenB2bList : tokenP2pList}
                placeholder={`Выберите валюту`}
                classNames={{
                  container: (state) => styles.select3,
                  singleValue: (state) => styles.select4,
                  control: (state) => styles.select2,
                  menuList: (state) => styles.select6,
                  menu: (state) => styles.select61,
                  option: (state) => styles.select7,
                  valueContainer: (state) => styles.select5,
                }}
                classNamePrefix="react-select"
                onChange={setSelectedTokenToOption}
                defaultValue={selectedTokenToOption}
              /> */}
            </div>
            <div className={styles.popupIn__text}>Сумма</div>
            <div className={styles.popupIn__input}>
              <input
                type="text"
                placeholder={`Введите сумму`}
                value={summ}
                onChange={(e) => priceChange(e)}
                id="inp3"
                maxLength={20}
              />
            </div>
            <div className={styles.popupIn__balance}>
              Текущий баланс:<div className={styles.curVal}>{currentChartBalance ? +currentChartBalance.balance : 0} {selectedTokenFromOption.label}</div>
            </div>
            {/* <div className={styles.popupIn__items}>
              <div className={styles.popupIn__item}>
                Сумма к получению
                <div className={styles.curVal}>{`${(+summ * +rate).toString().split('.')[0]}.${(+summ * +rate).toString().split('.')[1] ? (+summ * +rate).toString().split('.')[1].slice(0, 2) : `00`}`} RUB</div>
              </div>
            </div> */}
            <div>
              {errorMessage ? error : <></>}
              {okMessage ? ok : <></>}
            </div>
            <div className={styles.btns}>
              {
                loading ?
                  <div className={styles.skeletonBlock3}>
                    <div>
                      <Skeleton height={40} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
                    </div>
                  </div>
                  :
                  <div className={currentChartBalance && summ ? styles.btnOk : styles.btnDis} onClick={() => currentChartBalance && summ ? transfer() : <></>}>
                    {`Перевести ${summ} ${selectedTokenFromOption.label.props.children[1]} ${endsList[selectedWalletFrom.value]} кошелька на ${selectedWalletTo.label} кошелёк`}
                  </div>
              }
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Transfer