import React, { useEffect, useState } from 'react';
import styles from './Offers.module.scss';
import { B2bOffer } from "./B2bOffer/B2bOffer";
import { useAppSelector } from "../../../utils/hooks/useAppSelector";
import { getCharts, getCurrencies } from "../../../bll/selectors/app.selector";
import { IB2bOffer, IOkved } from "../../../types/B2b.types";
import { Chart } from '../../profileComponents/wallet/Wallet';
import { BalanceInfo, GetPaymentsB2B, ProfileInfo } from '../../../services/UserService';
import { Payments, UserProfile, WalletBalance } from '../../../types/types';
import { ICompany } from '../../../types/Company.type';

interface OffersProps {
    offers: IB2bOffer[];
    filterSum: number;
    isLoading: boolean;
    okveds: Record<string, IOkved>;
    selectedCompany: ICompany;
    offersType: number;
    setCreatingCompany: (param: ICompany | null) => void
    setShowAddModal: (param: boolean) => void
}

export const Offers: React.FC<OffersProps> = ({ offers, filterSum, isLoading, okveds, selectedCompany, offersType, setCreatingCompany, setShowAddModal }) => {

    const [p2pChartList, setP2pChartList] = useState<Chart[]>([])

    const getBalance = async (token: string) => {
        const data = await BalanceInfo(token, 6);
        if (data.status === 200) {
            let row: WalletBalance[] = data.data;
            let tempP2pChartList: Chart[] = row.map((el) => { return { symbol: el.symbol, icon: el.icon, balance: el.balance, blocked: el.blocked } });
            setP2pChartList(tempP2pChartList);
        }
    }

    const [paymentsB2B, setPaymentsB2B] = useState<Payments[]>([])

    const getPaymentsB2B = async (token: string) => {
        const data = await GetPaymentsB2B(token)
        if (data.status === 200) {
            setPaymentsB2B(data.data)
        }
    }

    const [profile, setProfile] = useState<UserProfile | null>(null)

    const checkToken = async (token: string) => {
        const data = await ProfileInfo(token);
        if (data.status === 200) {
            setProfile(data.data)
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("access_token") ?? '';
        getBalance(token);
        getPaymentsB2B(token)
        checkToken(token)
    }, [])


    const charts = useAppSelector(getCharts);
    const currencies = useAppSelector(getCurrencies);

    const rootClassName = isLoading ? `${styles.offersWrapper} ${styles.disabled}` : styles.offersWrapper;
    const [activeOffer, setActiveOffer] = useState(0);

    // if (!profile) return;

    const offersToRender = offers.length ? offers.sort((a: IB2bOffer, b: IB2bOffer) => offersType === 2 ? a.course - b.course : b.course - a.course).map(offer =>
        <B2bOffer
            profile={profile}
            setCreatingCompany={setCreatingCompany}
            selectedCompany={selectedCompany}
            p2pChartList={p2pChartList}
            key={offer.uuid}
            offer={offer}
            filterSum={filterSum}
            charts={charts}
            currencies={currencies}
            activeOffer={activeOffer}
            setActiveOffer={setActiveOffer}
            okveds={okveds}
            paymentsB2B={paymentsB2B}
            setShowAddModal={setShowAddModal}
        />)
        : <div className={styles.notFound}>Объявления не найдены.</div>

    return (
        <>
            <div className={rootClassName}>
                {offersToRender}
            </div>
        </>
    );
};