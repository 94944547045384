import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./SecondStep.module.scss";
import Select from "react-select";
import { BankProps } from "../../filters/Filters";
import { Chart } from "../../profileComponents/wallet/Wallet";
import { IOkved } from "../../../types/B2b.types";
import cross from '../../../assets/icons/cross.svg'
import { AddPayment, AddPaymentB2b, GetPaymentsB2B } from "../../../services/UserService";
import { GetPayment } from "../../../services/P2p";
import { Payments } from "../../../types/types";
import { GetCompanyByInfo } from "../../../services/CompanyService";

interface SecondStepContentProps {
  limit: string;
  setLimit: (limit: string) => void;
  limitStart: string;
  setLimitStart: (limitStart: string) => void;
  limitEnd: string;
  setLimitEnd: (limitEnd: string) => void;
  selectedFiatOption: any;
  selectedTokenoption: any;
  selectedBankOption: any;
  setSelectedBankOption: (param: any) => void;
  selectedBankB2bOption: any;
  setSelectedBankB2bOption: (param: any) => void;
  selectedOkvedOption?: any;
  setSelectedOkvedOption?: (param: any) => void;
  setSelectedDiscountOption?: (param: any) => void;
  type: number;
  bankList: BankProps[];
  refreshPayments: () => void;
  balanceChartList: Chart[];
  rate: number;
  setIsError: (param: boolean) => void;
  okveds?: Record<string, IOkved>;
  duration: number;
  setDuration: (param: number) => void;
}

const SecondStepContent = ({
  refreshPayments,
  type,
  limit,
  setLimit,
  setLimitEnd,
  setLimitStart,
  limitEnd,
  limitStart,
  selectedFiatOption,
  selectedTokenoption,
  selectedBankOption,
  bankList,
  setSelectedBankOption,
  balanceChartList,
  rate,
  setIsError,
  okveds,
  selectedOkvedOption,
  setSelectedOkvedOption,
  setSelectedDiscountOption,
  duration,
  setDuration,
  selectedBankB2bOption,
  setSelectedBankB2bOption
}: SecondStepContentProps) => {
  const [okvedList, setOkvedList] = useState([{ label: '', id: '' }]);

  const [errorMessage, setErrorMessage] = useState('')

  const error = <div className={styles.error}>
    {errorMessage}
  </div>


  const checkValueTotal = (e: any, func: any) => {
    let prev = ''
    const pattern = new RegExp("^(\\d*)\\.{0,1}(\\d*)$")
    if (type === 1) {
      if (pattern.test(e.target.value.trim())) {
        if (e.target.value.trim().length > 15) {
          func(e.target.value.trim().slice(0, 15));
          prev = e.target.value.trim().slice(0, 15);
        } else {
          func(e.target.value.trim());
          prev = e.target.value.trim();
        }
      } else if (e.target.value.trim() === '') {
        func('');
        prev = '';
      } else {
        e.target.value = prev;
      }
    } else {
      if (pattern.test(e.target.value.trim())) {
        if (e.target.value.trim().length > 15) {
          func(e.target.value.trim().slice(0, 15));
          prev = e.target.value.trim().slice(0, 15);
        } else if (e.target.placeholder !== 'Максимум') {
          if (+e.target.value.trim() > currentBalance) {
            func(parseFloat(currentBalance.toString()).toString());
            prev = parseFloat(currentBalance.toString()).toString();
            setLimitEnd((parseFloat(currentBalance.toString()) * +rate).toString())
            setErrorMessage('Вводимое значение превышает баланс')
          } else if (+e.target.value.trim() * +rate > +limitEnd) {
            func(e.target.value.trim());
            prev = e.target.value.trim();
            let temp =
              parseFloat((+e.target.value.trim() * +rate).toString()).toString().split(".")[1] ?
                parseFloat((+e.target.value.trim() * +rate).toString()).toFixed(8) :
                parseFloat((+e.target.value.trim() * +rate).toString())
            setLimitEnd((+temp).toString())
          } else {
            func(e.target.value.trim());
            prev = e.target.value.trim();
            let temp =
              parseFloat((+e.target.value.trim() * +rate).toString()).toString().split(".")[1] ?
                parseFloat((+e.target.value.trim() * +rate).toString()).toFixed(8) :
                parseFloat((+e.target.value.trim() * +rate).toString())
            setLimitEnd((+temp).toString())
          }
        } else {
          func(e.target.value.trim());
          prev = e.target.value.trim();
        }
      } else if (e.target.value.trim() === '') {
        func('');
        prev = '';
      } else {
        e.target.value = prev;
      }
    }
  }

  useEffect(() => {
    if (+selectedFiatOption.value === 1 ? +limitStart < 500 : +limitStart < 1) {
      +selectedFiatOption.value === 1 ? setErrorMessage('Минимум 500') : setErrorMessage('Минимум 1')
    } else if (+limitEnd > ((+parseFloat(currentBalance.toString()) / 1000) > 99 ? 100000000 : (+parseFloat(currentBalance.toString()) / 1000) > 9 ? 10000000 : 1000000)) {
      if (!errorMessage) {
        setErrorMessage(`Максимум ${((+parseFloat(currentBalance.toString()) / 1000) > 99 ? 100000000 : (+parseFloat(currentBalance.toString()) / 1000) > 9 ? 10000000 : 1000000)}`)
      }
    } else if (+limitStart > +limitEnd) {
      setErrorMessage('Минимальное значение не может быть больше максимального')
    } else {
      setErrorMessage('')
    }
  }, [limitStart, limitEnd, errorMessage, selectedFiatOption.value])

  const [currentBalance, setCurrentBalance] = useState(0);
  const [currentChartName, setCurretChartName] = useState('');

  const changeMax = () => {
    let currentChartList = balanceChartList.filter(el => selectedTokenoption.label.props.children[1] === el.symbol);
    if (currentChartList.length) {
      if (+currentChartList[0].balance * +rate > +limitEnd) {
        setLimit(parseFloat((+limitEnd / +rate).toString()).toString())
        setLimitEnd((+(+limitEnd / +rate) * +rate).toString())
      } else {
        setLimit(parseFloat(currentChartList[0].balance.toString()).toString())
        setLimitEnd((+currentChartList[0].balance * +rate).toString())
      }
    } else {
      setLimit('0')
    }
  }

  useEffect(() => {
    let currentChartList = balanceChartList.filter(el => selectedTokenoption.label.props.children[1] === el.symbol);
    if (currentChartList.length) {
      setCurrentBalance(currentChartList[0].balance)
      setCurretChartName(currentChartList[0].symbol)
    }
  }, [])

  useEffect(() => {
    errorMessage ? setIsError(true) : setIsError(false)
  }, [errorMessage])

  useEffect(() => {
    if (okveds) {
      let temp = Object.values(okveds).map(({ title, id }) => { return { label: title, id: id } })
      setOkvedList(temp)
    }
  }, [okveds])

  const discountOptions = [...Array(31)].map((_, i) => i).map(value => { return { label: `${value} %`, id: value } });

  const [showAddModal, setShowAddModal] = useState(false)
  const [showAddModalB2b, setShowAddModalB2b] = useState(false)
  const [errorMessage2, setErrorMessage2] = useState('')
  const [okMessage, setOkMessage] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [telNumber, setTelNumber] = useState('')
  const [fio, setFio] = useState('')
  const [checked, setChecked] = useState(false)

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>({ label: 'SberBank', value: 32 });
  const [paymentList, setPaymentList] = useState<BankProps[]>([
    { label: 'SberBank', value: 32 }
  ])

  const getPayments = async (token: string) => {
    const data = await GetPayment(token, 1);
    if (data.status === 200 && data.data.length) {
      const temp: BankProps[] = [];
      data.data.forEach((el: any) => {
        temp.push({ label: el.name, value: el.id.toString() })
      });
      setPaymentList(temp)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    getPayments(token)
    getPaymentsB2B(token)
    getBanksB2b()
  }, [])

  const checkTelValue = (e: ChangeEvent<HTMLInputElement>) => {
    const numbers = /^[0-9 ]*$/;
    // const reg = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
    //@ts-ignore
    if ((e.nativeEvent.inputType === 'insertText' && !numbers.test(e.target.value)) || e.target.value.length > 11) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    }
    if (e.target.value.length === 11) {
      setTelNumber(e.target.value)
    } else {
      setTelNumber('')
    }
  }

  const checkValue = (e: ChangeEvent<HTMLInputElement>) => {
    const numbers = /^[0-9 ]*$/;
    const regExp = /[0-9]{4}/;

    // не позволяем ввести ничего, кроме цифр 0-9, ограничиваем размер поля 19-ю символами
    //@ts-ignore
    if ((e.nativeEvent.inputType === 'insertText' && !numbers.test(e.target.value)) || e.target.value.length > 19) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    }
    // обеспечиваем работу клавиш "backspace","delete"
    let value = e.target.value
    //@ts-ignore
    if (e.nativeEvent.inputType === "deleteContentBackward" && regExp.test(value.slice(-4))) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    }
    // добавяем пробел после 4 цифр подряд
    if (regExp.test(value.slice(-4)) && value.length < 19) {
      e.target.value += " "
    }
    if (e.target.value.length === 19) {
      setCardNumber(e.target.value)
    } else {
      setCardNumber('')
    }
  }

  useEffect(() => {
    if (selectedPaymentOption.label === 'QIWI') {
      if (telNumber && selectedPaymentOption.value && fio) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    } else {
      if (cardNumber && selectedPaymentOption.value && fio) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }
  }, [cardNumber, selectedPaymentOption.value, fio, selectedPaymentOption.label, telNumber])

  const addPayment = async () => {
    if (checked) {
      const token = localStorage.getItem("access_token") ?? '';
      const data = await AddPayment(token, +selectedPaymentOption.value, selectedPaymentOption.label === 'QIWI' ? telNumber : cardNumber, fio);
      if (data.status === 200 && data.data.success) {
        setOkMessage(data.data.message)
        setTimeout(() => {
          setOkMessage('')
          setShowAddModal(false)
          refreshPayments()
        }, 1000);
      } else {
        setErrorMessage2(data.data)
        setTimeout(() => {
          setErrorMessage2('')
        }, 1500);
      }
    }
  }

  const error2 = <div className={styles.error}>
    {errorMessage2}
  </div>

  const ok = <div className={styles.ok}>
    {okMessage}
  </div>

  const bg = <div className={styles.twoFaMain} onClick={() => { setShowAddModal(false); setShowAddModalB2b(false) }}></div>
  const addModal =
    <div className={styles.passRoot}>
      <div className={styles.topBlock}>
        <p className={styles.topBlocktitle}>Добавить способ оплаты</p>
        <img
          src={cross}
          alt=""
          className={styles.cross}
          onClick={() => setShowAddModal(false)}
        />
      </div>
      <div className={styles.paymentSelect}>
        <p className={styles.subtitle}>Банк</p>
        <Select
          options={paymentList}
          classNames={{
            control: (state) => styles.select2,
            singleValue: (state) => styles.select4,
            menuList: (state) => styles.select6,
            menu: (state) => styles.select61,
            option: (state) => styles.select7,
          }}
          classNamePrefix="react-select"
          placeholder={`Способы оплаты`}
          defaultValue={selectedPaymentOption}
          value={selectedPaymentOption}
          onChange={setSelectedPaymentOption}
        />
      </div>
      {
        selectedPaymentOption.label === 'QIWI' ?
          <div>
            <p className={styles.subtitle}>Номер телефона</p>
            <input type="tel" className={styles.number} placeholder='Введите номер телефона' onChange={(e) => checkTelValue(e)} />
          </div>
          :
          <div>
            <p className={styles.subtitle}>Номер карты</p>
            <input type="text" className={styles.number} placeholder='____ ____ ____ ____' onChange={(e) => checkValue(e)} />
          </div>
      }
      <div>
        <p className={styles.subtitle}>ФИО получателя</p>
        <input type="text" className={styles.number} placeholder='Иванов Иван Иванович' onChange={(e) => setFio(e.target.value)} />
      </div>
      {errorMessage2 ? error2 : <></>}
      {okMessage ? ok : <></>}
      <button className={checked ? styles.btnOk : styles.btnDis} onClick={addPayment}>Добавить</button>
    </div>

  const [bik, setBik] = useState('')
  const [ks, setKs] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankId, setBankId] = useState(0)

  const addPaymentB2b = async () => {
    if (rsOk && bankId && bankName && ks && bik) {
      const token = localStorage.getItem('access_token') ?? ''
      const data = await AddPaymentB2b(
        token,
        +bankId,
        rs,
        bankName,
        bik,
        ks
      )
      if (data.status === 200 && data.data.success) {
        setOkMessage(data.data.message)
        setTimeout(() => {
          setOkMessage('')
          setShowAddModalB2b(false)
          getPaymentsB2B(token)
        }, 3000)
      } else {
        setErrorMessage(data.data)
        setTimeout(() => {
          setErrorMessage('')
        }, 3000)
      }
    }
  }

  const onChangeBankHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    setBankName(e.target.value)
  }
  const [disabled, setDisabled] = useState(false)

  const onChangeBikHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const inpBank = (document.getElementById(`pr-inp-bank`) as HTMLInputElement) ?? document.createElement("input")
    const inpKs = (document.getElementById(`pr-inp-ks`) as HTMLInputElement) ?? document.createElement("input")
    if (e.target.value.length === 9) {
      const data = await GetCompanyByInfo(e.target.value.slice(1));
      if (data.data.length === 1) {
        setDisabled(true)
        setBik(e.target.value)
        setKs(data.data[0].ks)
        setBankName(data.data[0].title)
        setBankId(+data.data[0].id)
        inpBank.value = data.data[0].title
        inpKs.value = data.data[0].ks
      }
    }
  }

  const onChangeNumberHandlerKs = (e: ChangeEvent<HTMLInputElement>) => {
    if (!Number.isFinite(+e.target.value)) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    };
    if (!e.target.value) {
      setKs('')
    }
    if (e.target.value.length > 28) {
      e.target.value = e.target.value.slice(0, 28)
    }
    setKs(e.target.value)
  };

  const [rs, setRs] = useState('')

  const onChangeNumberHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!Number.isFinite(+e.target.value)) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    };
    if (!e.target.value) {
      setRs('')
    }
    if (e.target.value.length > 28) {
      e.target.value = e.target.value.slice(0, 28)
    }
    setRs(e.target.value)
  };

  const [rsOk, setRsOk] = useState(false);

  useEffect(() => {
    if (rs && (String(rs).length === 20 || String(rs).length === 28)) {
      setRsOk(true)
    } else {
      setRsOk(false)
    }
  }, [rs])

  const [currentB2bListBanks, setCurrentB2bListBanks] = useState<BankProps[]>([])

  const addModalB2b = (
    <div className={styles.passRoot}>
      <div className={styles.topBlock}>
        <p className={styles.topBlocktitle}>Добавить расчётный счёт</p>
        <img src={cross} alt='' className={styles.cross} onClick={() => setShowAddModalB2b(false)} />
      </div>
      <div>
        <p className={styles.subtitle}>Бик банка</p>
        <input
          type='text'
          className={styles.number}
          placeholder='бик'
          onChange={e => onChangeBikHandler(e)}
          disabled={disabled}
        />
      </div>
      <div className={styles.paymentSelect}>
        <p className={styles.subtitle}>Банк</p>
        <input
          type='text'
          id='pr-inp-bank'
          className={styles.number}
          placeholder='банк'
          onChange={e => onChangeBankHandler(e)}
        />
      </div>
      <div>
        <p className={styles.subtitle}>Номер расчётного счёта</p>
        <input
          type='text'
          className={styles.number}
          placeholder='р/с (20/28)'
          onChange={e => onChangeNumberHandler(e)}
        />
      </div>
      <div>
        <p className={styles.subtitle}>Номер корреспондентского счёта</p>
        <input
          id='pr-inp-ks'
          type='text'
          className={styles.number}
          placeholder='к/с (20/28)'
          onChange={e => onChangeNumberHandlerKs(e)}
          disabled={disabled}
        />
      </div>
      {errorMessage ? error : <></>}
      {okMessage ? ok : <></>}
      <button className={rsOk && bankId && bankName && ks && bik ? styles.btnOk : styles.btnDis} onClick={addPaymentB2b}>
        Добавить
      </button>
    </div>
  )

  useEffect(() => {
    if ((+parseFloat(currentBalance.toString()) / 1000) > 99) {
      setLimitEnd('100000000')
    } else if ((+parseFloat(currentBalance.toString()) / 1000) > 9) {
      setLimitEnd('10000000')
    }
  }, [currentBalance])


  const getBanksB2b = async () => {
    const data = await GetCompanyByInfo()
    if (data.status === 200) {
      const temp: BankProps[] = []
      data.data.forEach((el: any) => {
        temp.push({ label: el.title, value: el.id.toString() })
      })
      setCurrentB2bListBanks(temp)
    }
  }

  const [paymentsB2B, setPaymentsB2B] = useState<Payments[]>([])

  const getPaymentsB2B = async (token: string) => {
    const data = await GetPaymentsB2B(token)
    if (data.status === 200) {
      setPaymentsB2B(data.data)
    }
  }

  const [b2bBankList, setB2bBankList] = useState<BankProps[]>([])

  useEffect(() => {
    const temp: BankProps[] = []
    paymentsB2B.forEach((el: Payments) => {
      const name = currentB2bListBanks.filter(bank => +bank.value === +el.payment_id)[0]?.label ?? '';
      temp.push({ label: name, value: el.id.toString() })
    })
    setB2bBankList(temp)
  }, [currentB2bListBanks, paymentsB2B])

  return (
    <>
      {showAddModal || showAddModalB2b ? bg : <></>}
      {showAddModal ? addModal : <></>}
      {showAddModalB2b ? addModalB2b : <></>}
      {
        okveds ?
          <div className={styles.wrap}>
            <div className={styles.okveds}>
              <Select
                options={okvedList}
                classNames={{
                  container: (state) => styles.select3,
                  singleValue: (state) => styles.select4,
                  control: (state) => styles.select2,
                  menuList: (state) => styles.select6,
                  menu: (state) => styles.select61,
                  option: (state) => styles.select7,
                  input: (state) => styles.select1,
                  placeholder: (state) => styles.select1
                }}
                placeholder='ОКВЭД'
                onChange={setSelectedOkvedOption}
              />
            </div>
            <div className={styles.discount}>
              <Select
                options={discountOptions}
                classNames={{
                  container: (state) => styles.select3,
                  singleValue: (state) => styles.select4,
                  control: (state) => styles.select2,
                  menuList: (state) => styles.select6,
                  menu: (state) => styles.select61,
                  option: (state) => styles.select7,
                  input: (state) => styles.select1,
                  placeholder: (state) => styles.select1
                }}
                placeholder='Комиссия'
                onChange={setSelectedDiscountOption}
              />
            </div>
          </div>
          :
          <></>
      }
      <div className={styles.main}>
        <div className={styles.mini} style={okveds ? { width: '100%' } : { flex: '2' }}>
          <p className={styles.title}>Общая сумма</p>
          <div className={styles.mainInputBlock}>
            <input
              placeholder="Введите сумму"
              className={styles.mainInput}
              value={limit}
              onChange={(e) => checkValueTotal(e, setLimit)}
              style={errorMessage === 'Вводимое значение превышает баланс' ? { color: 'rgba(211, 65, 118, 1)' } : {}}
            />
            {
              type === 2 ?
                <>
                  <div className={styles.mainLabel1} onClick={changeMax}>MAX</div>
                  <div className={styles.mainLabel}>{selectedTokenoption.label}</div>
                </>
                :
                <div className={styles.mainLabel}>{selectedTokenoption.label}</div>
            }
          </div>
          {
            type === 2 ?
              <div className={styles.title2} style={{ textAlign: 'end' }}>Баланс: <span>{parseFloat(currentBalance.toString())} {selectedTokenoption.label}</span></div>
              :
              <></>
          }
          <p className={styles.title}>Лимиты</p>
          <p
            className={styles.title3}
            style={type === 2 ? { display: 'block', textAlign: "center" } : { display: "none" }}
          >
            {limit ? limit : 0} * {rate} (курс {currentChartName} / RUB) = {`${parseFloat((+limit * +rate).toString()).toString().split(".")[1] ?
              +parseFloat((+limit * +rate).toString()).toFixed(8) :
              +parseFloat((+limit * +rate).toString())
              }`}
          </p>
          <div className={styles.limits}>
            <div className={styles.inputBlock}>
              <input
                placeholder="Минимум"
                className={styles.input}
                value={limitStart}
                onChange={(e) => checkValueTotal(e, setLimitStart)}
                style={(+selectedFiatOption.value === 1 ? +limitStart < 500 : +limitStart < 1) || +limitStart > +limitEnd ? { color: 'rgba(211, 65, 118, 1)' } : {}}
              />
              <p className={styles.currency}>{selectedFiatOption.label}</p>
            </div>
            <p>~</p>
            <div className={styles.inputBlock}>
              <input
                placeholder="Максимум"
                className={styles.input}
                value={limitEnd}
                onChange={(e) => checkValueTotal(e, setLimitEnd)}
                style={+limitEnd > ((+parseFloat(currentBalance.toString()) / 1000) > 99 ? 100000000 : (+parseFloat(currentBalance.toString()) / 1000) > 9 ? 10000000 : 1000000) || +limitStart > +limitEnd ? { color: 'rgba(211, 65, 118, 1)' } : {}}
              />
              <p className={styles.currency2}>{selectedFiatOption.label}</p>
            </div>
          </div>
          <div className={styles.counterBlock}>
            <div className={styles.limitBlock}>
              <p>Мин.:</p>
              <p className={styles.counter}>{+selectedFiatOption.value === 1 ? `500` : `1`}</p>
            </div>
            <div className={styles.limitBlock}>
              <p>Макс.:</p>
              <p className={styles.counter}>{((+parseFloat(currentBalance.toString()) / 1000) > 99 ? 100000000 : (+parseFloat(currentBalance.toString()) / 1000) > 9 ? 10000000 : 1000000)}</p>
            </div>
          </div>
          {errorMessage ? error : <></>}
        </div>
        <div className={styles.mini}
        // style={okveds ? { display: 'none' } : { flex: '1' }}
        >
          {okveds && paymentsB2B.length ?
            <div>
              <p className={styles.title}>Способ оплаты</p>
              <p className={styles.subTitle}>Выберите расчётный счёт</p>
              <div style={{ margin: '10px 0' }}>
                <Select
                  isMulti
                  options={b2bBankList}
                  onChange={setSelectedBankB2bOption}
                  closeMenuOnSelect={false}
                  classNames={{
                    container: (state) => styles.select3,
                    singleValue: (state) => styles.select4,
                    control: (state) => styles.select2,
                    menuList: (state) => styles.select6,
                    menu: (state) => styles.select61,
                    option: (state) => styles.select7,
                    multiValue: (state) => styles.select8,
                    multiValueLabel: (state) => styles.select9,
                    multiValueRemove: (state) => styles.select10,
                  }}
                  classNamePrefix="react-select"
                  defaultValue={selectedBankB2bOption}
                />
              </div>
            </div>
            : okveds ?
              <div>
                <button className={styles.addPaymentBtn} onClick={() => setShowAddModalB2b(true)}>Добавить расчётный счёт</button>
              </div>
              : <></>
          }
          {
            bankList.length && !okveds ?
              <div>
                <p className={styles.title}>Способ оплаты</p>
                <p className={styles.subTitle}>Выберите метод оплаты</p>
                <div style={{ margin: '10px 0' }}>
                  <Select
                    isMulti
                    options={bankList}
                    onChange={setSelectedBankOption}
                    closeMenuOnSelect={false}
                    classNames={{
                      container: (state) => styles.select3,
                      singleValue: (state) => styles.select4,
                      control: (state) => styles.select2,
                      menuList: (state) => styles.select6,
                      menu: (state) => styles.select61,
                      option: (state) => styles.select7,
                      multiValue: (state) => styles.select8,
                      multiValueLabel: (state) => styles.select9,
                      multiValueRemove: (state) => styles.select10,
                    }}
                    classNamePrefix="react-select"
                    defaultValue={selectedBankOption}
                  />
                </div>
              </div>
              : !okveds ?
                <div>
                  <button className={styles.addPaymentBtn} onClick={() => setShowAddModal(true)}>Добавить cпособ оплаты</button>
                </div>
                : <></>
          }
          <p className={styles.title}>Срок оплаты</p>
          <div className={styles.type}>
            <div
              className={styles.typeContent}
              onClick={() => setDuration(15)}
            >
              <div className={styles.mainCircle}>
                {duration === 15 && <div className={styles.circle} />}
              </div>
              <p>15 мин.</p>
            </div>
            <div className={styles.typeContent} onClick={() => setDuration(30)}>
              <div className={styles.mainCircle}>
                {duration === 30 && <div className={styles.circle} />}
              </div>
              <p>30 мин.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SecondStepContent;
