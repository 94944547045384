import React, { useEffect, useState } from 'react'
import styles from './HistoryTransactionsTable.module.scss'
import { IWalletHistory, IWalletSwap } from '../../../../types/types';
import HistoryTransactionsTableItem from './HistoryTransactionsTableItem';
import { GetHistorySwap, GetHistoryWallet } from '../../../../services/UserService';
import * as ethers from "ethers";
import CLVSwapABI from "../../../../assets/abi/CLVSwap.json";
import { useAccount } from 'wagmi';
import axios from 'axios';
import { erc20Abi } from 'viem';
import HistorySwapTableItem from './HistorySwapTableItem';

interface HistoryTableTransactionsPropsType {
  type: number;
}

const HistoryTransactionsTable = ({
  type,
}: HistoryTableTransactionsPropsType) => {
  const [historyIn, setHistoryIn] = useState<IWalletHistory[]>([]);
  const [historyOut, setHistoryOut] = useState<IWalletHistory[]>([]);
  const [historyOutB2b, setHistoryOutB2b] = useState<IWalletHistory[]>([]);
  const [historyTransfer, setHistoryTransfer] = useState<IWalletHistory[]>([]);
  const [historySwap, setHistorySwap] = useState<IWalletSwap[]>([])
  const { address } = useAccount()

  // const address = '0xF4740c37E7949219432449040823c47346CCec62'

  const getHistoryIn = async (token: string) => {
    const data = await GetHistoryWallet(token, 11);
    if (data.status === 200) {
      setHistoryIn(data.data)
    }
  }
  const getHistoryOut = async (token: string) => {
    const data = await GetHistoryWallet(token, 10);
    if (data.status === 200) {
      setHistoryOut(data.data)
    }
  }
  const getHistoryOutB2b = async (token: string) => {
    const data = await GetHistoryWallet(token, 13);
    if (data.status === 200) {
      setHistoryOutB2b(data.data)
    }
  }
  const getHistoryTransfer = async (token: string) => {
    const data = await GetHistoryWallet(token);
    if (data.status === 200) {
      setHistoryTransfer(data.data)
    }
  }
  const getHistorySwap = async () => {
    if (!address) return
    const page = 1
    const offset = 100
    const data = (await GetHistorySwap(address, page, offset))?.result
    if (!Array.isArray(data) && !data?.length) return
    const swaps: IWalletSwap[] = data.filter(
      (e: any) => e?.from?.toLowerCase() == address?.toLowerCase() && e?.functionName == "swap(address tokenA, address tokenB, uint256 amount)")
      .map((e: any) => {
        const provider = new ethers.JsonRpcProvider("https://bsc-rpc.publicnode.com")
        const trans = provider.getTransactionReceipt(e.hash)
          .then(async (receipt) => {
            const tokenAddressA = receipt?.logs?.[0]?.address
            const tokenAddressB = receipt?.logs?.[2]?.address
            if (!(tokenAddressA && tokenAddressB)) return
            const tokenAmountA = BigInt(receipt?.logs?.[0]?.data)
            const tokenAmountB = BigInt(receipt?.logs?.[2]?.data)
            if (!(tokenAmountA && tokenAmountB)) return

            const tokenContractA = new ethers.Contract(tokenAddressA, erc20Abi, provider)
            const tokenContractB = new ethers.Contract(tokenAddressB, erc20Abi, provider)

            const parsedTokenAmountA = Number(ethers.formatUnits(tokenAmountA.toString(), await tokenContractA.decimals()))
            const parsedTokenAmountB = Number(ethers.formatUnits(tokenAmountB.toString(), await tokenContractB.decimals()))

            const symbolTokenA = await tokenContractA.symbol()
            const symbolTokenB = await tokenContractB.symbol()

            const timestamp = new Date(Number(e.timeStamp) * 1e3)
            return {
              date: `${new Date(timestamp).getDate() < 10 ? `0${new Date(timestamp).getDate()}` : new Date(timestamp).getDate()}.${(new Date(timestamp).getMonth() + 1) < 10 ? `0${new Date(timestamp).getMonth() + 1}` : new Date(timestamp).getMonth() + 1}.${new Date(timestamp).getFullYear()}`,
              start_amount: parsedTokenAmountA,
              start_symbol: symbolTokenA,
              end_amount: parsedTokenAmountB,
              end_symbol: symbolTokenB,
              txhash: receipt.blockNumber,
              status: receipt.status,
            }
          })
          .catch((error) => {
            console.error(error);
          });

        return trans

      })
    Promise.all(swaps).then(res => setHistorySwap(res.filter(el => el !== undefined)))
  }

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    getHistoryIn(token)
    getHistoryOut(token)
    getHistoryOutB2b(token)
    getHistoryTransfer(token)
    getHistorySwap()
  }, [])

  return (
    <div className={styles.main}>
      <div className={styles.filter}
        style={type === 2 ? { gridTemplateColumns: 'repeat(7, 1fr)' } : type === 4 ? { gridTemplateColumns: 'repeat(5, 1fr)' } : { gridTemplateColumns: 'repeat(6, 1fr)' }}
      >
        <p className={styles.filterItem}>ID</p>
        <p className={styles.filterItem}>{(type >= 3 && type <= 4) ? `Куда` : `Кошелёк`}</p>
        <p className={styles.filterItem} style={type === 4 ? { display: 'none' } : { display: 'block' }}>Сумма</p>
        <p className={styles.filterItem}>Дата</p>
        <p className={styles.filterItem}>{(type >= 3 && type <= 4) ? `Откуда` : `Тип операции`}</p>
        <p className={styles.filterItem}>Статус</p>
        <p className={styles.filterItem} style={type === 2 ? { display: 'block' } : { display: 'none' }}>Способ вывода</p>
      </div>
      <div className={styles.wrap}>
        {
          type === 0 ?
            historyIn.length ?
              historyIn.reverse().map((el, index) => {
                return <div key={index}>
                  <HistoryTransactionsTableItem
                    type={type}
                    historyItem={el}
                  />
                </div>
              })
              : <div className={styles.filterItem} style={{ marginTop: "10px" }}>Нет данных по вводу средств</div>
            : type === 1 ?
              historyOut.length ?
                historyOut.reverse().map((el, index) => {
                  return <div key={index}>
                    <HistoryTransactionsTableItem
                      type={type}
                      historyItem={el}
                    /></div>
                })
                : <div className={styles.filterItem} style={{ marginTop: "10px" }}>Нет данных по выводу средств</div>
              : type === 2 ?
                historyOutB2b.length ?
                  historyOutB2b.reverse().map((el, index) => {
                    return <div key={index}>
                      <HistoryTransactionsTableItem
                        type={type}
                        historyItem={el}
                      /></div>
                  })
                  : <div className={styles.filterItem} style={{ marginTop: "10px" }}>Нет данных по выводу средств</div>
                : type === 3 ?
                  historyTransfer.length ?
                    historyTransfer.reverse().map((el, index) => {
                      return <div key={index}>
                        <HistoryTransactionsTableItem
                          type={type}
                          historyItem={el}
                        /></div>
                    })
                    : <div className={styles.filterItem} style={{ marginTop: "10px" }}>Нет данных по переводам</div>
                  : type === 4 ?
                    historySwap.length ?
                      historySwap.reverse().map((el, index) => {
                        return <div key={index}>
                          <HistorySwapTableItem
                            // type={type}
                            historyItem={el}
                          /></div>
                      })
                      : <div className={styles.filterItem} style={{ marginTop: "10px" }}>Нет данных по обменам</div>
                    : <div className={styles.filterItem} style={{ marginTop: "10px" }}>Нет данных</div>
        }
      </div>
    </div>
  )
}

export default HistoryTransactionsTable