import React, { useCallback, useEffect, useState } from 'react'
import styles from './Payments.module.scss'
import Container from '../../UI/container/Container'
import QRCode from "react-qr-code";
import copy from '../../assets/icons/copy.svg'
import ok from '../../assets/icons/copy-ok.svg'
import Countdown from 'react-countdown';
import { PaymentStatusMethod } from '../../services/PayService';
import { IPaymentData } from '../../types/types';
import Skeleton from 'react-loading-skeleton';

const Payment = () => {
  const [paymentData, setPaymentData] = useState<IPaymentData | null>(null)

  const paymentId = window.location.pathname.split('/payment/')[1]

  const getStatus = useCallback(async () => {
    const token = localStorage.getItem("access_token") ?? "";
    const data = await PaymentStatusMethod(token, +paymentId)
    // console.log(data);
    if (data.status === 200) {
      setPaymentData(data.data)
    }

  }, [paymentId])

  useEffect(() => {
    const id = setInterval(() => {
      getStatus()
    }, 6000);

    return () => {
      clearInterval(id);
    };
  }, [getStatus])

  useEffect(() => {
    getStatus()
  }, [getStatus])

  // const [min, setMin] = useState(20)
  // const [sec, setSec] = useState(0)

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    // setMin(minutes)
    // setSec(seconds)
    if (completed) {
      return (<span>00:00</span>);
    } else {
      return (
        <span>{minutes.toString().length === 1 ? '0' : ''}{minutes}:{seconds.toString().length === 1 ? '0' : ''}{seconds}</span>
      );
    }
  };

  const [isCopyAmount, setIsCopyAmount] = useState(false)
  const [isCopyAdres, setIsCopyAdres] = useState(false)

  const copyAmount = (amount: number) => {
    navigator.clipboard.writeText(amount.toString()).then(() => {
      setIsCopyAdres(false)
      setIsCopyAmount(true)
    }, (err) => {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  const copyAdres = (adres: string) => {
    navigator.clipboard.writeText(adres.toString()).then(() => {
      setIsCopyAmount(false)
      setIsCopyAdres(true)
    }, (err) => {
      console.error('Произошла ошибка при копировании текста: ', err);
    });
  }

  const roundToFixed = (number: number, decimalPlaces: number) => {
    const multiplier = 10 ** decimalPlaces;
    return Math.ceil(number * multiplier) / multiplier;
  }

  // status info

  //   waiting - waiting for the customer to send the payment. The initial status of each payment.
  // confirming - the transaction is being processed on the blockchain. Appears when NOWPayments detect the funds from the user on the blockchain.
  // confirmed - the process is confirmed by the blockchain. Customer’s funds have accumulated enough confirmations.
  // sending - the funds are being sent to your personal wallet. We are in the process of sending the funds to you.
  // partially_paid - it shows that the customer sent the less than the actual price. Appears when the funds have arrived in your wallet.
  // finished - the funds have reached your personal address and the payment is finished.
  // failed - the payment wasn't completed due to the error of some kind.
  // refunded - the funds were refunded back to the user.
  // expired - the user didn't send the funds to the specified address in the 24 hour time window.
  const currentStatus = {
    "waiting": "Ожидание платежа",
    "confirming": "Транзакция обрабатывается",
    "confirmed": "Транзакция подтверждена",
    "sending": "Средства отправляются",
    "partially_paid": "Частично оплачено",
    "finished": "Платеж завершен",
    "failed": "Ошибка платежа",
    "refunded": "Средства возвращены",
    "expired": "Срок на ввод истёк",
  }
  // status info

  return (
    <Container className={styles.container}>
      {
        paymentData ?
          <div className={styles.paymentContainer}>
            <div className={styles.head}>
              <p>Статус:</p>
              <p>{currentStatus[(paymentData.payment_status as keyof typeof currentStatus)]}</p>
            </div>
            <div className={styles.timerBox}>
              <p>Время на ввод:</p>
              <Countdown
                date={new Date(paymentData.created_at).getTime() + (20 * 60 * 1000)}
                renderer={renderer}
              />
            </div>
            <div className={styles.main}>
              <div className={styles.qrSide}>
                <QRCode value={paymentData.pay_address} size={180} />
              </div>
              <div className={styles.infoSide}>
                <div className={styles.infoBox}>
                  <div className={styles.top}>
                    <p>Сумма</p>
                    <p>~ USD {paymentData.price_amount}</p>
                  </div>
                  <div className={styles.bottom}>
                    <p className={styles.value}>{roundToFixed(paymentData.pay_amount, 4)} {'USDT'}</p>
                    {
                      isCopyAmount ?
                        <img className={styles.ok} src={ok} alt='ok' />
                        :
                        <img className={styles.copy} src={copy} alt='copy' onClick={() => copyAmount(roundToFixed(paymentData.pay_amount, 4))} />
                    }
                  </div>
                </div>
                <div className={styles.infoBox}>
                  <div className={styles.top}>
                    <p>Адрес</p>
                  </div>
                  <div className={styles.bottom}>
                    <p className={styles.value}>{paymentData.pay_address}</p>
                    {
                      isCopyAdres ?
                        <img className={styles.ok} src={ok} alt='ok' />
                        :
                        <img className={styles.copy} src={copy} alt='copy' onClick={() => copyAdres(paymentData.pay_address)} />
                    }
                  </div>
                </div>
                <div className={styles.infoBox}>
                  <div className={styles.top}>
                    <p>Сеть</p>
                  </div>
                  <div className={styles.bottom}>
                    <p className={styles.value}>{paymentData.pay_currency.toUpperCase()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <Container className={styles.skeletonContainer}>
            <div className={styles.skeletonBlock1}>
              <div style={{ marginBottom: '10px' }}>
                <Skeleton height={250} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
              </div>
              <div>
                <Skeleton height={350} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
              </div>
            </div>
            <div className={styles.skeletonBlock2}>
              <div>
                <Skeleton height={615} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
              </div>
            </div>
          </Container>
      }
    </Container>
  )
}

export default Payment