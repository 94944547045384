import React, { useEffect, useState } from 'react'
import styles from './Help.module.scss'
import i11 from '../../assets/img/help/1/1.png'
import i12 from '../../assets/img/help/1/2.png'
import i13 from '../../assets/img/help/1/3.png'
import i21 from '../../assets/img/help/2/1.png'
import i22 from '../../assets/img/help/2/2.png'
import i23 from '../../assets/img/help/2/3.png'
import i31 from '../../assets/img/help/3/1.png'
import i32 from '../../assets/img/help/3/2.png'
import i41 from '../../assets/img/help/4/1.png'
import i42 from '../../assets/img/help/4/2.png'
import i43 from '../../assets/img/help/4/3.png'
import i44 from '../../assets/img/help/4/4.png'
import i45 from '../../assets/img/help/4/5.png'
import i51 from '../../assets/img/help/5/1.png'
import i52 from '../../assets/img/help/5/2.png'
import i53 from '../../assets/img/help/5/3.png'
import i54 from '../../assets/img/help/5/4.png'
import i61 from '../../assets/img/help/6/1.png'
import i62 from '../../assets/img/help/6/2.png'
import i63 from '../../assets/img/help/6/3.png'
import i64 from '../../assets/img/help/6/4.png'
import i65 from '../../assets/img/help/6/5.png'
import i71 from '../../assets/img/help/7/1.png'
import cir from '../../assets/img/help/cir.png'
import cirA from '../../assets/img/help/cirA.png'
import Container from '../../UI/container/Container'


const Help = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [precent, setPrecent] = useState(0);
  const [docHeight, setDocHeight] = useState(0);

  useEffect(() => {
    // const h = document.getElementsByTagName('html')[0].offsetHeight;
    // console.log(h);
    setDocHeight(13110)
  }, [])

  useEffect(() => {
    const handleScroll = (event: any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const prec = (scrollTop * 100) / docHeight;
    setPrecent(prec)
  }, [docHeight, scrollTop])

  return (
    <div className={styles.helpContainer}>
      <Container>
        <div className={styles.main}>
          <div className={styles.title}>Руководство по торговле</div>
          <div className={styles.bg}>
            <div className={styles.mainBlock}>
              <div className={styles.textT}>
                Испытываете трудности в отношении криптовалют? Присоединяйтесь к DCX-P2P и торгуйте за считанные минуты! Просто выполните следующие действия: создайте учетную запись, выберите способ покупки и начните торговать. В нашем руководстве есть вся информация для работы с GREEnavi-P2P
              </div>
              <div className={styles.list}>
                <a href="#1"><div className={styles.subtitleT}>1. Регистрация и авторизация</div></a>
                <a href="#2"><div className={styles.subtitleT}>2. Заполнение профиля</div></a>
                <a href="#3"><div className={styles.subtitleT}>3. Прохождение верификации</div></a>
                <a href="#4"><div className={styles.subtitleT}>4. Покупка криптовалюты</div></a>
                <a href="#5"><div className={styles.subtitleT}>5. Продажа криптовалюты</div></a>
                <a href="#6"><div className={styles.subtitleT}>6. Размещение объявления</div></a>
                <a href="#7"><div className={styles.subtitleT}>7. Апелляции и споры</div></a>
                <a href="#8"><div className={styles.subtitleT}>8. Дополнительные рекомендации</div></a>
              </div>
            </div>
          </div>
          <div className={styles.flexBlock}>
            <div className={styles.textSide} id='toH'>
              <div className={styles.block}>
                <div className={styles.subtitle} id='1'>Регистрация и авторизация</div>
                <div className={styles.text}>
                  Чтобы использовать платформу GREEnavi, пользователи должны создать учетную запись. Для этого нужно в верхнем меню нажать кнопку «Регистрация» и в открывшемся модальном окне ввести логин, ввести адрес электронной почты и придумать пароль.
                </div>
                <img src={i11} alt="" />
                <div className={styles.text}>
                  После регистрации на введенную электронную почту придет код подтверждения, который необходимо ввести в модальное окно. Оно откроется сразу после нажатия кнопки «Регистрация».
                </div>
                <img src={i12} alt="" />
                <div className={styles.text}>
                  Авторизоваться в системе можно нажав кнопку «Войти». В модельном окне пользователю следует ввести логин и пароль.
                </div>
                <img src={i13} alt="" />
              </div>
              <div className={styles.block}>
                <div className={styles.subtitle} id='2'>Заполнение профиля</div>
                <div className={styles.text}>
                  Для торговли на платформе требуется привязать хотя бы 1 метод оплаты. Вы можете привязать до 20 методов оплаты и использовать до 5-ти в одной сделке.
                </div>
                <img src={i21} alt="" />
                <div className={styles.text}>
                  В открывшемся окне пользователю нужно выбрать банк и заполнить номер карты или счета, с которого будет вестись расчет или приходить оплата.
                </div>
                <img src={i22} alt="" />
                <div className={styles.text}>
                  В случае отсутствия статуса «подтвержденный пользователь» (KYC процедура), вы сможете только откликаться на объявления для покупки криптовалюты. Чтобы подтвердить профиль пользователю следует перейти нa страницу «Безопасность» и добавить Google Authenticator (2FA).
                </div>
                <img src={i23} alt="" />
              </div>
              <div className={styles.block}>
                <div className={styles.subtitle} id='3'>Прохождение верификации</div>
                <div className={styles.text}>
                  Вам необходимо пройти верификацию личности в вашем аккаунте GREEnavi для снятия ограничений на ввод и вывод средств, а также для торговли. Обычно этот процесс занимает несколько минут. Верификация требует основной информации о вас и удостоверения личности, а также прохождения верификации лица.
                </div>
                <div className={styles.text}>
                  Пожалуйста, помните о безопасности вашего аккаунта GREEnavi. Мы прилагаем все усилия для его защиты, но вы также можете существенно повысить безопасность своего аккаунта.
                </div>
                <img src={i31} alt="" />
                <div className={styles.text}>
                  После прохождения верификации вы сможете настроить свой аккаунт GREEnavi.
                </div>
                <img src={i32} alt="" />
                <div className={styles.text}>
                  Это сделает вас на шаг ближе к тому, чтобы начать покупать криптовалюту. Сейчас мы подробно рассмотрим весь процесс!
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.subtitle} id='4'>Покупка криптовалюты</div>
                <div className={styles.text}>
                  Самый простой вариант для новых пользователей.  Выберите криптовалюту, которую вы планируете купить.  Вы также можете применить фильтр по различным параметрам: сумма, способ оплаты.
                </div>
                <img src={i41} alt="" />
                <div className={styles.text}>
                  В разделе «Покупка» выбрать подходящее по сумме и цене объявление и нажать на кнопку «купить»
                </div>
                <img src={i42} alt="" />
                <div className={styles.text}>
                  Далее откроется дополнительное окно, где от вас требуется ввести сумму сделки.
                </div>
                <img src={i43} alt="" />
                <div className={styles.text}>
                  На следующей странице вы сможете выбрать один из доступных методов оплаты, после чего потребуется выполнить перевод на реквизиты продавца. Будьте внимательны при переводе, платформа не несет ответственность за некорректный перевод средств.
                </div>
                <img src={i44} alt="" />
                <div className={styles.text}>
                  После совершения перевода, подтвердите совершение оплаты, нажав на соответствующую кнопку.
                </div>
                <img src={i45} alt="" />
              </div>
              <div className={styles.block}>
                <div className={styles.subtitle} id='5'>Продажа криптовалюты</div>
                <div className={styles.text}>
                  У продавца есть 15 минут на подтверждение получения платежа и отправку криптовалюты. Если продавец не успеет за 15 минут, вы в праве открыть апелляцию по сделке.
                </div>
                <img src={i51} alt="" />
                <div className={styles.text}>
                  В разделе «Продажа» выбрать подходящее по сумме и цене объявление и нажать на кнопку «продать».
                </div>
                <img src={i52} alt="" />
                <div className={styles.text}>
                  Далее откроется дополнительное окно, где от вас требуется ввести сумму сделки и выбрать способ получения фиата.
                </div>
                <img src={i53} alt="" />
                <div className={styles.text}>
                  На следующей странице от вас требуется ожидать поступления оплаты по выбранному способу получения. У покупателя есть 15 минут на совершение перевода. Если покупатель не успеет перевести платеж, вы можете отменить ордер, либо открыть апелляцию. После получения оплаты, нажмите на кнопку «Подтвердить получение оплаты», затем криптовалюта будет автоматически отправлена покупателю. Будьте внимательны, проверьте сумму, поступившую на ваш счет, прежде, чем подтверждать получение.
                </div>
                <img src={i54} alt="" className={styles.extra} />
              </div>
              <div className={styles.block}>
                <div className={styles.subtitle} id='6'>Размещение объявления</div>
                <div className={styles.text}>
                  Для размещения объявления требуется нажать на кнопку «Новое объявление».
                </div>
                <img src={i61} alt="" />
                <div className={styles.text}>
                  На открывшейся странице вы можете выбрать тип объявления (Покупка/Продажа), цену за актив, затем сумму сделки и методы оплаты.
                </div>
                <img src={i62} alt="" />
                <div className={styles.text}>
                  Заполните поля и введите данные для оплаты.
                </div>
                <img src={i63} alt="" />
                <div className={styles.text}>
                  На последней странице вы можете добавить дополнительные условия сделки в свободной форме. Будьте аккуратны, любая оскорбительная/нецензурная речь ведет к блокировке аккаунта.
                </div>
                <img src={i64} alt="" className={styles.extra} />
                <div className={styles.text}>
                  Затем нажмите на кнопку «создать объявление» и оно будет опубликовано среди всех объявлений на платформе. Вы сможете его отслеживать во вкладке «Мои сделки», либо в истории личного кабинета.
                </div>
                {/* <img src={i65} alt="" /> */}
              </div>
              <div className={styles.block}>
                <div className={styles.subtitle} id='7'>Апелляции и споры</div>
                <div className={styles.text}>
                  Апелляцию возможно открыть после истечения срока таймера в открытой сделке (15 минут).
                </div>
                <img src={i71} alt="" className={styles.extra} />
                <div className={styles.text}>
                  При возникновении споров и претензий любой пользователь имеет право открыть апелляцию, чтобы администрация сервера помогла в решении любых затруднений при проведении сделки. Если покупатель совершил оплату, а продавец не перевел криптовалюту, платформа имеет полное право сделать это самостоятельно за продавца, используя замороженный под сделку актив
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.subtitle} id='8'>Дополнительные рекомендации</div>
                <div className={styles.text}>
                  При совершении любых операций на платформе будьте бдительны и проверяйте любую информацию, которую используете. В большинстве случаев администрация сервиса всегда поможет в решении любых претензий и споров, однако, в случае, если был совершен перевод некорректной суммы, администрация не сможет вернуть ваши средства. Не отправляйте оплату несколькими платежами, это строго запрещено правилами платформы. Совершайте перевод строго по доступным реквизитам, включая конкретную платежную форму/банк. Если по доступным реквизитам совершить перевод не удается, не пытайтесь совершить его другими способами. (Включая передачу альтернативных реквизитов в чате).
                </div>
              </div>
            </div>
            <div className={styles.fixSide}>
              <div className={styles.fixMenu}>
                <div className={styles.line}></div>
                <div className={styles.liBlock}>
                  <div className={styles.li}>
                    <img src={precent >= 4.5 && precent < 19.4 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#1"><div className={precent >= 4.5 && precent < 19.4 ? styles.liTextA : styles.liText}>Регистрация и авторизация</div></a>
                  </div>
                  <div className={styles.li}>
                    <img src={precent >= 19.4 && precent < 32.9 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#2"><div className={precent >= 19.4 && precent < 32.9 ? styles.liTextA : styles.liText}>Заполнение профиля</div></a>
                  </div>
                  <div className={styles.li}>
                    <img src={precent >= 32.9 && precent < 44.1 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#3"><div className={precent >= 32.9 && precent < 44.1 ? styles.liTextA : styles.liText}>Прохождение верификации</div></a>
                  </div>
                  <div className={styles.li}>
                    <img src={precent >= 44.1 && precent < 70.5 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#4"><div className={precent >= 44.1 && precent < 70.5 ? styles.liTextA : styles.liText}>Покупка криптовалюты</div></a>
                  </div>
                  <div className={styles.li}>
                    <img src={precent >= 70.5 && precent < 92.3 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#5"><div className={precent >= 70.5 && precent < 92.3 ? styles.liTextA : styles.liText}>Продажа криптовалюты</div></a>
                  </div>
                  <div className={styles.li}>
                    <img src={precent >= 92.3 && precent < 111.5 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#6"><div className={precent >= 92.3 && precent < 111.5 ? styles.liTextA : styles.liText}>Размещение объявления</div></a>
                  </div>
                  <div className={styles.li}>
                    <img src={precent >= 111.5 && precent < 115 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#7"><div className={precent >= 111.5 && precent < 115 ? styles.liTextA : styles.liText}>Апелляции и споры</div></a>
                  </div>
                  <div className={styles.li}>
                    <img src={precent >= 115 && precent <= 1000 ? cirA : cir} alt="" className={styles.cir} />
                    <a href="#8"><div className={precent >= 115 && precent <= 1000 ? styles.liTextA : styles.liText}>Дополнительные рекомендации</div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Help