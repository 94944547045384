import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from "./bll/store";
import { Web3Provider } from './providers/Web3Provider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Web3Provider>
                <Provider store={store}>
                    <App />
                </Provider>
            </Web3Provider>
        </BrowserRouter>
    </React.StrictMode>
);

