import React, { useState } from 'react'
import styles from './HistoryTransactionsTable.module.scss'
import { IWalletSwap } from '../../../../types/types'
import { ReactComponent as RollUp } from "../../../../assets/icons/profile/balance/Button.svg";

interface HistorySwapTableItemProps {
  historyItem: IWalletSwap
}

const HistorySwapTableItem = ({
  historyItem
}: HistorySwapTableItemProps) => {
  console.log(historyItem);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div className={styles.blockItem}>
        <div
          onClick={() => setIsVisible((prev) => !prev)}
          className={styles.blockItemTop}
        >
          <div className={styles.item}>
            {historyItem.txhash}
          </div>
          <div className={styles.blockItemTopCenter}>
          </div>
          <div>
            <RollUp className={`${isVisible && styles.blockItemBtn}`} />
          </div>
        </div>
        <div
          className={`${styles.blockItemBody} ${isVisible && styles.blockItemBodyVisible
            }`}
        >
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Сумма</span>
            {historyItem.start_amount} {historyItem.start_symbol}
          </div>
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Дата</span>
            {historyItem.date}
          </div>
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Откуда</span>
            {historyItem.start_symbol}
          </div>
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Куда</span>
            {historyItem.end_symbol}
          </div>
          <div className={styles.blockItemBodyRow}>
            <span className={styles.blockItemBodyRowLeft}>Статус</span>
            <span className={styles.blockItemBodyRowRight}>
              {historyItem.status === 1 ? 'Успешно' : 'Ошибка'}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.itemBlock}
        style={{ gridTemplateColumns: 'repeat(5, 1fr)' }}
      >
        <div className={styles.item}>
          {historyItem.txhash}
        </div>
        <div className={styles.item}>
          {historyItem.end_amount} {historyItem.end_symbol}
        </div>
        {/* <p className={styles.item}>
          {historyItem.start_amount} {historyItem.start_symbol}
          ({historyItem.end_amount} {historyItem.end_symbol})
        </p> */}
        <p className={styles.item}>
          {historyItem.date}
        </p>
        <p className={styles.item}>
          {historyItem.start_amount} {historyItem.start_symbol}
        </p>
        <p className={styles.item}>
          {historyItem.status === 1 ? 'Успешно' : 'Ошибка'}
        </p>
      </div>
    </>
  )
}

export default HistorySwapTableItem