import React, { useCallback, useEffect, useState } from 'react'
import styles from './MoveIn.module.scss'
import { ReactComponent as Cross } from '../../../../assets/icons/cross.svg'
import Select from 'react-select'
import { TokenProps } from '../../../filters/Filters'
import { GetChainList, PaymentStatusMethod, moveInMethod } from '../../../../services/PayService'
import { IPaymentData } from '../../../../types/types'
import QRCode from "react-qr-code";
import copy from '../../../../assets/icons/copy.svg'
import ok from '../../../../assets/icons/copy-ok.svg'
import Countdown from 'react-countdown';
import trc20 from '../../../../assets/icons/coins/trc20.svg'
import erc20 from '../../../../assets/icons/coins/erc20.svg'

interface MoveInModalProps {
	isOpenModal: boolean
	setIsOpenModal: (isOpenModal: boolean) => void
	tokenList: TokenProps[]
	selectedTokenOption: any
	setSelectedTokenOption: (param: any) => void
}

const MoveIn = ({ isOpenModal, setIsOpenModal, tokenList, selectedTokenOption, setSelectedTokenOption }: MoveInModalProps) => {
	const [summ, setSumm] = useState('10')
	const [errorMessage, setErrorMessage] = useState('')

	const error = <div className={styles.error}>{errorMessage}</div>

	let prev = ''
	const priceChange = (e: any) => {
		const pattern = new RegExp('^(\\d*)\\.{0,1}(\\d*)$')
		if (pattern.test(e.target.value.trim())) {
			if (e.target.value.trim().length > 15) {
				setSumm(e.target.value.trim().slice(0, 15))
				prev = e.target.value.trim().slice(0, 15)
			} else {
				setSumm(e.target.value.trim())
				prev = e.target.value.trim()
			}
		} else if (e.target.value.trim() === '') {
			setSumm('')
			prev = ''
		} else {
			e.target.value = prev
		}
	}

	const [chainList, setChainList] = useState<any>([
		{ label: <div className={styles.fiatBox}><img src={erc20} alt="" className={styles.fiat} />ERC20</div>, value: 54 },
		{ label: <div className={styles.fiatBox}><img src={trc20} alt="" className={styles.fiat} />TRC20</div>, value: 56 },
	])
	const [selectedChainOption, setSelectedChainOption] = useState<any>({ label: 'ERC20', value: 54 })

	const [loading, setLoading] = useState(false)

	const moveInRequest = async () => {
		if (+summ) {
			setLoading(true)
			const token = localStorage.getItem('access_token') ?? ''
			const data = await moveInMethod(token, +summ, +selectedTokenOption.value, selectedChainOption?.value ? +selectedChainOption.value : 0)
			if (data.status === 200 && data.data.success) {
				// window.location.href = data.data.invoice_url
				// window.open(`/payment/${data.data[0].detail.payment_id}`, '_blank')
				// window.location.href = `/payment/${data.data[0].detail.payment_id}`
				setPaymentCreated(true)
				setPaymentId(+data.data[0].detail.payment_id)
				localStorage.setItem("payment_id", data.data[0].detail.payment_id.toString())
				localStorage.setItem("payment_created", data.data[0].detail.created_at.toString())
				if (paymentData && (paymentCreated || paymentId) && paymentData.pay_address) {
					getStatus()
				}
				// setIsOpenModal(false)
			} else {
				setErrorMessage('Что-то пошло не так..')
				setTimeout(() => {
					setErrorMessage('')
					setLoading(false)
				}, 2000)
			}
		} else {
			setErrorMessage('Введите сумму')
			setTimeout(() => {
				setErrorMessage('')
				setLoading(false)
			}, 2000)
		}
	}

	const jivoShow = () => {
		let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div')
			; (jivo as HTMLDivElement).style.display = 'block'
		let body = document.getElementsByTagName('body')[0]
			; (body as HTMLBodyElement).style.overflow = 'unset'
	}

	const getChains = useCallback(async () => {
		const token = localStorage.getItem('access_token') ?? ''
		const data = await GetChainList(token, +selectedTokenOption.value)
		if (data.status === 200) {
			let temp = data.data.map((el: any) => {
				// return { label: el.name.toUpperCase(), value: el.id }
				let newName = (el.name as string).startsWith('usdt') ? el.name.slice(4) : el.name
				return {
					label: <div className={styles.fiatBox}>
						<img src={el.name === 'usdterc20' ? erc20 : el.name === 'usdttrc20' ? trc20 : ''} alt="" className={styles.fiat} />
						{newName.toUpperCase()}</div>, value: el.id
				}
			})
			setChainList(temp)
			setSelectedChainOption(temp[0])
		}
	}, [selectedTokenOption.value])

	useEffect(() => {
		getChains()
	}, [getChains])

	const [paymentId, setPaymentId] = useState(0)
	const [paymentCreated, setPaymentCreated] = useState(false)
	const [paymentData, setPaymentData] = useState<IPaymentData | null>(null)

	const getStatus = useCallback(async () => {
		const token = localStorage.getItem("access_token") ?? "";
		const data = await PaymentStatusMethod(token, +paymentId)
		if (data.status === 200 && !data.data.detail) {
			setPaymentData(data.data)
			setLoading(false)
		} else {
			getStatus()
		}

	}, [paymentId])

	useEffect(() => {
		if (paymentCreated || paymentId) {
			const id = setInterval(() => {
				getStatus()
			}, 6000);

			return () => {
				clearInterval(id);
			};
		}
	}, [getStatus, paymentCreated, paymentId])

	useEffect(() => {
		(paymentCreated || paymentId) ? getStatus() : <></>
	}, [getStatus, paymentCreated, paymentId])

	// const [min, setMin] = useState(20)
	// const [sec, setSec] = useState(0)

	useEffect(() => {
		const id = localStorage.getItem("payment_id");
		const created = localStorage.getItem("payment_created") ?? '';
		if (id && (((Date.now() - new Date(created).getTime()) / 1000 / 60) < 20)) {
			setPaymentId(+id)
		}
	}, [])

	const renderer = ({ hours, minutes, seconds, completed }: any) => {
		// setMin(minutes)
		// setSec(seconds)
		if (completed) {
			return (<span>00:00</span>);
		} else {
			return (
				<span>{minutes.toString().length === 1 ? '0' : ''}{minutes}:{seconds.toString().length === 1 ? '0' : ''}{seconds}</span>
			);
		}
	};

	const [isCopyAmount, setIsCopyAmount] = useState(false)
	const [isCopyAdres, setIsCopyAdres] = useState(false)

	const copyAmount = (amount: number) => {
		navigator.clipboard.writeText(amount.toString()).then(() => {
			setIsCopyAdres(false)
			setIsCopyAmount(true)
		}, (err) => {
			console.error('Произошла ошибка при копировании текста: ', err);
		});
	}

	const copyAdres = (adres: string) => {
		navigator.clipboard.writeText(adres.toString()).then(() => {
			setIsCopyAmount(false)
			setIsCopyAdres(true)
		}, (err) => {
			console.error('Произошла ошибка при копировании текста: ', err);
		});
	}

	const roundToFixed = (number: number, decimalPlaces: number) => {
		const multiplier = 10 ** decimalPlaces;
		return Math.ceil(number * multiplier) / multiplier;
	}

	// status info

	//   waiting - waiting for the customer to send the payment. The initial status of each payment.
	// confirming - the transaction is being processed on the blockchain. Appears when NOWPayments detect the funds from the user on the blockchain.
	// confirmed - the process is confirmed by the blockchain. Customer’s funds have accumulated enough confirmations.
	// sending - the funds are being sent to your personal wallet. We are in the process of sending the funds to you.
	// partially_paid - it shows that the customer sent the less than the actual price. Appears when the funds have arrived in your wallet.
	// finished - the funds have reached your personal address and the payment is finished.
	// failed - the payment wasn't completed due to the error of some kind.
	// refunded - the funds were refunded back to the user.
	// expired - the user didn't send the funds to the specified address in the 24 hour time window.
	const currentStatus = {
		"waiting": "Ожидание платежа",
		"confirming": "Транзакция обрабатывается",
		"confirmed": "Транзакция подтверждена",
		"sending": "Средства отправляются",
		"partially_paid": "Частично оплачено",
		"finished": "Платеж завершен",
		"failed": "Ошибка платежа",
		"refunded": "Средства возвращены",
		"expired": "Срок на ввод истёк",
	}
	// status info

	const finishPayment = () => {
		setIsOpenModal(false)
		localStorage.removeItem("payment_id")
		localStorage.removeItem("payment_created")
		setPaymentId(0)
		setPaymentCreated(false)
		jivoShow()
	}

	return (
		<>
			{isOpenModal && (
				paymentData && (paymentCreated || paymentId) ?
					<>
						<div
							className={styles.popupIn}
							onClick={() => {
								setIsOpenModal(false)
								jivoShow()
							}}
						></div>
						<div className={styles.popupIn__block} onClick={e => e.stopPropagation()}>
							<div className={styles.popupIn__title}>Ввод</div>
							<Cross
								className={styles.popupIn__close}
								onClick={() => {
									setIsOpenModal(false)
									localStorage.removeItem("payment_id")
									localStorage.removeItem("payment_created")
									setPaymentId(0)
									setPaymentCreated(false)
									jivoShow()
								}}
							/>
							<div className={styles.paymentContainer}>
								<div className={styles.head}>
									<p>Статус:</p>
									<button
										className={paymentData.payment_status === 'finished' ? styles.statusBtnOk : styles.statusBtn}
										onClick={() => paymentData.payment_status === 'finished' ? finishPayment() : <></>}
									>
										{currentStatus[(paymentData.payment_status as keyof typeof currentStatus)]}
									</button>
								</div>
								<div className={styles.timerBox}>
									<p>ID:</p>
									<p>{paymentData.order_id}</p>
								</div>
								<div className={styles.timerBox} style={paymentData.payment_status !== 'waiting' ? { display: 'none' } : { display: 'flex' }}>
									<p>Время на ввод:</p>
									<Countdown
										date={new Date(paymentData.created_at).getTime() + (20 * 60 * 1000)}
										renderer={renderer}
									/>
								</div>
								<div className={styles.main}>
									<div className={styles.qrSide}>
										<QRCode value={paymentData.pay_address ?? ''} size={180} />
									</div>
									<div className={styles.infoSide}>
										<div className={styles.infoBox}>
											<div className={styles.top}>
												<p>Сумма</p>
												<p>~ USD {paymentData.price_amount}</p>
											</div>
											<div className={styles.bottom}>
												<p className={styles.value}>{roundToFixed(paymentData.pay_amount, 4)} {'USDT'}</p>
												{
													isCopyAmount ?
														<img className={styles.ok} src={ok} alt='ok' />
														:
														<img className={styles.copy} src={copy} alt='copy' onClick={() => copyAmount(roundToFixed(paymentData.pay_amount, 4))} />
												}
											</div>
										</div>
										<div className={styles.infoBox}>
											<div className={styles.top}>
												<p>Адрес</p>
											</div>
											<div className={styles.bottom}>
												<p className={styles.value}>{paymentData.pay_address}</p>
												{
													isCopyAdres ?
														<img className={styles.ok} src={ok} alt='ok' />
														:
														<img className={styles.copy} src={copy} alt='copy' onClick={() => copyAdres(paymentData.pay_address)} />
												}
											</div>
										</div>
										<div className={styles.infoBox}>
											<div className={styles.top}>
												<p>Сеть</p>
											</div>
											<div className={styles.bottom}>
												<p className={styles.value}>
													{paymentData.pay_currency?.toUpperCase() === 'USDTERC20' ? 'ERC20' : paymentData.pay_currency?.toUpperCase() === 'USDTTRC20' ? 'TRC20' : paymentData.pay_currency?.toUpperCase()}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
					:
					<>
						<div
							className={styles.popupIn}
							onClick={() => {
								setIsOpenModal(false)
								jivoShow()
							}}
						></div>
						<div className={styles.popupIn__block} onClick={e => e.stopPropagation()}>
							<div className={styles.popupIn__title}>Ввод</div>
							<Cross
								className={styles.popupIn__close}
								onClick={() => {
									setIsOpenModal(false)
									jivoShow()
								}}
							/>
							<div className={styles.popupIn__warning}>
								Минимальная сумма для ввода:
								<div className={styles.curValOr}>{10} USD</div>
							</div>
							<div className={styles.popupIn__info}>
								Ввод криптовалюты USDT осуществляется только в сетях (TRC20) или (ERC20)
							</div>
							<div className={styles.popupIn__text}>Выберите валюту</div>
							<Select
								options={tokenList}
								placeholder={`Выберите криптовалюту`}
								classNames={{
									container: state => styles.select3,
									singleValue: state => styles.select4,
									control: state => styles.select2,
									menuList: state => styles.select6,
									menu: state => styles.select61,
									option: state => styles.select7
								}}
								classNamePrefix='react-select'
								onChange={setSelectedTokenOption}
								defaultValue={{ label: 'USDT', value: 259 }}
							/>
							<div style={(selectedTokenOption.label.props?.children[1] === 'CLV') || !chainList.length ? { display: 'none' } : { display: "block" }}>
								<div className={styles.popupIn__text}>Выберите сеть</div>
								<Select
									options={chainList}
									placeholder={`Выберите сеть`}
									classNames={{
										container: state => styles.select3,
										singleValue: state => styles.select4,
										control: state => styles.select2,
										menuList: state => styles.select6,
										menu: state => styles.select61,
										option: state => styles.select7
									}}
									classNamePrefix='react-select'
									onChange={setSelectedChainOption}
									defaultValue={chainList[0]}
								/>
							</div>
							<div className={styles.popupIn__text}>Введите сумму</div>
							<div className={styles.popupIn__input}>
								<input
									type='text'
									placeholder={`Введите сумму`}
									value={summ}
									onChange={e => priceChange(e)}
								/>
							</div>
							{errorMessage ? error : <></>}
							{/* {okMessage ? ok : <></>} */}
							<div
								className={summ && +summ >= 10 && !loading ? styles.btnOk : styles.btnDis}
								onClick={() => (summ && +summ >= 10 && !loading ? moveInRequest() : <></>)}
							>
								Ввести криптовалюту
							</div>
						</div>
					</>

			)}
		</>
	)
}

export default MoveIn
