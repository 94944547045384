import React, { useEffect, useState } from 'react'
import styles from './PayOut.module.scss'
import { ReactComponent as Cross } from "../../../../assets/icons/cross.svg";
import Select from 'react-select'
import { payOutMethod } from '../../../../services/PayService';
import { BankProps, TokenProps } from '../../../filters/Filters';
import { GetRate } from '../../../../services/P2p';
import { useAppSelector } from '../../../../utils/hooks/useAppSelector';
import { getAllWallets } from '../../../../bll/selectors/app.selector';
import { GetPayments } from '../../../../services/UserService';
import { Payments } from '../../../../types/types';

interface PayOutModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  tokenList: TokenProps[];
  selectedTokenOption: any;
  setSelectedTokenOption: (param: any) => void;
}

interface ChartBalance {
  id: number;
  balance: number;
  price: number;
}

const PayOut = ({
  isOpenModal,
  setIsOpenModal,
  tokenList,
  selectedTokenOption,
  setSelectedTokenOption,
}: PayOutModalProps) => {
  const [summ, setSumm] = useState('10');
  const [errorMessage, setErrorMessage] = useState('')
  const [okMessage, setOkMessage] = useState('')
  const [rate, setRate] = useState(0);
  const [chartsBalances, setChartsBalances] = useState<ChartBalance[]>([])
  const [currentChartBalance, setCurrentChartBalance] = useState<ChartBalance>({
    id: 259,
    balance: 0,
    price: 0
  })

  const allWallets = useAppSelector(getAllWallets);

  const getBalance = async () => {
    let tempList = allWallets.map(el => {
      return {
        id: +el.id,
        balance: +el.balance,
        price: +el.price
      }
    })
    setChartsBalances(tempList)
  }

  const getRates = async (token: string, currency_id: number, chart_id: number) => {
    const data = await GetRate(token, currency_id, chart_id);
    if (data.status === 200) {
      setRate(data.data.price)
    }
  }

  useEffect(() => {
    getBalance()
    getPayments()
  }, [])

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    if (+selectedTokenOption.value) {
      getRates(token, 1, +selectedTokenOption.value);
      let temp = chartsBalances.filter(el => el.id === +selectedTokenOption.value);
      setCurrentChartBalance(temp[0]);
    }
  }, [selectedTokenOption, setSelectedTokenOption])

  const error = <div className={styles.error}>
    {errorMessage}
  </div>

  const ok = <div className={styles.ok}>
    {okMessage}
  </div>

  let prev = ''
  const priceChange = (e: any) => {
    const pattern = new RegExp("^(\\d*)\\.{0,1}(\\d*)$")
    if (pattern.test(e.target.value.trim())) {
      if (e.target.value.trim().length > 12) {
        setSumm(e.target.value.trim().slice(0, 12));
        prev = e.target.value.trim().slice(0, 12);
      } else {
        setSumm(e.target.value.trim());
        prev = e.target.value.trim();
      }
    } else if (e.target.value.trim() === '') {
      setSumm('');
      prev = '';
    } else {
      e.target.value = prev;
    }
  }

  const payOut = async () => {
    const token = localStorage.getItem("access_token") ?? '';
    if (+selectedTokenOption.value && +summ && +selectedPaymentOption.value) {
      const data = await payOutMethod(token, +selectedTokenOption.value, +summ, +selectedPaymentOption.value);
      if (data.status && data.data.success) {
        setOkMessage(data.data.message)
        setTimeout(() => {
          setOkMessage('')
          document.location.reload()
        }, 3000);
      } else {
        setErrorMessage(data.data)
        setTimeout(() => {
          setErrorMessage('')
        }, 3000);
      }
    } else {
      setErrorMessage('Проверьте правильность введённых данных')
      setTimeout(() => {
        setErrorMessage('')
      }, 3000);
    }
  };

  const jivoShow = () => {
    let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div');
    (jivo as HTMLDivElement).style.display = 'block';
    let body = document.getElementsByTagName('body')[0];
    (body as HTMLBodyElement).style.overflow = 'unset';
  }

  const [payments, setPayments] = useState<Payments[]>([])
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>({ label: '', value: 0 })

  const getPayments = async () => {
    const token = localStorage.getItem("access_token") ?? '';
    const data = await GetPayments(token);
    if (data.status === 200 && data.data.length) {
      setPayments(data.data)
    }
  }

  const [currentPayments, setCurrentPayments] = useState<any>([])

  useEffect(() => {
    //@ts-ignore
    let temp = payments.map(el => { return { label: `${el.name} | ${el.value}`, value: el.id } })
    setCurrentPayments(temp)
  }, [payments])



  return (
    <>
      {isOpenModal && (
        <>
          <div className={styles.popupIn} onClick={() => { setIsOpenModal(false); jivoShow() }}></div>
          <div
            className={styles.popupIn__block}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.popupIn__title}>Продать</div>
            <Cross
              className={styles.popupIn__close}
              onClick={() => { setIsOpenModal(false); jivoShow() }}
            />
            {/* <div className={styles.popupIn__info}>
              Продажа криптовалюты USDTTRC осуществляется только в сети TRON (TRC20)
            </div> */}
            <div className={styles.popupIn__text}>Криптовалюта </div>
            <Select
              options={tokenList}
              placeholder={`Выберите криптовалюту`}
              classNames={{
                container: (state) => styles.select3,
                singleValue: (state) => styles.select4,
                control: (state) => styles.select2,
                menuList: (state) => styles.select6,
                menu: (state) => styles.select61,
                option: (state) => styles.select7,
                valueContainer: (state) => styles.select5,
              }}
              classNamePrefix="react-select"
              onChange={setSelectedTokenOption}
              defaultValue={selectedTokenOption}
            />
            <div className={styles.popupIn__text}>Карта для вывода</div>
            {payments.length > 0 ?
              <div className={styles.popupIn__input}>
                <div >
                  <Select
                    options={currentPayments}
                    onChange={setSelectedPaymentOption}
                    classNames={{
                      container: (state) => styles.select3,
                      singleValue: (state) => styles.select4,
                      control: (state) => styles.select2,
                      menuList: (state) => styles.select6,
                      menu: (state) => styles.select61,
                      option: (state) => styles.select7,
                    }}
                    classNamePrefix="react-select"
                    defaultValue={selectedPaymentOption}
                  />
                </div>
              </div>
              : <div className={styles.noPay}>Необходимо добавить способ оплаты в профиле</div>
            }
            <div className={styles.popupIn__text}>Сумма</div>
            <div className={styles.popupIn__input}>
              <input
                type="text"
                placeholder={`Введите сумму`}
                value={summ}
                onChange={(e) => priceChange(e)}
                id="inp3"
                maxLength={20}
              />
            </div>
            <div className={styles.popupIn__balance}>
              Текущий баланс:
              <div className={styles.curVal}>{currentChartBalance ? +currentChartBalance.balance : 0} {selectedTokenOption.label}</div>
            </div>
            {errorMessage ? error : <></>}
            {okMessage ? ok : <></>}
            <div className={styles.popupIn__btn} onClick={() => payOut()}>
              Продать криптовалюту
            </div>
            <div className={styles.popupIn__items}>
              <div className={styles.popupIn__item}>
                Сумма к получению
                <div className={styles.curVal}>{(+summ * +rate).toFixed(2)} RUB</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default PayOut