import { useEffect, useState } from 'react'
import Container from '../../UI/container/Container'
import Skeleton from 'react-loading-skeleton'
import styles from './Swap.module.scss'
import { SwapField } from './SwapField'
import FlipIcon from '../../assets/icons/swap/flip.svg'
import USDTIcon from '../../assets/icons/swap/usdt.svg'
import CLVIcon from '../../assets/icons/swap/clv.svg'
import { useAccount, useReadContract, useReadContracts, useWriteContract } from 'wagmi'
import { erc20Abi } from 'viem'
import { SwapItem } from '../../types/swap.types'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import CLVSwapABI from '../../assets/abi/CLVSwap.json'
import { useWaitForTransactionReceipt } from 'wagmi'
import * as ethers from 'ethers'

const swapItems: SwapItem[] = [
	{
		tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
		name: 'USDT',
		icon: USDTIcon
	},
	{
		tokenAddress: '0x4d57Ce7E453D652DEf305e43536491B4d433f9F1',
		name: 'CLV',
		icon: CLVIcon
	}
]

const swapContractAddress = '0xe4501BcC3ED3f0af1a2132788407096124e92505'

const Swap = () => {
	const { isConnected } = useAccount()
	const [loadSwap, setLoadSwap] = useState(false)
	const [swapSended, setSwapSended] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selectedFrom, setSelectedFrom] = useState(0)
	const [selectedTo, setSelectedTo] = useState(1)
	const [fromAmount, setFromAmount] = useState(0)
	const [toAmount, setToAmount] = useState(0)
	const [swapError, setSwapError] = useState(false)
	const { open } = useWeb3Modal()

	const contract = {
		address: swapContractAddress,
		abi: CLVSwapABI
	} as const
	const { data: prices } = useReadContracts({
		contracts: [
			{
				...contract,
				functionName: 'tokenPrices',
				args: [swapItems[selectedFrom].tokenAddress]
			},
			{
				...contract,
				functionName: 'tokenPrices',
				args: [swapItems[selectedTo].tokenAddress]
			}
		]
	})
	const maxValue = useReadContract({
		...contract,
		functionName: 'liquidityBalance',
		args: [swapItems[selectedTo].tokenAddress]
	})
	const [maxAmount, setMaxAmount] = useState('')

	const approve = useWriteContract()
	const swapAction = useWriteContract()

	const {
		data: txReceipt,
		error: txError,
		isLoading: txLoading
	} = useWaitForTransactionReceipt({ confirmations: 1, hash: approve?.data })

	useEffect(() => {
		// Приведение к Number перед операцией деления
		setToAmount(() => {
			const priceFrom = Number(prices?.[0]?.result)
			const priceTo = Number(prices?.[1]?.result)

			return swapItems[selectedFrom].name === 'CLV' ? fromAmount / priceTo : fromAmount * priceFrom
		})

		if (!maxValue?.data) return

		const liquidityBalance = BigInt(maxValue.data as string | number | bigint | boolean) ?? 0;
		const priceFrom = prices ? BigInt(prices?.[0]?.result as string | number | bigint | boolean) : BigInt(1);
		const formattedMaxAmount = ethers.formatEther(liquidityBalance / priceFrom)

		setMaxAmount(() => formattedMaxAmount.toString())
	}, [fromAmount, toAmount, maxValue?.data, prices])

	const swap = async () => {
		try {
			setLoadSwap(() => true)
			setSwapError(() => false)

			const approveTxHash = await approve.writeContractAsync({
				address: swapItems[selectedFrom].tokenAddress as `0x${string}`,
				abi: erc20Abi,
				functionName: 'approve',
				args: [swapContractAddress as `0x${string}`, ethers.parseEther(fromAmount.toString())]
			})

			await new Promise(r => setTimeout(r, 10_000))

			const swapTxHash = await swapAction.writeContractAsync({
				address: swapContractAddress as `0x${string}`,
				abi: Array.from(CLVSwapABI),
				functionName: 'swap',
				args: [
					swapItems[selectedFrom].tokenAddress,
					swapItems[selectedTo].tokenAddress,
					BigInt(fromAmount.toString())
				]
			})

			setLoadSwap(() => false)
			setSwapSended(() => true)

			await new Promise(r => setTimeout(r, 2_000))

			window.location.reload()
		} catch (e) {
			console.log(e)
			setLoadSwap(() => false)
			setSwapSended(() => false)
			setSwapError(() => true)
			setTimeout(() => setSwapError(() => false), 2_000)
		}
	}

	const flip = () => {
		const selFromLast = selectedFrom
		const selToLast = selectedTo

		setSelectedFrom(() => selToLast)
		setSelectedTo(() => selFromLast)
	}

	return (
		<Container className={styles.wrapper}>
			<div className={styles.title}>Swap</div>
			<div style={{ display: 'block', width: '100%' }}>
				{loading ? (
					<Container className={styles.skeletonContainer}>
						<div>
							<div className={styles.skeletonColumn} style={{ marginBottom: '16px' }}>
								<div className={styles.skeletonBlock1}>
									<div>
										<Skeleton
											height={102}
											baseColor={'#1caf8610'}
											highlightColor={'#1caf8630'}
											borderRadius={'8px'}
										/>
									</div>
								</div>
								<div className={styles.containerS}>
									<div className={styles.skeletonBlock2}>
										<div>
											<Skeleton
												height={36}
												width={36}
												baseColor={'#1caf8610'}
												highlightColor={'#1caf8630'}
												borderRadius={'8px'}
											/>
										</div>
									</div>
								</div>
								<div className={styles.skeletonBlock1}>
									<div>
										<Skeleton
											height={102}
											baseColor={'#1caf8610'}
											highlightColor={'#1caf8630'}
											borderRadius={'8px'}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.skeletonBlock1}>
							<div>
								<Skeleton
									height={37}
									baseColor={'#1caf8610'}
									highlightColor={'#1caf8630'}
									borderRadius={'8px'}
								/>
							</div>
						</div>
					</Container>
				) : (
					<Container className={styles.skeletonContainer}>
						<div className={styles.skeletonColumn} style={{ marginBottom: '16px' }}>
							<div className={styles.skeletonBlock1}>
								<SwapField
									side='From'
									withMax={
										!isNaN(Number(maxAmount)) ? Math.floor(Number(maxAmount)) : undefined
									}
									onClick={() => setFromAmount(Math.floor(Number(maxAmount)))}
									icon={swapItems[selectedFrom].icon}
									currency={swapItems[selectedFrom].name}
									amount={fromAmount}
									setAmount={setFromAmount}
								/>
							</div>
							<div className={styles.containerS}>
								<div className={styles.skeletonBlock2}>
									<div
										onClick={() => flip()}
										style={{ cursor: 'pointer' }}
										className={styles.field__flip}
									>
										<img alt='Flip' src={FlipIcon} />
									</div>
								</div>
							</div>
							<div className={styles.skeletonBlock1}>
								<SwapField
									side='To'
									icon={swapItems[selectedTo].icon}
									currency={swapItems[selectedTo].name}
									amount={toAmount}
									setAmount={setToAmount}
								/>
							</div>
						</div>
						<div className={styles.skeletonBlock1}>
							<button
								className={styles.bottomBtn}
								onClick={() => {
									if (typeof isConnected !== undefined && !isConnected) return open()
									if (!(loadSwap && swapSended)) return swap()
								}}
							>
								{typeof isConnected !== undefined
									? isConnected
										? !swapError
											? loadSwap
												? 'Ожидание...'
												: swapSended
													? 'Обмен отправлен'
													: 'Обменять'
											: 'Ошибка...'
										: 'Подключить MetaMask'
									: 'Ожидание...'}
							</button>
						</div>
					</Container>
				)}
			</div>
		</Container>
	)
}

export default Swap
