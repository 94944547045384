import React, { useEffect, useRef, useState } from 'react'
import styles from './Settings.module.scss'
import cross from '../../../assets/icons/cross.svg'
import Input from '../../../UI/Input'
import { UserProfile } from '../../../types/types'
import { ChangeAvatar, ChangeProfile } from '../../../services/UserService'
import a1 from '../../../assets/img/profile/avatar1.svg';
import a2 from '../../../assets/img/profile/avatar2.svg';
import a3 from '../../../assets/img/profile/avatar3.svg';
import a4 from '../../../assets/img/profile/avatar4.svg';
import a5 from '../../../assets/img/profile/avatar5.svg';
import a6 from '../../../assets/img/profile/avatar6.svg';
import myava from '../../../assets/img/profile/myava.svg'

const Settings = (props: any) => {
  const [showNameModal, setShowNameModal] = useState(false)
  const [showAvatarModal, setShowAvatarModal] = useState(false)
  const [newLogin, setNewLogin] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [okMessage, setOkMessage] = useState('')

  const [profile, setProfile] = useState<UserProfile>(props)

  useEffect(() => {
    setProfile(props)
  }, [props])

  const error = <div className={styles.error}>
    {errorMessage}
  </div>

  const ok = <div className={styles.ok}>
    {okMessage}
  </div>

  const changeName = async () => {
    if (newLogin.trim().length < 4) {
      setErrorMessage(`Длина имени должна быть больше четырёх символов`)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000);
    } else {
      const newProfile = {
        login: newLogin,
        telegram: profile.telegram,
        last_name: profile.last_name,
        first_name: profile.first_name,
        patronymic: profile.patronymic,
      }

      const token = localStorage.getItem("access_token") ?? '';
      const data = await ChangeProfile(token, newProfile);
      if (data.status === 200) {
        setOkMessage(`Данные успешно изменены`)
        setTimeout(() => {
          setOkMessage('');
          document.location.reload();
        }, 3000);
      } else {
        setErrorMessage(`Что-то пошло не так...`)
        setTimeout(() => {
          setErrorMessage('')
        }, 3000);
      }
    }
  }

  const bg = <div className={styles.avatarMain} onClick={() => { setShowNameModal(false); setShowAvatarModal(false) }}></div>
  const nameModal =
    <div className={styles.nameRoot}>
      <div className={styles.topBlock}>
        <p className={styles.topBlocktitle}>Изменить логин</p>
        <img
          src={cross}
          alt=""
          className={styles.cross}
          onClick={() => setShowNameModal(false)}
        />
      </div>
      <Input
        value={newLogin}
        setValue={setNewLogin}
        placeholder={`Новый логин`}
        type={'text'}
      />
      {errorMessage ? error : <></>}
      {okMessage ? ok : <></>}
      <button className={styles.btn} onClick={changeName}>Изменить</button>
    </div>

  const [currentNewAva, setCurrentNewAva] = useState(1);

  const changeAvatar = async () => {
    const token = localStorage.getItem("access_token") ?? '';
    const data = await ChangeAvatar(token, currentNewAva)
    if (data.status === 200) {
      document.location.reload()
    }
  }

  const avatars = [a1, a2, a3, a4, a5, a6];

  const avatarModal =
    <div className={styles.nameRoot}>
      <div className={styles.topBlock}>
        <p className={styles.topBlocktitle}>Изменить изображение</p>
        <img
          src={cross}
          alt=""
          className={styles.cross}
          onClick={() => setShowAvatarModal(false)}
        />
      </div>
      <div className={styles.now}>
        <img src={avatars[profile.avatar - 1]} alt="ava" className={styles.nowAva} />
        <h2>Текущий аватар</h2>
      </div>
      <div className={styles.avatars}>
        {
          avatars.map((ava, index) => {
            return <div className={styles.avaContainer} key={index} onClick={() => setCurrentNewAva(index + 1)}>
              {ava === avatars[profile.avatar - 1] ? <img src={myava} alt="myava" className={styles.myAva} /> : ''}
              <img src={ava} alt="ava" className={styles.ava} />
            </div>
          })
        }
      </div>
      <button className={styles.btn} onClick={changeAvatar}>Изменить</button>
    </div>

  useEffect(() => {
    if (showNameModal || showAvatarModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showAvatarModal, showNameModal])

  return (
    <div className={styles.main}>
      {showNameModal || showAvatarModal ? bg : <></>}
      {showNameModal ? nameModal : <></>}
      {showAvatarModal ? avatarModal : <></>}
      <h1 className={styles.title}>Настройки</h1>
      <div className={styles.items}>
        <div className={styles.item}>
          <div className={styles.text}>
            <h3>Изображение</h3>
            <h4>Установите новое изображение</h4>
          </div>
          <div className={styles.userName}>
            <img className={styles.avatar} src={avatars[profile.avatar - 1]} alt="avatar" />
          </div>
          <button className={styles.btn} onClick={() => setShowAvatarModal(true)}>Изменить</button>
        </div>
        <div className={styles.item}>
          <div className={styles.text}>
            <h3>Логин</h3>
            <h4>Задайте профилю уникальный логин</h4>
          </div>
          <div className={styles.userName}>{profile.login}</div>
          <button className={styles.btn} onClick={() => setShowNameModal(true)}>Изменить</button>
        </div>
      </div>
    </div>
  )
}

export default Settings