import styles from './SixthBlock.module.scss';
// import imgBlock6 from '../../../assets/img/main/imgBlock6.png';

import star1 from '../../../assets/img/main/Star1.svg';
import star2 from '../../../assets/img/main/Star2.svg';
import star3 from '../../../assets/img/main/Star3.svg';

export const SixthBlock = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>ОБЕСПЕЧИВАЕМ</h3>
      <div className={styles.flexBlock}>
        <div className={styles.blockLists}>
          <div className={styles.listHead}>безопасность на платформе</div>
          <div className={styles.list}>
            <img src={star1} alt="" />
            <p>  Используем защищенный сервер с современной системой защиты от
              DDoS-атак</p>
          </div>
          <div className={styles.list}>
            <img src={star1} alt="" />
            <p>Используем двухэтапную аутентификацию при выводе средств</p>
          </div>
          <div className={styles.list}>
            <img src={star2} alt="" />
            <p>Администратор платформы лично проверяет вводы и выводы средств
              пользователей, которые прошли верификацию</p>
          </div>
          <div className={styles.list}>
            <img src={star2} alt="" />
            <p> Защищаем от фальсификации баланса</p>
          </div>
          <div className={styles.list}>
            <img src={star3} alt="" />
            <p> Записываем каждое действие пользователя</p>
          </div>
          <div className={styles.list}>
            <img src={star3} alt="" />
            <p>Гарантируем конфиденциальность действий пользователя</p>
          </div>
        </div>
      </div>
    </div>
  );
};
