import React from 'react';
import { createConfig, WagmiProvider, http } from "wagmi";
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { bsc } from 'wagmi/chains'
import { createClient, type Chain } from 'viem'
import { cookieStorage, cookieToInitialState, createStorage } from '@wagmi/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { injected } from "wagmi/connectors"

const projectId = '969d1da0e876fe3aae0466b188afffa4'

const chains = [bsc] as const

const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// const wagmiConfig = createConfig({
//     chains: [bsc],
//     connectors: [
//         injected({ shimDisconnect: true }),
//     ],
//     client({ chain }) {
//         return createClient({ chain, transport: http() })
//     },
//     ssr: true,
// })

const wagmiConfig = defaultWagmiConfig({ 
    chains, 
    projectId, 
    metadata, 
    ssr: true,
    connectors: [
        injected({ shimDisconnect: true }),
    ],
    storage: createStorage({
        storage: cookieStorage
    }),
})

createWeb3Modal({ 
    wagmiConfig, 
    projectId, 
    defaultChain: bsc, 
    featuredWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'],
    includeWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'],
    excludeWalletIds: [
        'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
        '2bd8c14e035c2d48f184aaa168559e86b0e3433228d3c4075900a221785019b0'
    ],
    allowUnsupportedChain: false,
    allWallets: 'HIDE'
}) 

const queryClient = new QueryClient()

export const Web3Provider = ({ children }: React.PropsWithChildren) => {
    // const initialState = cookieToInitialState(config, headers().get('cookie'))

    return <>
        <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
    </>
}
