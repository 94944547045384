import { FC, useState, useEffect, useRef } from 'react'
import styles from './NinthBlock.module.scss'
import arrowbottom from '../../../assets/img/main/arrowbottom.svg'

export const NinthBlock = () => {
	const content = [
		[
			{
				title: 'Что такое P2P биржа?',
				text: 'Пользователи, предпочитающие децентрализованный подход к покупке и продаже криптовалюты, часто склоняются к торговле peer-to-peer. Торговля peer-to-peer, или, как ее обычно называют, P2P, позволяет покупателям и продавцам взаимодействовать напрямую, без агентов или посредников.'
			},
			{
				title: 'В чем отличие от обменного сервиса?',
				text: 'P2P-сервис (англ. peer-to-peer — равный к равному) - операции без посредников. Он позволяет пользователям обменивать криптовалюту без необходимости обращаться к третьей стороне. Криптообменник – это сервис, который помогает перевести криптовалюту в фиатные деньги (и наоборот). Если ваша цель – это исключительно обмен, а не трейдинг, стейкинг и т.д., то это самый простой вариант для операций с криптовалютой.'
			},
			{
				title: 'Какие гарантии, что меня не обманут?',
				text: 'Пользователи Greenavi совершают сделки по покупке и продаже криптовалюты напрямую друг с другом. Большинство транзакций происходит без каких-либо проблем. Однако в случае спорной ситуации или конфликта между пользователями Greenavi предоставляет возможность обеим сторонам открыть апелляцию, чтобы независимая сторона в лице службы поддержки смогла принять справедливое решение и помочь завершить сделку.'
			},
			{
				title: 'Как быстро проходит сделка?',
				text: 'На сделку выделяется до 30 минут. В среднем, сделка занимает от 2 до 4 минут. В случае, если одна из сторон не успела выполнить требуемые действия, у пользователей появляется возможность открыть аппеляцию.'
			},
			{
				title: 'Почему я не указываю кошелек к получению?',
				text: 'Криптовалюта находится у Вас в Личном кабинете Greenavi. После сделки Вы можете сделать вывести её на Ваш личный кошелек.'
			}
		],
		[
			{
				title: 'За что могут заблокировать на платформе?',
				text: 'На самом деле, ограничения, принятые на Greenavi, характерны для любого цивилизованного общества. Они сводятся к простым постулатам: быть честным в бизнесе и корректным в общении.'
			},
			{
				title: 'Какие комиссии по вводу и выводу криптовалют?',
				text: 'Мы не накладываем никаких дополнительных комиссий на операции с криптовалютой. Оплачивается только комиссия сети.'
			},
			{
				title: 'Как стать партнером платформы?',
				text: 'Если вы желаете стать партнером Greenavi, вы можете ознакомиться с требованиями к мерчантам в личном кабинете, а также обратившись к администрации на почту в блоке «Контакты»'
			},
			{
				title: 'Есть ли ограничения по оборотам криптовалюты?',
				text: 'На данный момент ограничений по оборотам криптовалюты нет'
			},
			{
				title: 'Когда будут доступны другие монеты?',
				text: 'Мы постепенно будем расширять список доступных для торговли монет. Также, вы всегда можете написать нам, чтобы мы рассмотрели добавление какой-либо валюты.'
			}
		]
	]

	const [block1Visible, setBlock1Visibility] = useState(true)
	const [openIndexes, setOpenIndexes] = useState<number[]>([])
	// const [isInView, setIsInView] = useState(false)
	const blockRef = useRef<HTMLDivElement | null>(null)

	const toggleBlock1 = () => {
		setBlock1Visibility(true)
		setOpenIndexes([]) // Сброс состояния открытых индексов
	}
	const toggleBlock2 = () => {
		setBlock1Visibility(false)
		setOpenIndexes([]) // Сброс состояния открытых индексов
	}

	const handleUserClick = (index: number) => {
		setOpenIndexes(prev => {
			if (prev.includes(index)) {
				return prev.filter(i => i !== index)
			} else {
				return [...prev, index]
			}
		})
	}

	// useEffect(() => {
	// 	const observer = new IntersectionObserver(
	// 		entries => {
	// 			if (entries[0].isIntersecting) {
	// 				setIsInView(true)
	// 			} else {
	// 				setIsInView(false)
	// 			}
	// 		},
	// 		{ threshold: 0.1 }
	// 	)

	// 	if (blockRef.current) {
	// 		observer.observe(blockRef.current)
	// 	}

	// 	return () => {
	// 		if (blockRef.current) {
	// 			observer.unobserve(blockRef.current)
	// 		}
	// 	}
	// }, [])

	// useEffect(() => {
	// 	if (isInView) {
	// 		const intervalId = setInterval(() => {
	// 			setOpenIndexes(prev => {
	// 				const nextIndex =
	// 					(prev.length === 0 ? 0 : prev[prev.length - 1] + 1) % content[block1Visible ? 0 : 1].length
	// 				return prev.includes(nextIndex) ? prev : [...prev, nextIndex]
	// 			})
	// 		}, 1500)

	// 		return () => clearInterval(intervalId)
	// 	}
	// }, [block1Visible, isInView])

	return (
		<>
			<h3 className={styles.title}>Часто задаваемые Вопросы</h3>
			<div className={styles.accardionContainer} ref={blockRef}>
				<div className={styles.accardionBlock}>
					<div className={styles.btns}>
						<button
							className={block1Visible ? styles.topButtonA : styles.topButton}
							onClick={toggleBlock1}
						>
							Начинающий
						</button>
						<button
							className={!block1Visible ? styles.topButtonA : styles.topButton}
							onClick={toggleBlock2}
						>
							Продвинутый
						</button>
					</div>
					{block1Visible
						? content[0].map((list, key) => (
							<AccordionList
								title={list.title}
								info={list.text}
								key={key}
								isOpen={openIndexes.includes(key)}
								onClick={() => handleUserClick(key)}
							/>
						))
						: content[1].map((list, key) => (
							<AccordionList
								title={list.title}
								info={list.text}
								key={key}
								isOpen={openIndexes.includes(key)}
								onClick={() => handleUserClick(key)}
							/>
						))}
				</div>
			</div>
		</>
	)
}

interface AccordionProps {
	title: string
	info: string
	isOpen: boolean
	onClick: () => void
}

const AccordionList: FC<AccordionProps> = ({ title, info, isOpen, onClick }) => {
	const [openInfo, setOpen] = useState(isOpen)

	useEffect(() => {
		setOpen(isOpen)
	}, [isOpen])

	return (
		<div className={styles.list}>
			<div
				className={styles.listContainer}
				onClick={() => {
					setOpen(!openInfo)
					onClick()
				}}
			>
				<div className={styles.listText}>{title}</div>
				<button className={openInfo ? `${styles.buttonListOpen}` : `${styles.buttonListClose}`}>
					<img src={arrowbottom} alt='phone' />
				</button>
			</div>
			{openInfo && <div className={styles.listInfo}>{info}</div>}
		</div>
	)
}
