import React from 'react'
import styles from './Chat.module.scss'
import ava1 from '../../assets/img/profile/avatar1.svg'
import ava2 from '../../assets/img/profile/avatar2.svg'
import ava3 from '../../assets/img/profile/avatar3.svg'
import ava4 from '../../assets/img/profile/avatar4.svg'
import ava5 from '../../assets/img/profile/avatar5.svg'
import ava6 from '../../assets/img/profile/avatar6.svg'
import close from '../../assets/icons/close.svg'
import MessageList from './messageList/MessageList'
import SendBlock from './sendBlock/SendBlock'
import { IMessage } from '../../types/types'

interface ChatPropsType {
  agentName: string
  userId: number
  avatar: number
  openChat: boolean
  setOpenChat: (param: boolean) => void
  setUnread: (param: string) => void;
  roomId: number,
  messageList: IMessage[],
  setReload: (param: boolean) => void,
}

const Chat = ({
  agentName, userId, avatar, openChat, setOpenChat, setUnread, roomId, messageList, setReload
}: ChatPropsType) => {
  const avaList = [ava1, ava2, ava3, ava4, ava5, ava6]

  const jivoShow = () => {
    let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div')
      ; (jivo as HTMLElement).style.display = 'block'
    let body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'unset'
    window.scrollTo(0, 0)
  }

  return (
    <div className={styles.chatBlock}>
      <div className={styles.topBlock}>
        <img
          src={avaList[avatar - 1]}
          alt={'avatar'}
          className={styles.avatar}
        />

        <p className={styles.userName}>{agentName}</p>
        <img
          src={close}
          alt='close'
          className={styles.close}
          style={openChat ? { display: 'block' } : { display: 'none' }}
          onClick={() => {
            setOpenChat(false)
            jivoShow()
          }}
        />
      </div>
      <MessageList messageList={messageList} userId={userId} />
      <SendBlock setReload={setReload} userId={userId} roomId={roomId} />
    </div>
  )
}

export default Chat