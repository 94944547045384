import React, { useState } from 'react'
import styles from './index.module.scss'
import cross from '../../../assets/icons/cross.svg'
import Input from '../../../UI/Input'
import { LogMethod } from '../../../services/AuthService'
import OtpInput from '../Register/OtpInput/OtpInput'
import { GetRecoverCode, RecoverPassword } from '../../../services/UserService'
// import { userLogin } from "../../../services/profileServices";
// import { FormattedMessage, useIntl } from "react-intl";
import MoveOut from '../../profileComponents/modals/MoveOut/MoveOut'

interface LoginProps {
	setAuthType: (str: string) => void
	setOpenModal: (openModal: boolean) => void
	setStatus: (status: boolean) => void
}

const Login = ({ setAuthType, setOpenModal, setStatus }: LoginProps) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [requestStatus, setRequestStatus] = useState<any>()
	const [errorMessage, setErrorMessage] = useState('')
	const [showEmailVerify, setShowEmailVerify] = useState(false)
	const [loading, setLoading] = useState(false)

	const userLoginFunc = async () => {
		setLoading(true)
		const data = await LogMethod({ email: email, password: password })
		if (data.data === undefined) {
			setErrorMessage('Что-то пошло не так..')
			setTimeout(() => {
				setErrorMessage('')
			}, 3000)
			setLoading(false)
		} else if (data.status === 400 || !data.data.success) {
			setErrorMessage('Неверный логин или пароль')
			setTimeout(() => {
				setErrorMessage('')
			}, 3000)
			setLoading(false)
		} else {
			setShowEmailVerify(true)
			setLoading(false)
		}
	}

	const error = <div className={styles.error}>{errorMessage}</div>

	const [otp, setOtp] = useState('')
	const onChange = (value: string) => setOtp(value)

	const block = (
		<div className={styles.changeBlock}>
			<div className={styles.blockTop}>
				<p className={styles.blockTopText}>Код подтверждения</p>
				<div
					className={styles.blockClose}
					onClick={() => {
						setShowEmailVerify(false)
						setOpenModal(false)
					}}
				></div>
			</div>
			<div className={styles.mail}></div>
			<div className={styles.info}>
				На указанный адрес электронной почты отправлено письмо. Введите 6-тизначный код из письма
			</div>
			<div className={styles.wrap}>
				<OtpInput value={otp} valueLength={6} onChange={onChange} email={email} password={password} />
				{errorMessage ? error : <></>}
			</div>
			<div
				className={styles.resent}
				onClick={() => {
					setShowEmailVerify(false)
					userLoginFunc()
				}}
			>
				Письмо не пришло. Повторить отправку
			</div>
		</div>
	)

	const [showRecoverModal, setShowRecoverModal] = useState(false)
	const [emailRecover, setEmailRecover] = useState(email ?? '')
	const [stage, setStage] = useState(1)
	const [errorMessage2, setErrorMessage2] = useState('')
	const [okMessage, setOkMessage] = useState('')
	const [code, setCode] = useState('')
	const [newPassword, setNewPassword] = useState('')

	const error2 = <div className={styles.error}>{errorMessage2}</div>
	const ok = <div className={styles.ok}>{okMessage}</div>

	const getRecoverCode = async () => {
		const token = localStorage.getItem('access_token') ?? ''
		const data = await GetRecoverCode(token, emailRecover ? emailRecover : email)
		if (data.status === 200 && data.data?.token) {
			setStage(2)
		} else {
			setErrorMessage2('Что-то пошло не так...')
			setTimeout(() => {
				setErrorMessage2('')
			}, 2000)
		}
	}

	const recover = async () => {
		const token = localStorage.getItem('access_token') ?? ''
		const data = await RecoverPassword(token, code, newPassword)
		if (data.status === 200) {
			setOkMessage('Пароль успешно восстановлен')
			setTimeout(() => {
				setOkMessage('')
			}, 2000)
			document.location.reload()
		} else {
			setErrorMessage2('Что-то пошло не так')
			setTimeout(() => {
				setErrorMessage2('')
			}, 2000)
		}
	}

	const recoverPass = () => {
		if (stage === 1) {
			getRecoverCode()
		} else {
			recover()
		}
	}

	const recoverModal = (
		<div className={styles.passRoot}>
			<div className={styles.topBlock}>
				<p className={styles.topBlocktitle}>Восстановить пароль</p>
				<img src={cross} alt='' className={styles.cross} onClick={() => setShowRecoverModal(false)} />
			</div>
			{stage === 2 ? (
				<div className={styles.stage}>
					<Input value={code} setValue={setCode} placeholder={`Код подтверждения`} type={'text'} />
					<Input
						value={newPassword}
						setValue={setNewPassword}
						placeholder={`Новый пароль`}
						type={'password'}
					/>
				</div>
			) : (
				<Input
					value={email ? email : emailRecover}
					setValue={e => {
						setEmail(e)
						setEmailRecover(e)
					}}
					placeholder={`Ваш E-Mail`}
					type={'email'}
				/>
			)}
			{errorMessage2 ? error2 : <></>}
			{okMessage ? ok : <></>}
			<button className={styles.btnOk} onClick={recoverPass}>
				{stage === 1 ? `Получить код` : `Восстановить`}
			</button>
		</div>
	)

	return (
		<>
			{showEmailVerify ? block : <></>}
			{showRecoverModal ? recoverModal : <></>}
			<div className={styles.root} style={showRecoverModal ? { display: 'none' } : { display: 'flex' }}>
				<div className={styles.topBlock}>
					<p className={styles.title}>Авторизация</p>
					<img src={cross} alt='' className={styles.cross} onClick={() => setOpenModal(false)} />
				</div>
				<div className={styles.warning}>
					<p className={styles.warningText}>
						Для пользования площадкой необходимо зарегистрировать отдельный аккаунт на greenavi.com
					</p>
				</div>
				<div className={styles.inputBlock}>
					<Input value={email} setValue={setEmail} type='email' placeholder={`E-mail`} />
					<Input value={password} setValue={setPassword} type='password' placeholder={`Пароль`} />
				</div>
				{errorMessage ? error : <></>}
				<div className={styles.help}>
					<span className={styles.helpPassword} onClick={() => setShowRecoverModal(true)}>
						Забыли пароль?
					</span>
				</div>
				<div className={styles.btns}>
					<button
						className={loading ? styles.btnDis : styles.authBtn}
						onClick={() => {
							!loading ? userLoginFunc() : <></>
						}}
					>
						Авторизоваться
					</button>
				</div>
				<div className={styles.switchAuth} onClick={() => setAuthType('register')}>
					Регистрация
				</div>
			</div>
		</>
	)
}
export default Login
