import React, { useEffect, useState } from 'react'
import styles from './Wallet.module.scss'
import BalanceBlock from '../balanceBlock/BalanceBlock'
import DetailBalanceBlock from '../detailBalanceBlock/DetailBalanceBlock'
import { BalanceInfo, GetPayments } from '../../../services/UserService'
import { WalletBalance } from '../../../types/types'
import Container from '../../../UI/container/Container'
import Skeleton from 'react-loading-skeleton'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import { BankProps, TokenProps } from '../../filters/Filters'
import { GetB2bCharts, GetCharts, GetPayment } from '../../../services/P2p'
import Transfer from '../modals/transfer/Transfer'
import { useAppSelector } from '../../../utils/hooks/useAppSelector'
import { getAllWallets, getB2bWallets } from '../../../bll/selectors/app.selector'
import MoveIn from '../modals/MoveIn/MoveIn'
import MoveOut from '../modals/MoveOut/MoveOut'
import PayIn from '../modals/payIn/PayIn'
import PayOut from '../modals/payOut/PayOut'
import B2bPayOut from '../modals/b2bPayOut/B2bPayOut'

export interface Chart {
	symbol: string
	icon: string
	balance: number
	blocked: number | null
}

const Wallet = (props: any) => {
	const [loading, setLoading] = useState(false)
	const [p2pChartList, setP2pChartList] = useState<Chart[]>([])
	const [b2bChartList, setB2bChartList] = useState<Chart[]>([])
	const [totalBalance, setTotalBalance] = useState<number>(0)
	const [totalBlocked, setTotalBlocked] = useState<number>(0)
	const [b2bBalance, setB2bBalance] = useState<number>(0)
	const [b2bBlocked, setB2bBlocked] = useState<number>(0)
	const [bankList, setBankList] = useState<BankProps[]>([{ label: 'SberBank', value: 32 }])

	const allWallets = useAppSelector(getAllWallets)

	const getBalance = async () => {
		//@ts-ignore
		if (allWallets[0] === 'нет данных') {
			setTotalBlocked(0)
			setTotalBalance(0)
			setP2pChartList([])
		} else {
			let row = allWallets
			let tempTotal = row.map(el => +el.balance)
			let total = tempTotal.reduce((prev, cur) => +prev + +cur, 0)
			setTotalBalance(total)
			let tempBlocked = row.map(el => (el?.blocked ? +el.blocked : 0))
			let totalBlocked = tempBlocked.reduce((prev, cur) => +prev + +cur, 0)
			setTotalBlocked(totalBlocked)
			let tempP2pChartList: Chart[] = row.map(el => {
				return { symbol: el.symbol, icon: el.icon, balance: el.balance, blocked: el.blocked }
			})
			setP2pChartList(tempP2pChartList)
		}
		setLoading(false)
	}

	const b2bWallets = useAppSelector(getB2bWallets)

	const getB2bBalance = async () => {
		//@ts-ignore
		if (b2bWallets[0] === 'нет данных') {
			setB2bBlocked(0)
			setB2bBalance(0)
			setB2bChartList([])
		} else {
			let row = b2bWallets
			let tempTotal = row.map(el => +el.balance)
			let total = tempTotal.reduce((prev, cur) => +prev + +cur, 0)
			setB2bBalance(total)
			let tempBlocked = row.map(el => (el?.blocked ? +el.blocked : 0))
			let totalBlocked = tempBlocked.reduce((prev, cur) => +prev + +cur, 0)
			setB2bBlocked(totalBlocked)
			let tempB2bChartList: Chart[] = row.map(el => {
				return { symbol: el.symbol, icon: el.icon, balance: el.balance, blocked: el.blocked }
			})
			setB2bChartList(tempB2bChartList)
		}
	}

	const getBanks = async (token: string) => {
		const data = await GetPayment(token, 1)
		if (data.status === 200 && data.data.length) {
			const temp: BankProps[] = []
			data.data.forEach((el: any) => {
				temp.push({ label: el.name, value: el.id.toString() })
			})
			setBankList(temp)
		}
	}

	useEffect(() => {
		setLoading(true)
		const token = localStorage.getItem('access_token') ?? ''
		getBalance()
		getB2bBalance()
		getBanks(token)
	}, [])

	const [activeSlide, setAtiveSlide] = useState(0)

	const [isOpenTransferModal, setIsOpenTransferModal] = useState(false)
	const [tokenList, setTokenList] = useState<TokenProps[]>([])
	const [tokenB2bList, setTokenB2bList] = useState<TokenProps[]>([])

	const getKrypto = async (token: string) => {
		const data = await GetCharts(token)
		if (data.status === 200) {
			const temp: TokenProps[] = []
			data.data.forEach((el: any) => {
				temp.push({
					label: (
						<div className={styles.fiatBox}>
							<img src={el.icon} alt={el.name} className={styles.fiat} />
							{el.symbol}
						</div>
					),
					value: el.id
				})
			})
			setTokenList(temp)
		}
	}
	const getB2bKrypto = async (token: string) => {
		const data = await GetB2bCharts(token)
		if (data.status === 200) {
			const temp: TokenProps[] = []
			data.data.forEach((el: any) => {
				temp.push({
					label: (
						<div className={styles.fiatBox}>
							<img src={el.icon} alt={el.name} className={styles.fiat} />
							{el.symbol}
						</div>
					),
					value: el.id
				})
			})
			setTokenB2bList(temp)
		}
	}

	useEffect(() => {
		const token = localStorage.getItem('access_token') ?? ''
		getKrypto(token)
		getB2bKrypto(token)
	}, [])

	const [bankListB2b, setBankListB2b] = useState<BankProps[]>([])

	const getBank = async (token: string, fiatID: number) => {
		const data = await GetPayments(token, fiatID)
		if (data.status === 200) {
			const temp: BankProps[] = []
			data.data.forEach((el: any) => {
				temp.push({ label: el.name, value: el.payment_id.toString() })
			})
			setBankListB2b(temp)
		}
	}

	useEffect(() => {
		const token = localStorage.getItem('access_token') ?? ''
		getBank(token, 1)
	}, [totalBalance])

	const [isOpenMoveInModal, setIsOpenMoveInModal] = useState(false)
	const [isOpenMoveOutModal, setIsOpenMoveOutModal] = useState(false)
	const [isMoveOutModal, setIsMoveOutModal] = useState(false)
	const [isOpenPayInModal, setIsOpenPayInModal] = useState(false)
	const [isOpenPayOutModal, setIsOpenPayOutModal] = useState(false)
	const [isOpenB2bPayOutModal, setIsOpenB2bPayOutModal] = useState(false)

	const [selectedTokenOption, setSelectedTokenOption] = useState<any>({
		label: <div className={styles.fiatBox}>USDT</div>,
		value: 259
	})
	const [selectedTokenB2bOption, setSelectedTokenB2bOption] = useState<any>({
		label: <div className={styles.fiatBox}>USDT</div>,
		value: 259
	})

	const [selectedBankOption, setSelectedBankOption] = useState<any>({ label: 'SberBank', value: 32 })

	const [action, setAction] = useState<'total' | 'b2b'>('total')

	return (
		<div className={styles.main}>
			{loading ? (
				<div style={{ display: 'block', width: '100%' }}>
					<Container className={styles.skeletonContainer}>
						<div className={styles.skeletonBlock1}>
							<div style={{ marginBottom: '10px' }} className={styles.skelet1}>
								<Skeleton
									height={250}
									baseColor={'#0ddcaa10'}
									highlightColor={'#0ddcaa30'}
									borderRadius={'8px'}
								/>
							</div>
							<div className={styles.skelet2}>
								<Skeleton
									height={250}
									baseColor={'#0ddcaa10'}
									highlightColor={'#0ddcaa30'}
									borderRadius={'8px'}
								/>
							</div>
						</div>
					</Container>
				</div>
			) : (
				<>
					<div className={styles.top}>
						<div className={styles.balanceContainer}>
							<BalanceBlock
								setAction={setAction}
								balance={totalBalance}
								blocked={totalBlocked}
								title={'total'}
								setIsOpenTransferModal={setIsOpenTransferModal}
								setIsOpenMoveInModal={setIsOpenMoveInModal}
								setIsOpenMoveOutModal={setIsOpenMoveOutModal}
								setIsMoveOutModal={setIsMoveOutModal}
								setIsOpenPayInModal={setIsOpenPayInModal}
								setIsOpenPayOutModal={setIsOpenPayOutModal}
								setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
							/>
							<BalanceBlock
								setAction={setAction}
								balance={b2bBalance}
								blocked={b2bBlocked}
								title='b2b'
								setIsOpenTransferModal={setIsOpenTransferModal}
								setIsOpenMoveInModal={setIsOpenMoveInModal}
								setIsOpenMoveOutModal={setIsOpenMoveOutModal}
								setIsMoveOutModal={setIsMoveOutModal}
								setIsOpenPayInModal={setIsOpenPayInModal}
								setIsOpenPayOutModal={setIsOpenPayOutModal}
								setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
							/>
						</div>
						<div className={styles.swiperContainer} style={{ width: `${window.innerWidth - 20}px` }}>
							<Swiper
								spaceBetween={50}
								slidesPerView={1}
								onSlideChange={(e: any) => setAtiveSlide(e.activeIndex)}
							>
								<SwiperSlide>
									<BalanceBlock
										setAction={setAction}
										balance={totalBalance}
										blocked={totalBlocked}
										title='total'
										setIsOpenTransferModal={setIsOpenTransferModal}
										setIsOpenMoveInModal={setIsOpenMoveInModal}
										setIsOpenMoveOutModal={setIsOpenMoveOutModal}
										setIsMoveOutModal={setIsMoveOutModal}
										setIsOpenPayInModal={setIsOpenPayInModal}
										setIsOpenPayOutModal={setIsOpenPayOutModal}
										setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
									/>
								</SwiperSlide>
								<SwiperSlide>
									<BalanceBlock
										setAction={setAction}
										balance={b2bBalance}
										blocked={b2bBlocked}
										title='b2b'
										setIsOpenTransferModal={setIsOpenTransferModal}
										setIsOpenMoveInModal={setIsOpenMoveInModal}
										setIsOpenMoveOutModal={setIsOpenMoveOutModal}
										setIsMoveOutModal={setIsMoveOutModal}
										setIsOpenPayInModal={setIsOpenPayInModal}
										setIsOpenPayOutModal={setIsOpenPayOutModal}
										setIsOpenB2bPayOutModal={setIsOpenB2bPayOutModal}
									/>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
					<div className={styles.bottom}>
						<div className={styles.detailMobile}>
							{activeSlide === 0 ? (
								<DetailBalanceBlock chartList={p2pChartList} />
							) : (
								<DetailBalanceBlock chartList={b2bChartList} />
							)}
						</div>
						<div className={styles.detailDesk}>
							<DetailBalanceBlock chartList={p2pChartList} />
							<DetailBalanceBlock chartList={b2bChartList} />
						</div>
					</div>
				</>
			)}
			<MoveIn
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenMoveInModal}
				setIsOpenModal={setIsOpenMoveInModal}
			/>
			<MoveOut
				bankList={bankList}
				selectedBankOption={selectedBankOption}
				setSelectedBankOption={setSelectedBankOption}
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenMoveOutModal}
				setIsOpenModal={setIsOpenMoveOutModal}
				p2pChartList={p2pChartList.filter(el => el.symbol === 'USDT')}
				isMoveOutModal={isMoveOutModal}
			/>
			<PayIn
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenPayInModal}
				setIsOpenModal={setIsOpenPayInModal}
			/>
			<PayOut
				tokenList={tokenList}
				selectedTokenOption={selectedTokenOption}
				setSelectedTokenOption={setSelectedTokenOption}
				isOpenModal={isOpenPayOutModal}
				setIsOpenModal={setIsOpenPayOutModal}
			/>
			<B2bPayOut
				profile={props}
				bankList={bankListB2b}
				bankListToAdd={bankList}
				isOpenModal={isOpenB2bPayOutModal}
				setIsOpenModal={setIsOpenB2bPayOutModal}
				tokenList={tokenB2bList}
				selectedTokenOption={selectedTokenB2bOption}
				setSelectedTokenOption={setSelectedTokenB2bOption}
			/>
			<Transfer
				action={action}
				tokenP2pList={tokenList}
				tokenB2bList={tokenB2bList}
				isOpenModal={isOpenTransferModal}
				setIsOpenModal={setIsOpenTransferModal}
			/>
		</div>
	)
}

export default Wallet
