import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './B2bPayOut.module.scss'
import { ReactComponent as Cross } from "../../../../assets/icons/cross.svg";
import Select from 'react-select'
import { BankProps, TokenProps } from '../../../filters/Filters';
import { GetRate } from '../../../../services/P2p';
import { B2bCouriers, B2bPayments, UserProfile } from '../../../../types/types';
import cross from '../../../../assets/icons/cross.svg'
import { useAppSelector } from '../../../../utils/hooks/useAppSelector';
import { getB2bWallets, getBank } from '../../../../bll/selectors/app.selector';
import { AddB2bCard, B2bCardMethod, DelB2bCard, DeliveryMethod, GetCardB2bList, UpdateSumma, moveOutMethod } from '../../../../services/PayService';
import { getCompanyList } from '../../../../bll/selectors/company.selector';
import back from '../../../../assets/img/main/arrowbottom.svg'
import delivery from '../../../../assets/icons/delivery.svg'

interface b2bPayOutPropsType {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  tokenList: TokenProps[];
  selectedTokenOption: any;
  setSelectedTokenOption: (param: any) => void;
  bankList: BankProps[];
  bankListToAdd?: BankProps[];
  profile: UserProfile;
}

interface toOutParams {
  card_id: number,
  sum: number
}

export interface ChartBalance {
  id: number;
  balance: number;
  price: number;
}

const B2bPayOut = ({
  isOpenModal,
  setIsOpenModal,
  tokenList,
  selectedTokenOption,
  setSelectedTokenOption,
  bankList,
  bankListToAdd,
  profile
}: b2bPayOutPropsType) => {

  const [summ, setSumm] = useState('10');
  const [chartsBalances, setChartsBalances] = useState<ChartBalance[]>([])
  const [currentChartBalance, setCurrentChartBalance] = useState<ChartBalance>({
    id: 259,
    balance: 0,
    price: 0
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [okMessage, setOkMessage] = useState('')
  const [rate, setRate] = useState(0);
  const [payments, setPayments] = useState<B2bPayments[]>([])
  const [couriers, setCouriers] = useState<B2bCouriers[]>([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedBankOption, setSelectedBankOption] = useState<any>({ label: 'SberBank', value: 32 });
  const [cardNumber, setCardNumber] = useState('')
  const [telNumber, setTelNumber] = useState('')
  const [fio, setFio] = useState('')
  const [checked, setChecked] = useState(false)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [street, setStreet] = useState('')
  const [house, setHouse] = useState('')
  const [hp, setHp] = useState('')

  const b2bWallets = useAppSelector(getB2bWallets);

  const getBalance = async () => {
    let tempList: ChartBalance[] = b2bWallets.map(el => {
      return {
        id: +el.id,
        balance: +el.balance,
        price: +el.price
      }
    })
    setChartsBalances(tempList)
  }

  const error = <div className={styles.error}>
    {errorMessage}
  </div>

  const ok = <div className={styles.ok}>
    {okMessage}
  </div>

  const [current, setCurrent] = useState(0);

  const deliveryOut = async (type: 1 | 0) => {
    const token = localStorage.getItem("access_token") ?? '';
    if (type === 0) {
      const data = await DeliveryMethod(token, name, phone, street, house, hp, summ);
      if (data.status === 200) {
        setOkMessage('Вывод средств курьером успешно оформлен')
        setTimeout(() => {
          setOkMessage('')
          document.location.reload();
        }, 2000);
      } else {
        setErrorMessage(data.data)
        setTimeout(() => {
          setErrorMessage('')
        }, 2000);
      }
    } else {
      const ids = payments.map(el => el.id);
      const token = localStorage.getItem("access_token") ?? '';
      const data = await moveOutMethod(token, +selectedTokenOption.value, +summ, ids);
      if (data.status === 200) {
        Object.keys(toOut2).forEach((id) => {
          const data = UpdateSumma(token, +id, +toOut2[id])
        })
        setOkMessage(data.data.message)
        setTimeout(() => {
          setOkMessage('');
          setCurrent(0);
          setIsOpenModal(false)
        }, 2000);
      } else {
        setErrorMessage(data.data)
        setTimeout(() => {
          setErrorMessage('')
        }, 2000);
      }
    }
  }

  const getPayments = async (token: string) => {
    const data = await GetCardB2bList(token, 0, profile.id);
    if (data.status === 200 && data.data.length) {
      setPayments(data.data)
    }
  }

  const getCouriers = async (token: string) => {
    const data = await GetCardB2bList(token, 1, profile.id);
    if (data.status === 200) {
      setCouriers(data.data)
    }
  }

  const getRates = async (token: string, currency_id: number, chart_id: number) => {
    const data = await GetRate(token, currency_id, chart_id);
    if (data.status === 200) {
      setRate(data.data.price)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    getBalance()
    getPayments(token)
    getCouriers(token)
  }, [])

  useEffect(() => {
    const token = localStorage.getItem("access_token") ?? '';
    if (+selectedTokenOption.value) {
      getRates(token, 1, +selectedTokenOption.value);
      let temp = chartsBalances.filter(el => el.id === +selectedTokenOption.value);
      setCurrentChartBalance(temp[0]);
    }
  }, [selectedTokenOption, setSelectedTokenOption])

  let prev = ''
  const priceChange = (e: any) => {
    const pattern = new RegExp("^(\\d*)\\.{0,1}(\\d*)$")
    if (pattern.test(e.target.value.trim())) {
      if (e.target.value.trim().length > 12) {
        setSumm(e.target.value.trim().slice(0, 12));
        prev = e.target.value.trim().slice(0, 12);
      } else {
        setSumm(e.target.value.trim());
        prev = e.target.value.trim();
      }
    } else if (e.target.value.trim() === '') {
      setSumm('');
      prev = '';
    } else {
      e.target.value = prev;
    }
  }

  const [activeList, setActiveList] = useState<number[]>([])
  const [ps, setPs] = useState(false);

  const changePayment = (el: B2bPayments) => {
    const currentPayment = document.getElementById(`${el.id}`) || document.createElement('div');
    if (!activeList.includes(el.id)) {
      let temp = activeList;
      temp.push(el.id);
      setActiveList(temp);
      currentPayment.style.border = '2px solid #1caf86';
    } else {
      let newList = activeList.filter(num => num !== el.id);
      setActiveList(newList);
      currentPayment.style.border = '2px solid rgba(217, 217, 217, 0.062745098)';
      setPs(newList.length === 0 ? false : true)
    }
    if (activeList.length) {
      setPs(true)
    }
  }

  useEffect(() => {
    setPs(activeList.length === 0 ? false : true)
  }, [activeList, setActiveList])


  const checkTelValue = (e: ChangeEvent<HTMLInputElement>) => {
    const numbers = /^[0-9 ]*$/;
    // const reg = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
    //@ts-ignore
    if ((e.nativeEvent.inputType === 'insertText' && !numbers.test(e.target.value)) || e.target.value.length > 11) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    }
    if (e.target.value.length === 11) {
      setTelNumber(e.target.value)
    } else {
      setTelNumber('')
    }
  }

  const checkValue = (e: ChangeEvent<HTMLInputElement>) => {
    const numbers = /^[0-9 ]*$/;
    const regExp = /[0-9]{4}/;

    // не позволяем ввести ничего, кроме цифр 0-9, ограничиваем размер поля 19-ю символами
    //@ts-ignore
    if ((e.nativeEvent.inputType === 'insertText' && !numbers.test(e.target.value)) || e.target.value.length > 19) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    }
    // обеспечиваем работу клавиш "backspace","delete"
    let value = e.target.value
    //@ts-ignore
    if (e.nativeEvent.inputType === "deleteContentBackward" && regExp.test(value.slice(-4))) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1)
      return
    }
    // добавяем пробел после 4 цифр подряд
    if (regExp.test(value.slice(-4)) && value.length < 19) {
      e.target.value += " "
    }
    if (e.target.value.length === 19) {
      setCardNumber(e.target.value)
    } else {
      setCardNumber('')
    }
  }

  useEffect(() => {
    if (selectedBankOption.label === 'QIWI') {
      if (telNumber && selectedBankOption.value && fio) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    } else {
      if (cardNumber && selectedBankOption.value && fio) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }
  }, [cardNumber, selectedBankOption.value, fio, selectedBankOption.label, telNumber])

  const [bankId, setBankId] = useState(0);

  const company = useAppSelector(getCompanyList);
  const b2bBank = useAppSelector(getBank);

  const filterBanks = () => {
    //@ts-ignore
    const currentBank = Object.values(b2bBank).filter(el => el.title === company[0]?.bank);
    currentBank.length ? setBankId(currentBank[0].id) : <></>
  }

  // useEffect(() => {
  //   filterBanks()
  // }, [b2bBank])

  const addPayment = async () => {
    if (checked) {
      const token = localStorage.getItem("access_token") ?? '';
      const data = await AddB2bCard(token, fio, selectedBankOption.label === 'QIWI' ? telNumber : cardNumber, bankId, +company[0].id, +selectedBankOption.value, selectedBankOption.label);
      if (data.status === 200 && data.data.success) {
        setOkMessage('Способ вывода успешно добавлен')
        setTimeout(() => {
          setOkMessage('')
          setShowAddModal(false)
        }, 1000);
      } else {
        setErrorMessage(data.data)
        setTimeout(() => {
          setErrorMessage('')
        }, 3000);
      }
      getPayments(token);
    }
  }

  const delPayment = async (el: B2bPayments) => {
    const token = localStorage.getItem("access_token") ?? '';
    const data = await DelB2bCard(token, el.id);
    if (data.status === 200) {
      setOkMessage(data.data.message)
      setTimeout(() => {
        setOkMessage('');
        if (payments.length === 1) {
          setPayments([])
          setActiveList([])
          setPs(false)
        } else {
          getPayments(token)
        }
      }, 1000);
    } else {
      setErrorMessage('Повторите попытку через 5 сек.')
      setTimeout(() => {
        setErrorMessage('')
      }, 2000);
    }
  }

  const bg = <div className={styles.twoFaMain} onClick={() => setShowAddModal(false)}></div>
  const addModal =
    <div className={styles.passRoot}>
      <div className={styles.topBlock}>
        <p className={styles.topBlocktitle}>Добавить способ вывода</p>
        <img
          src={cross}
          alt=""
          className={styles.cross}
          onClick={() => setShowAddModal(false)}
        />
      </div>
      <div className={styles.paymentSelect}>
        <p className={styles.subtitle}>Банк</p>
        <Select
          options={bankListToAdd}
          classNames={{
            control: (state) => styles.select2,
            singleValue: (state) => styles.select4,
            menuList: (state) => styles.select6,
            menu: (state) => styles.select61,
            option: (state) => styles.select7,
          }}
          classNamePrefix="react-select"
          placeholder={`Способы оплаты`}
          defaultValue={selectedBankOption}
          value={selectedBankOption}
          onChange={setSelectedBankOption}
        />
      </div>
      {
        selectedBankOption.label === 'QIWI' ?
          <div>
            <p className={styles.subtitle}>Номер телефона</p>
            <input type="text" className={styles.number} placeholder='8 (000) 000 00 00' onChange={(e) => checkTelValue(e)} />
          </div>
          :
          <div>
            <p className={styles.subtitle}>Номер карты</p>
            <input type="text" className={styles.number} placeholder='____ ____ ____ ____' onChange={(e) => checkValue(e)} />
          </div>
      }
      <div>
        <p className={styles.subtitle}>ФИО получателя</p>
        <input type="text" className={styles.number} placeholder='Введите реквизиты' onChange={(e) => setFio(e.target.value)} />
      </div>
      {errorMessage ? error : <></>}
      {okMessage ? ok : <></>}
      <button className={checked ? styles.btnOk : styles.btnDis} onClick={addPayment}>Добавить</button>
    </div>

  const jivoShow = () => {
    let jivo = document.querySelectorAll('jdiv')[0] || document.createElement('div');
    (jivo as HTMLDivElement).style.display = 'block';
    let body = document.getElementsByTagName('body')[0];
    (body as HTMLBodyElement).style.overflow = 'unset';
  }

  const goBack = () => {
    setCurrent(0)
  }

  useEffect(() => {
    if (showAddModal) {
      let body = document.getElementsByTagName('body')[0];
      (body as HTMLBodyElement).style.overflow = 'hidden';
    } else {
      let body = document.getElementsByTagName('body')[0];
      (body as HTMLBodyElement).style.overflow = 'unset';
    }
  }, [showAddModal])

  const [toOut2, setToOut2] = useState<any>({})

  const [totalError, setTotalError] = useState('');

  let prev2 = ''
  const priceChange2 = (e: any, el: B2bPayments) => {
    const pattern = new RegExp("^(\\d*)\\.{0,1}(\\d*)$")
    if (pattern.test(e.target.value.trim())) {
      if (e.target.value.trim().length > 12) {
        let temp = toOut2;
        temp[el.id] = e.target.value.trim().slice(0, 12);
        setToOut2(temp);
        prev2 = e.target.value.trim().slice(0, 12);
        e.target.value = e.target.value.trim().slice(0, 12)
      } else {
        let temp = toOut2;
        temp[el.id] = e.target.value.trim();
        setToOut2(temp);
        prev2 = e.target.value.trim();
      }
    } else if (e.target.value.trim() === '') {
      let temp = toOut2;
      temp[el.id] = '';
      setToOut2(temp);
      prev2 = '';
    } else {
      e.target.value = prev2;
    }
    let temp: any = Object.values(toOut2).reduce((a: any, c: any) => +a + +c)
    if (+temp > (+summ * +rate)) {
      setTotalError('Введёные суммы превышают выбранную')
      setErrorMessage('Введёные суммы превышают выбранную')
    } else if (+temp < (+summ * +rate)) {
      setTotalError('Введёные суммы меньше выбранной')
      setErrorMessage(`Введёные суммы меньше выбранной, распределите ещё ${(+(`${(+summ * +rate).toString().split('.')[0]}.${(+summ * +rate).toString().split('.')[1] ? (+summ * +rate).toString().split('.')[1].slice(0, 2) : `00`}`) - +temp).toFixed(2)}`)
    } else {
      setTotalError('')
      setErrorMessage('')
    }
  }

  return (
    <>
      {showAddModal ? bg : <></>}
      {showAddModal ? addModal : <></>}
      {isOpenModal && (
        <>
          <div className={styles.popupIn} onClick={() => { setIsOpenModal(false); jivoShow() }}></div>
          <div
            className={styles.popupIn__block}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.backTitle}>
              <img src={back} alt="back" onClick={goBack} style={current ? { display: 'block' } : { display: "none" }} />
              <div className={styles.popupIn__title}>Вывод</div>
            </div>
            <Cross
              className={styles.popupIn__close}
              onClick={() => { setIsOpenModal(false); jivoShow() }}
            />
            {
              current === 0 ?
                <>
                  <div className={styles.popupIn__text}>Криптовалюта </div>
                  <Select
                    options={tokenList}
                    placeholder={`Выберите криптовалюту`}
                    classNames={{
                      container: (state) => styles.select3,
                      singleValue: (state) => styles.select4,
                      control: (state) => styles.select2,
                      menuList: (state) => styles.select6,
                      menu: (state) => styles.select61,
                      option: (state) => styles.select7,
                      valueContainer: (state) => styles.select5,
                    }}
                    classNamePrefix="react-select"
                    onChange={setSelectedTokenOption}
                    defaultValue={selectedTokenOption}
                  />
                  <div className={styles.popupIn__text}>Сумма</div>
                  <div className={styles.popupIn__input}>
                    <input
                      type="text"
                      placeholder={`Введите сумму`}
                      value={summ}
                      onChange={(e) => priceChange(e)}
                      id="inp3"
                      maxLength={20}
                    />
                  </div>
                  <div className={styles.popupIn__balance}>
                    Текущий баланс:
                    <div className={styles.curVal}>{currentChartBalance ? +currentChartBalance.balance : 0} {selectedTokenOption.label}</div>
                  </div>
                  <div className={styles.popupIn__items}>
                    <div className={styles.popupIn__item}>
                      Сумма к получению
                      <div className={styles.curVal}>{`${(+summ * +rate).toString().split('.')[0]}.${(+summ * +rate).toString().split('.')[1] ? (+summ * +rate).toString().split('.')[1].slice(0, 2) : `00`}`} RUB</div>
                    </div>
                  </div>
                  {errorMessage ? error : <></>}
                  {okMessage ? ok : <></>}
                  <div className={styles.btns}>
                    <div className={(+currentChartBalance?.balance && (+summ <= +currentChartBalance?.balance) && +summ) ? styles.btnOk : styles.btnDis} onClick={() => (+currentChartBalance?.balance && (+summ <= +currentChartBalance?.balance) && +summ) ? setCurrent(1) : <></>}>
                      На карту
                    </div>
                    <div className={(+currentChartBalance?.balance && (+summ <= +currentChartBalance?.balance) && +summ) ? styles.btnOk : styles.btnDis} onClick={() => (+currentChartBalance?.balance && (+summ <= +currentChartBalance?.balance) && +summ) ? setCurrent(2) : <></>}>
                      Курьером
                    </div>
                  </div>
                </>
                :
                current === 1 ?
                  <>
                    <div className={styles.text_topBlock}>
                      <div className={styles.popupIn__text}>Способы вывода</div>
                      <div className={styles.popupIn__text} style={totalError ? { color: 'rgba(211, 65, 118, 1)' } : {}}>{summ} {selectedTokenOption.label?.props?.children?.[1]} ≈ {`${(+summ * +rate).toString().split('.')[0]}.${(+summ * +rate).toString().split('.')[1] ? (+summ * +rate).toString().split('.')[1].slice(0, 2) : `00`}`} RUB</div>
                    </div>
                    <div className={styles.paymentBox} id='paymentBox'>
                      {
                        payments.length ?
                          payments.map((el, index) => {
                            return <div className={styles.payment} key={index} id={el.id.toString()} onClick={() => changePayment(el)}>
                              <div className={styles.paymentTop}>
                                <div className={styles.paymentName}>{el.bank ?? `Необходимо удалить и добавить этот реквизит заново`}</div>
                                <img
                                  src={cross}
                                  alt=""
                                  className={styles.cross}
                                  onClick={() => delPayment(el)}
                                />
                              </div>
                              <div className={styles.paymentBottom}>
                                <div className={styles.paymentRec}>
                                  <p className={styles.paymentTitle}>Фамилия Имя</p>
                                  <p>{el.payment_receiver}</p>
                                </div>
                                <div className={styles.paymentVal}>
                                  <p className={styles.paymentTitle}>{'Номер банковской карты'}</p>
                                  <p>{el.value}</p>
                                </div>
                              </div>
                              <div className={styles.paymentInputBox}>
                                <p className={styles.paymentTitle}>Сумма для этой карты</p>
                                <input type="text" placeholder={`Введите сумму`} onChange={(e) => priceChange2(e, el)} />
                              </div>
                            </div>
                          })
                          :
                          <div className={styles.notEx}>Нет добавленных способов вывода</div>
                      }
                    </div>
                    {errorMessage ? error : <></>}
                    {okMessage ? ok : <></>}
                    <div className={styles.btns}>
                      <div className={styles.btnOk} onClick={() => setShowAddModal(true)}>
                        Добавить карту
                      </div>
                      <div className={((ps && !totalError) && (Object.values(toOut2).length > 0)) ? styles.btnOk : styles.btnDis} onClick={() => ((ps && !totalError) && (Object.values(toOut2).length > 0)) ? deliveryOut(1) : <></>}>
                        Заказать вывод
                      </div>
                    </div>
                  </>
                  :
                  current === 2 ?
                    couriers.length ?
                      <>
                        <img src={delivery} alt="delivery" className={styles.delivery} />
                        <div className={styles.text2}>
                          У Вас уже имеется заказаный вывод стредств.<br /> Ожидайте, курьер скоро будет у вас
                        </div>
                      </>
                      :
                      <>
                        <div className={styles.popupIn__text}>Имя</div>
                        <div className={styles.popupIn__input}>
                          <input
                            type="text"
                            placeholder={`Введите имя`}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className={styles.popupIn__text}>Телефон</div>
                        <div className={styles.popupIn__input}>
                          <input
                            type="text"
                            placeholder={`Введите телефон`}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div className={styles.popupIn__text}>Улица</div>
                        <div className={styles.popupIn__input}>
                          <input
                            type="text"
                            placeholder={`Введите улицу`}
                            onChange={(e) => setStreet(e.target.value)}
                          />
                        </div>
                        <div className={styles.popupIn__text}>Дом</div>
                        <div className={styles.popupIn__input}>
                          <input
                            type="text"
                            placeholder={`Введите дом`}
                            onChange={(e) => setHouse(e.target.value)}
                          />
                        </div>
                        <div className={styles.popupIn__text}>Подъезд</div>
                        <div className={styles.popupIn__input}>
                          <input
                            type="text"
                            placeholder={`Введите подъезд`}
                            onChange={(e) => setHp(e.target.value)}
                          />
                        </div>
                        {errorMessage ? error : <></>}
                        {okMessage ? ok : <></>}
                        <div className={styles.btns}>
                          <div className={name && phone && street && house && hp ? styles.btnOk : styles.btnDis} onClick={() => name && phone && street && house && hp ? deliveryOut(0) : <></>}>
                            Заказать вывод
                          </div>
                        </div>
                      </>
                    :
                    <></>
            }

          </div>
        </>
      )}
    </>
  )
}

export default B2bPayOut