import React from 'react'
import styles from './Contacts.module.scss'
import Container from '../../UI/container/Container'

const Contacts = () => {
  return (
    <div className={styles.contactsContainer}>
      <Container>
        <div className={styles.main}>
          <div className={styles.title}>Контакты</div>
          <div className={styles.block}>
            <div className={styles.subtitle}>
              Инновационная крипто-платформа Greenavi - это надежность и безопасность. Мониторинг обменников, P2P, DeFi, Процессинг - мы создаем целую экосистему, которая поможет совершить любую операцию с криптовалютой: хранить, перевести, инвестировать, обменять.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.text}>
              Ниже указан наш адрес и адреса наших партнеров.
            </div>
            <div className={styles.text}>
              С нами можно связаться по телефону: <a href='tel:+7 951 833 09 97'>+7 951 833 09 97</a>
            </div>
            <div className={styles.text}>
              ежедневно с 10:00 до 21:00.
            </div>
            <div className={styles.block}>
              <div className={styles.flexBlock}>
                <div>
                  <div className={styles.text}>
                    Жалобы:
                  </div>
                  <div className={styles.text}>
                    <a href="mailto:support@greenavi.com">support@greenavi.com</a>
                  </div>
                </div>
                <div>
                  <div className={styles.text}>
                    Сотрудничество:
                  </div>
                  <div className={styles.text}>
                    <a href="mailto:support@greenavi.com">support@greenavi.com</a>
                  </div>
                </div>
                <div>
                  <div className={styles.text}>
                    Для СМИ:
                  </div>
                  <div className={styles.text}>
                    <a href="mailto:support@greenavi.com">support@greenavi.com</a>
                  </div>
                </div>
                <div>
                  <div className={styles.text}>
                    Хотите работать у нас:
                  </div>
                  <div className={styles.text}>
                    <a href="mailto:support@greenavi.com">support@greenavi.com</a>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.text}>
                г. Москва, Пресненская набережная, 4с2
              </div>
              <div className={styles.text}>
                Режим работы офиса:
              </div>
              <div className={styles.text}>
                с 11:00 до 20:30 по г. Москва
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Contacts