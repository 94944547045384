import React, { useCallback, useEffect, useState } from 'react'
import styles from './Order.module.scss'
import { useLocation } from 'react-router-dom'
import { ApealProps, IMessage, OrderHistory, UserProfile } from '../../types/types';
import Container from '../../UI/container/Container';
import Skeleton from 'react-loading-skeleton';
import { ProfileInfo } from '../../services/UserService';
// import ChatBlock from '../../components/paymentComponents/chatBlock/ChatBlock';
import { GetAllOffers } from '../../services/P2p';
import TradeSide from './TradeSide';
import Chat from '../../components/chat/Chat';
import { GetMessageList, NewMessage } from '../../services/ChatService';
// import Apeal from '../../components/paymentComponents/apeal/Apeal';


const Order = () => {
  // const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [openChat, setOpenChat] = useState(false)
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [showApeal, setShowApeal] = useState<ApealProps | null>(null)
  const token = localStorage.getItem("access_token") ?? ''

  const checkToken = async (token: string) => {
    const { data } = await ProfileInfo(token);
    if (data === 'Token не найден' || data === undefined) {
      localStorage.removeItem("access_token");
      document.location.href = '/'
    } else {
      setProfile(data)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? '';
    checkToken(token);
  }, [location])

  const [order, setOrder] = useState<OrderHistory | null>(null)

  const [messageList, setMessageList] = useState<IMessage[]>([])
  const [isNewMessage, setIsNewMessage] = useState(false)
  const messageBlock = document.getElementById('messageBlock') || document.createElement("div");

  useEffect(() => {
    if (isNewMessage) {
      (messageBlock as HTMLDivElement).scrollTo(0, +messageBlock.scrollHeight);
      setIsNewMessage(false)
    }
  }, [isNewMessage, messageBlock])

  const sendNullMessage = async (order: OrderHistory) => {
    if (!order || !profile) return;
    const userId = order.author_id !== profile.id ? order.user_id : order.author_id;
    const roomId = order.chat_room_id;
    const formData = new FormData();
    formData.append("text", '')
    formData.append("attachment_type", '')
    formData.append("sender_user_id", userId.toString())
    formData.append("chat_room_id", roomId.toString())
    const data = await NewMessage(formData)
  }

  const getMessageList = useCallback(async (order: OrderHistory) => {
    if (!order) return
    const data = await GetMessageList(order.chat_room_id)
    if (data.status === 200 && data.data && data.data.Items) {
      if (data.data.Items.length > messageList.length) { setIsNewMessage(true) };
      setMessageList(data.data.Items.reverse())
    }
  }, [messageList.length, order])

  const getCurrentOrder = useCallback(async () => {
    if (!profile) return
    const data = await GetAllOffers(token, profile.id)
    if (data.status === 200) {
      const currentOrder = (data.data as OrderHistory[]).filter(ord => +ord.order_id === +(location.state as OrderHistory)?.order_id ?? 0)
      setOrder(currentOrder[currentOrder.length - 1])
      getMessageList(currentOrder[currentOrder.length - 1])
      sendNullMessage(currentOrder[currentOrder.length - 1])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, profile, token])

  useEffect(() => {
    getCurrentOrder()
  }, [getCurrentOrder])

  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentOrder()
    }, 6000)
    return () => clearInterval(interval);
  }, [getCurrentOrder])

  const [unread, setUnread] = useState('');

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!reload || !order) return
    getMessageList(order);
    setReload(false)
  }, [getMessageList, order, reload, setReload])

  return (
    <Container className={styles.main}>
      {order && profile ?
        <>
          <div className={styles.leftBlock}>
            <TradeSide
              unread={unread}
              currentOfferData={order}
              profile={profile}
              setOpenChat={setOpenChat}
              setShowApeal={setShowApeal}
            />
          </div>
          <div className={styles.rightBlock}>
            {/* <ChatBlock
              openChat={openChat}
              setOpenChat={setOpenChat}
              agentName={profile.id === order.author_id ? order.user : order.author}
              userId={order.author_id !== profile.id ? order.user_id : order.author_id}
              avatar={profile.id === order.author_id ? order.avatar : order.avatar_author}
              orderId={order.order_id}
              date={order.start_date}
              setUnread={setUnread}
            /> */}
            <Chat
              setReload={setReload}
              messageList={messageList}
              openChat={openChat}
              setOpenChat={setOpenChat}
              agentName={profile.id === order.author_id ? order.user : order.author}
              userId={order.author_id !== profile.id ? order.user_id : order.author_id}
              avatar={profile.id === order.author_id ? order.avatar : order.avatar_author}
              setUnread={setUnread}
              roomId={order.chat_room_id}
            />
          </div>
          <div className={styles.rightBlockSmall} style={openChat ? { display: 'block' } : { display: 'none' }}>
            {/* <ChatBlock
              openChat={openChat}
              setOpenChat={setOpenChat}
              agentName={profile.id === order.author_id ? order.user : order.author}
              userId={order.author_id !== profile.id ? order.user_id : order.author_id}
              avatar={profile.id === order.author_id ? order.avatar : order.avatar_author}
              orderId={order.order_id}
              date={order.start_date}
              setUnread={setUnread}
            /> */}
            <Chat
              setReload={setReload}
              messageList={messageList}
              openChat={openChat}
              setOpenChat={setOpenChat}
              agentName={profile.id === order.author_id ? order.user : order.author}
              userId={order.author_id !== profile.id ? order.user_id : order.author_id}
              avatar={profile.id === order.author_id ? order.avatar : order.avatar_author}
              setUnread={setUnread}
              roomId={order.chat_room_id}
            />
          </div>
        </>
        :
        <div style={{ display: 'block', width: '100%' }}>
          <Container className={styles.skeletonContainer}>
            <div className={styles.skeletonBlock1}>
              <div style={{ marginBottom: '10px' }}>
                <Skeleton height={250} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
              </div>
              <div>
                <Skeleton height={350} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
              </div>
            </div>
            <div className={styles.skeletonBlock2}>
              <div>
                <Skeleton height={615} baseColor={'#1caf8610'} highlightColor={'#1caf8630'} borderRadius={'8px'} />
              </div>
            </div>
          </Container>
        </div>
      }
      {/* <>
            {showApeal && <Apeal apeal={showApeal} />}
          </> */}
    </Container>
  )
}

export default Order